import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'date-fns';
import { differenceInCalendarDays, addDays, isPast, isAfter, startOfMonth, isDate, parseISO } from 'date-fns';
import { PortalDataContext } from 'Contexts';
import Utility from 'Services/utils.js';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Typography, Button, Input, FormControl, CircularProgress, NativeSelect, FormGroup, InputLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import useUsage from 'hooks/useUsage';
import { endOfMonth, subMonths } from 'date-fns/esm';
import { CustomerManagement as CustomerManagementContext } from 'Contexts/CustomerManagement';
import PickitAPI from '../../PickitAPI';
const styles = (theme) => ({
  customerLogo: {
    textAlign: 'center',
    padding: '4px 0',
    height: 100,
  },
  customerLogoImg: {
    maxHeight: 75,
    maxWidth: '100%',
    margin: '0 auto',
  },
  grid: {
    padding: 15,
  },
  gridContent: {},
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    width: '100%',
    paddingBottom: 20,
  },
  subheading: {
    paddingTop: 20,
  },
  saveButton: {
    margin: '0 auto',
    display: 'block',
  },
  toolbar: {
    backgroundColor: '#f7f7f5',
  },
  card: {
    padding: '10px',
    width: '100%',
    height: '100%',
    borderRadius: '2px',
    minHeight: 80,
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0px !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
  statGrid: {},
  cardInput: {
    fontSize: 30,
    fontWeight: 'bold',
    '&>input': {
      textAlign: 'center',
    },
  },
});
const StaticCard = (props) => {
  const { title, subtitle, label } = props;
  return (
    <>
      <Typography
        variant={'subtitle1'}
        component={'strong'}
        style={{
          fontSize: '0.8em',
          lineHeight: '0.8em',
          fontWeight: 500,
          color: 'rgba(0,0,0,0.45)',
        }}>
        {label}
      </Typography>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography
          variant={'h2'}
          component={'center'}
          style={{
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '24px',
          }}>
          {title}
        </Typography>
        <Typography
          variant={'subtitle1'}
          component={'center'}
          style={{
            fontWeight: 'bold',
            fontSize: 12,
            lineHeight: '12px',
          }}>
          {subtitle}
        </Typography>
      </div>
    </>
  );
};

const OnDemandCard = (props) => {
  const { title, subtitle, label, refreshAction, refreshing } = props;
  return (
    <>
      <div style={{ display: 'flex'}} >
        <Typography
          variant={'subtitle1'}
          component={'strong'}
          style={{
            fontSize: '0.8em',
            lineHeight: '0.8em',
            fontWeight: 500,
            color: 'rgba(0,0,0,0.45)',
          }}>
          {label}
        </Typography>
        <Button
          name="refresh"
          color={'primary'}
          component={'strong'}
          disabled={refreshing}
          onClick={refreshAction}
          style={{
            marginLeft: 'auto',
            fontSize: '0.8em',
            lineHeight: '0.8em',
            fontWeight: 500,
            padding: 0,
            minWidth: 0,
          }}>
          Refresh
        </Button>
      </div>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography
          variant={'h2'}
          component={'center'}
          style={{
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '24px',
          }}>
          {title}
        </Typography>
        <Typography
          variant={'subtitle1'}
          component={'center'}
          style={{
            fontWeight: 'bold',
            fontSize: 12,
            lineHeight: '12px',
          }}>
          {subtitle}
        </Typography>
      </div>
    </>
  );
};

function EditCard(props) {
  const { title, subtitle, label, disableEdit, children, saving, disableSave, reset, onReset } = props;
  const [editMode, setEditMode] = useState(false);
  const enableEdit = (event) => {
    const { onEdit } = props;
    if (onEdit) onEdit();
    setEditMode(true);
  };
  const cancelEdit = (event) => {
    const { onCancel } = props;
    if (onCancel) onCancel();
    setEditMode(false);
  };
  const saveEdit = (event) => {
    const { onSave } = props;
    if (onSave) onSave();
    setEditMode(false);
  };
  useEffect(() => {
    if (reset) {
      setEditMode(false);
      onReset();
    }
  }, [reset]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography
          variant={'subtitle1'}
          component={'strong'}
          style={{
            fontSize: '0.8em',
            lineHeight: '0.8em',
            fontWeight: 500,
            color: 'rgba(0,0,0,0.45)',
          }}>
          {label}
        </Typography>

        {!disableEdit &&
          (!editMode ? (
            <Button
              name="edit"
              color={'primary'}
              component={'strong'}
              disabled={disableEdit || saving}
              onClick={enableEdit}
              style={{
                marginLeft: 'auto',
                fontSize: '0.8em',
                lineHeight: '0.8em',
                fontWeight: 500,
                padding: 0,
                minWidth: 0,
              }}>
              Edit
            </Button>
          ) : (
            <>
              {props.onSave && (
                <Button
                  name="save"
                  color={'primary'}
                  component={'strong'}
                  disabled={disableEdit || disableSave}
                  onClick={saveEdit}
                  style={{
                    marginLeft: 'auto',
                    fontSize: '0.8em',
                    lineHeight: '0.8em',
                    fontWeight: 500,
                    padding: 0,
                    minWidth: 0,
                  }}>
                  Save
                </Button>
              )}
              <Button
                name="cancel"
                color={'primary'}
                component={'strong'}
                disabled={disableEdit}
                onClick={cancelEdit}
                style={{
                  marginLeft: 'auto',
                  fontSize: '0.8em',
                  lineHeight: '0.8em',
                  fontWeight: 500,
                  padding: 0,
                  minWidth: 0,
                }}>
                Cancel
              </Button>
            </>
          ))}
      </div>
      {!editMode ? (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {saving ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                variant={'h2'}
                component={'center'}
                style={{
                  fontWeight: 'bold',
                  fontSize: '24px',
                  lineHeight: '24px',
                }}>
                {title}
              </Typography>
              <Typography
                variant={'subtitle1'}
                component={'center'}
                style={{
                  fontWeight: 'bold',
                  fontSize: 12,
                  lineHeight: '12px',
                }}>
                {subtitle}
              </Typography>
            </>
          )}
        </div>
      ) : (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{children}</div>
      )}
    </>
  );
}
function CustomerDashboard(props) {
  const context = useContext(PortalDataContext);
  const CustomerManagement = useContext(CustomerManagementContext);
  const ref = useRef('ref');
  const { classes } = props;
  const { customer: cust, originalCustomer, handleCustomerChange, handleCustomerSave, isSaving, hasChanges } = CustomerManagement;
  const { customer, community } = cust;

  const [checkedInvalid, setCheckedInvalid] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [customerMediaStorageSize, setCustomerMediaStorageSize] = useState('-');
  const [customerDocumentsStorageSize, setCustomerDocumentsStorageSize] = useState('-');
  const [reset, setReset] = useState(undefined);
  const stats = useUsage(community.slug);

  useEffect(() => {
    if (stats.startPeriod && stats.endPeriod) {
      stats.getActiveUsers();
    }
  }, [stats.startPeriod, stats.endPeriod]);
  useEffect(() => {
    if (!hasChanges) {
      setReset(true);
    }
  }, [hasChanges]);

  useEffect(() => {
    if (customer) {
      stats.setStartPeriod(startOfMonth(subMonths(new Date(), 1)));
      stats.setEndPeriod(endOfMonth(subMonths(new Date(), 1)));
      setTimeout(() => {
        if (
          !checkedInvalid &&
          !!customer.status &&
          customer.status === 'trialactive' &&
          community.product.trial_end_date === null &&
          community.product.trial_start_date === null
        ) {
          handleCustomerSave({ mode: 'silent' });
          setCheckedInvalid(true);
        }
      }, 100);
    }
  }, [customer && customer.tenant_id]);

  const handleFieldUpdate = (event) => {
    let { name: path, value } = event.target;
    if (path.includes('licenses')) {
      value = value < 0 ? 0 : value;
    }
    if (path.includes('has_self_service')) {
      value = value === 'true';
    }
    if (path.includes('trial_days_left')) {
      if (value < 1) value = 1;
      const end_date = addDays(new Date(), value);
      handleCustomerChange({ path: `community.product.trial_end_date`, value: end_date });
    }
    if (path.includes('trial_end_date')) {
      handleCustomerChange({
        path: 'community.product.trial_days_left',
        value: differenceInCalendarDays(new Date(value), new Date()),
      });
    }
    if (path.includes('status') && value === 'trialactive') {
      const end_date = addDays(new Date(), 30);
      handleCustomerChange({ path: `community.product.trial_end_date`, value: end_date });
      handleCustomerChange({
        path: 'community.product.trial_days_left',
        value: differenceInCalendarDays(new Date(end_date), new Date()),
      });
    }

    if (path.includes('seats')) {
      value = Number(value);
    }
    handleCustomerChange({ path, value });
  };

  const cancelEdit = (data) => {
    const { path, value } = data;

    handleCustomerChange({ path, value });
  };

  const onReset = () => {
    if (reset) {
      setReset(false);
    }
  };

  if (!customer || !stats) return null;

  return (
    <div>
      <br />
      <Grid container spacing={0} className={classes.gridContent}>
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.statGrid}>
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <EditCard
                    disableEdit={props.pass.isReadOnly() || Utility.getStatus(customer) === 'Deleted'}
                    title={customer.has_self_service ? 'Self-service' : 'Managed'}
                    subtitle={customer.has_self_service ? undefined : `By: ${customer.partner_name}`}
                    label="RELATION"
                    /*   onSave={() => handleCustomerSave({ source: 'dashboard.customer.has_self_service', field: 'customer.has_self_service' })} */
                    onCancel={() => cancelEdit({ path: 'customer.has_self_service', value: originalCustomer.customer.has_self_service })}
                    saving={isSaving}
                    reset={reset}
                    onReset={onReset}>
                    <NativeSelect name={'customer.has_self_service'} value={customer.has_self_service} onChange={handleFieldUpdate}>
                      <option name={'customer.has_self_service'} value={true}>
                        Self-service
                      </option>
                      <option name={'customer.has_self_service'} value={false}>
                        Managed
                      </option>
                    </NativeSelect>
                  </EditCard>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.gridItem}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <EditCard
                    disableEdit={props.pass.isReadOnly() || Utility.getStatus(customer) === 'Deleted' || Utility.getStatus(customer) === 'Suspended'}
                    disableSave={
                      (customer?.status === 'trialactive' &&
                        (isPast(parseISO(community?.product?.trial_end_date)) || !isDate(parseISO(community?.product?.trial_end_date)))) ||
                      isAfter(parseISO(community?.product?.trial_end_date), new Date(2300, 0, 1))
                    }
                    title={Utility.getStatus(customer)}
                    subtitle={
                      Utility.getStatus(customer) === 'Trial Active'
                        ? `${differenceInCalendarDays(
                            new Date(community?.product?.trial_end_date),
                            new Date(new Date().toISOString())
                          )} of ${differenceInCalendarDays(
                            new Date(community?.product?.trial_end_date),
                            new Date(community?.product?.trial_start_date)
                          )} days left`
                        : undefined
                    }
                    label="STATUS"
                    onCancel={() => cancelEdit({ path: 'customer.status', value: originalCustomer.customer.status })}
                    saving={isSaving}
                    reset={reset}
                    onReset={onReset}>
                    <FormGroup>
                      <FormControl fullWidth>
                        <NativeSelect name={'customer.status'} value={customer.status} onChange={handleFieldUpdate}>
                          {Utility.getStatus(customer) === 'Unknown' && customer?.status === 'active' && (
                            <option name={'Unknown'} value={'Unknown'}></option>
                          )}
                          <option name={'Free'} value={'free'}>
                            Free
                          </option>
                          {props.pass.isStaff() && (
                            <>
                              <option name={'NFR'} value={'nfr'}>
                                NFR
                              </option>
                              <option name={'Starter'} value={'starter'}>
                                Starter
                              </option>
                            </>
                          )}
                          <option name={'TrialActive'} value={'trialactive'}>
                            Trial Active
                          </option>
                          <option name={'TrialExpired'} value={'trialexpired'}>
                            Trial Expired
                          </option>
                          <option name={'Paying'} value={'paying'}>
                            Paying
                          </option>
                        </NativeSelect>
                      </FormControl>
                      {customer?.status === 'trialactive' ? (
                        <FormGroup row>
                          <FormControl style={{ width: '30%' }} margin="normal">
                            <InputLabel htmlFor="trialDays">Days</InputLabel>
                            <Input
                              id="trialDays"
                              name="community.product.trial_days_left"
                              type="number"
                              onChange={handleFieldUpdate}
                              label="days"
                              value={community?.product?.trial_days_left || 1}
                            />
                          </FormControl>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              name="community.product.trial_end_date"
                              variant="inline"
                              margin="normal"
                              id="date-picker-inline"
                              label="End date"
                              value={community?.product?.trial_end_date}
                              onChange={(value) => handleFieldUpdate({ target: { value, name: 'community.product.trial_end_date' } })}
                              minDateMessage={'Selected date is before today'}
                              disablePast
                              minDate={addDays(new Date(), 1)}
                              maxDate={addDays(new Date(), 100000)}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              style={{ width: '65%', marginLeft: 'auto' }}
                            />
                          </MuiPickersUtilsProvider>
                        </FormGroup>
                      ) : undefined}
                    </FormGroup>
                  </EditCard>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <StaticCard title={community.product.name} label="PRODUCT" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: 20 }} />
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <EditCard
                    disableEdit={
                      props.pass.isReadOnly() || Utility.getStatus(customer) === 'Deleted' || customer.self_service_market === 'partner-center'
                    }
                    title={customer?.details?.product?.seats}
                    label="LICENSES"
                    /*  onSave={() => handleCustomerSave({ source: 'dashboard.customer.details.product.seats', field: 'customer.details.product.seats' })} */
                    onCancel={() => cancelEdit({ path: 'customer.details.product.seats', value: originalCustomer.customer.details.product.seats })}
                    saving={isSaving}
                    reset={reset}
                    onReset={onReset}>
                    <FormControl>
                      <Input
                        name="customer.details.product.seats"
                        label="licenses"
                        value={customer.details.product.seats}
                        type="number"
                        onChange={handleFieldUpdate}
                        className={classes.cardInput}
                      />
                    </FormControl>
                  </EditCard>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <StaticCard title={community.total_number_of_users} label="LICENSED USERS" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <StaticCard title={customer.statistic.previous_month} label={'ACTIVE USERS (' + context.lastMonth.toUpperCase() + ')'} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: 20 }} />
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <StaticCard title={customer.statistic.media} label={'MEDIA FILES'} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <StaticCard title={customer.statistic.documents} label={'DOCUMENTS'} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <StaticCard title={customer.statistic.all_files} label={'TOTAL FILES'} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: 20 }} />
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <OnDemandCard
                    title={customerMediaStorageSize}
                    label={'MEDIA FILES STORAGE SIZE'}
                    refreshAction={async () => {
                      setRefreshing(true);
                      const size = await PickitAPI.getCustomerStorageSize({
                        slug: community.slug,
                        storage: "media"
                      });
                      setCustomerMediaStorageSize(size);
                      setRefreshing(false);
                    }}
                    refreshing={refreshing} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.gridItem} ref={ref}>
              <Card elevation={1} className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <OnDemandCard
                    title={customerDocumentsStorageSize}
                    label={'DOCUMENTS STORAGE SIZE'}
                    refreshAction={async () => {
                      setRefreshing(true);
                      const size = await PickitAPI.getCustomerStorageSize({
                        slug: community.slug,
                        storage: "documents"
                      });
                      setCustomerDocumentsStorageSize(size);
                      setRefreshing(false);
                    }}
                    refreshing={refreshing} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ height: 20 }} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

CustomerDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerDashboard);

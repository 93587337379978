import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PortalDataContext } from 'Contexts/PortalDataContext';

import {
  Toolbar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Icon,
  Paper,
  Button,
  FormControl,
  LinearProgress,
  Typography,
  Snackbar,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  TableSortLabel,
  Box,
  ListItemText,
  Checkbox,
  FilledInput,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import clone from 'clone';
import { startCase } from 'lodash';
import Utility from 'Services/utils.js';
import useUsage from 'hooks/useUsage';
import { differenceInMilliseconds, format, subDays } from 'date-fns';
import useRouterQuery from 'hooks/useRouterQuery';
import SalesUICustomer from 'PickitAPI/Customer';
import usePresets from 'hooks/usePresets';

const gridSpacing = 2;
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
    paddingBottom: 50,
  },
  cacheMessage: {
    textAlign: 'right',
  },
  toolbar: {
    backgroundColor: '#ffffff',

    borderRadius: 4,
  },
  paperLoader: {
    minHeight: '35vh',
    paddingTop: '25vh',
  },
  tableRow: {
    cursor: 'pointer',
  },
  customerLogo: {
    textAlign: 'center',
    padding: '4px 0',
    width: 125,
    height: 75,
  },
  customerLogoImg: {
    maxHeight: '85%',
    maxWidth: '85%',
  },
  customerLogoNoBranding: {
    height: 75,
    width: 125,
    textAlign: 'center',
    position: 'relative',
    padding: 0,
    fontWeight: 'bold',
    color: '#6D6D6C',

    '& > img': {
      opacity: 0.3,
      width: 122,
      height: 85,
      position: 'absolute',
      zIndex: 5,
      background:
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEGWlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPrtzZyMkzlNsNIV0qD8NJQ2TVjShtLp/3d02bpZJNtoi6GT27s6Yyc44M7v9oU9FUHwx6psUxL+3gCAo9Q/bPrQvlQol2tQgKD60+INQ6Ium65k7M5lpurHeZe58853vnnvuuWfvBei5qliWkRQBFpquLRcy4nOHj4g9K5CEh6AXBqFXUR0rXalMAjZPC3e1W99Dwntf2dXd/p+tt0YdFSBxH2Kz5qgLiI8B8KdVy3YBevqRHz/qWh72Yui3MUDEL3q44WPXw3M+fo1pZuQs4tOIBVVTaoiXEI/MxfhGDPsxsNZfoE1q66ro5aJim3XdoLFw72H+n23BaIXzbcOnz5mfPoTvYVz7KzUl5+FRxEuqkp9G/Ajia219thzg25abkRE/BpDc3pqvphHvRFys2weqvp+krbWKIX7nhDbzLOItiM8358pTwdirqpPFnMF2xLc1WvLyOwTAibpbmvHHcvttU57y5+XqNZrLe3lE/Pq8eUj2fXKfOe3pfOjzhJYtB/yll5SDFcSDiH+hRkH25+L+sdxKEAMZahrlSX8ukqMOWy/jXW2m6M9LDBc31B9LFuv6gVKg/0Szi3KAr1kGq1GMjU/aLbnq6/lRxc4XfJ98hTargX++DbMJBSiYMIe9Ck1YAxFkKEAG3xbYaKmDDgYyFK0UGYpfoWYXG+fAPPI6tJnNwb7ClP7IyF+D+bjOtCpkhz6CFrIa/I6sFtNl8auFXGMTP34sNwI/JhkgEtmDz14ySfaRcTIBInmKPE32kxyyE2Tv+thKbEVePDfW/byMM1Kmm0XdObS7oGD/MypMXFPXrCwOtoYjyyn7BV29/MZfsVzpLDdRtuIZnbpXzvlf+ev8MvYr/Gqk4H/kV/G3csdazLuyTMPsbFhzd1UabQbjFvDRmcWJxR3zcfHkVw9GfpbJmeev9F08WW8uDkaslwX6avlWGU6NRKz0g/SHtCy9J30o/ca9zX3Kfc19zn3BXQKRO8ud477hLnAfc1/G9mrzGlrfexZ5GLdn6ZZrrEohI2wVHhZywjbhUWEy8icMCGNCUdiBlq3r+xafL549HQ5jH+an+1y+LlYBifuxAvRN/lVVVOlwlCkdVm9NOL5BE4wkQ2SMlDZU97hX86EilU/lUmkQUztTE6mx1EEPh7OmdqBtAvv8HdWpbrJS6tJj3n0CWdM6busNzRV3S9KTYhqvNiqWmuroiKgYhshMjmhTh9ptWhsF7970j/SbMrsPE1suR5z7DMC+P/Hs+y7ijrQAlhyAgccjbhjPygfeBTjzhNqy28EdkUh8C+DU9+z2v/oyeH791OncxHOs5y2AtTc7nb/f73TWPkD/qwBnjX8BoJ98VQNcC+8AAAFFSURBVGgF7djbDYMwDAXQBjEMYqyqY1WMxTppLiJIQIA8ariWkp8IPuweO60am67r7MstY8wwjuPH7dMz3uUua63p+/7r9rePIRW/9QmQzCV9ub0YgSIgzhxvQkjFb1EZBAdEKolkfJTqtnb7bv/zOJm58moRE0AzYgFoRawAGhE7gDZEEKAJcQjQgjgFaEBcAtgRUQBmRDSAFZEEYEQkA9gQWQAmRDaABVEEYEAUAx5H4EaGOyw+SMl66mbXzNOD4k6gCPNUY/CFwF1YOn5zRxJJRINqaUbUuZA/79sd3wm3xIdbdS60rXzo2XVCbO60/HxKJgFKKv4CkEziuyKBWAE0InYAbYggQBPiEKAFcQrQgLgEsCOiAMyIaAArIgnAiEgGsCGyAEyIbAALogjAgCgGPI7AX9w6F3JtqHMhnMWLFSrSD9jOnakVHpZYAAAAAElFTkSuQmCC') 8% 50%",
      backgroundSize: '15%',
      left: 0,
      top: 0,
    },
  },
  'noWrap > td': {
    whiteSpace: 'nowrap',
  },
  noWrap: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  tableCellFocus: {
    fontWeight: 500,
  },
  descriptor: {
    position: 'absolute',
    bottom: -6,
    margin: 0,
    fontSize: 12,
    color: 'gray',
  },
  paper: {
    width: 'fit-content',
  },
  showSort: {
    '&:hover > button > span > span': {
      opacity: 1,
    },
  },
});

let searchTimeout = undefined;

function Customers(props) {
  const context = useContext(PortalDataContext);
  const { classes } = props;

  const familyFilter = {
    all: {
      title: 'All',
      slug: 'all'
    },
    both: {
      title: 'Family',
      slug: 'both'
    },
    parent: {
      title: 'Parents',
      slug: 'parents'
    },
    children: {
      title: 'Children',
      slug: 'children'
    }
  }
  const customersFilter = {
    all: {
      title: 'All',
      slug: 'undefined',
    },
    active: {
      title: 'Paying',
      slug: 'paying',
    },
    suspended: {
      title: 'Suspended',
      slug: 'suspended',
    },
    deleted: {
      title: 'Deleted',
      slug: 'deleted',
    },
    trialExpired: {
      title: 'Trial Expired',
      slug: 'trialexpired',
    },
    trialActive: {
      title: 'Trial Active',
      slug: 'trialactive',
    },
    nfr: {
      title: 'NFR',
      slug: 'nfr',
      staff: true,
    },
    starter: {
      title: 'Starter',
      slug: 'starter',
      staff: true,
    },
    free: {
      title: 'Free',
      slug: 'free',
    },
    /* unknown: {
      title: 'Unknown',
      slug: 'unknown',
    }, */
  };
  const defaultParams = [
    { key: 'page_nr', value: 1 },
    { key: 'page_limit', value: 25 },
    { key: 'sort_field', value: 'date_desc' },
    { key: 'has_self_service', value: false },
    { key: 'status', value: undefined },
    { key: 'partner', value: undefined },
    { key: 'search', value: undefined },
    { key: 'family', value: 'all' },
    //{ key: 'activity', value: 30 },
  ];

  const hiddenServiceKeys = ['getty_api_key', 'getty_api_secret'];

  const query = useRouterQuery(defaultParams);
  const { params, applyDefaultParams, saveParams, loadParams } = query;
  const stats = useUsage(props.pass.getUserCommunitySlug(), subDays(new Date(), 30), new Date());
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [isExporting, setIsExporting] = React.useState(false);
  const [exportingProgress, setExportingProgress] = React.useReducer((state, action) => {
    if (action === 'increase') {
      return state + 1;
    }
    if (action === 'reset') {
      return 0;
    }
  }, 0);
  const [totalExportingProgress, setTotalExportingProgress] = React.useState(0);
  const [exportStartDate, setExportStartDate] = React.useState(null);

  const { data } = usePresets();
  const allServices = [...(data?.services?.features ?? []), ...(data?.services?.integrations ?? []), ...(data?.services?.services ?? [])]?.filter(
    (service) => !hiddenServiceKeys?.includes(service.key || service.slug)
  );
  useEffect(() => {
    if (customers?.length > 0) {
      const communities = customers.map((customer) => customer.community.slug).filter((community) => !stats.activeUsersCommunities[community]);
      stats.getActiveUsersByCommunities(communities);
    }
  }, [customers]);

  useEffect(() => {
    loadParams('customers');
    getCustomers();
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      getCustomers();
      saveParams('customers');
    }
  }, [
    params.page_nr,
    params.page_limit,
    params.relation,
    params.status,
    params.family,
    params.sort_field,
    params.has_self_service,
    params.partner,
    params.policies,
    props.pass.testMode(),
  ]);

  useEffect(() => {
    if (isMounted) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        if (params.page_nr === 1 || params.page_nr === '1') {
          getCustomers();
        } else {
          query.set('page_nr', 1);
        }
        saveParams('customers');
      }, 1000);
    }
  }, [params.search]);

  const getParams = () => {
    const newParams = clone(params);

    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === 'undefined' || newParams[key] === 'all') {
        newParams[key] = undefined;
      }
    });

    delete newParams.activity;

    if (!props.pass.isStaff()) {
      newParams.partner = props.pass.isReseller() ? props.pass.getPartnerTenantId() : undefined;
      newParams.parent = props.pass.isDistributor() ? props.pass.getPartnerTenantId() : undefined;
      newParams.has_self_service = props.pass.isReseller() ? undefined : params.has_self_service;
    }
    if (newParams.search) {
      newParams.search = newParams.search.toLowerCase();
    }
    return newParams;
  };

  const getCustomers = () => {
    if (!params.page_nr || !params.has_self_service) {
      loadParams('customers');
      return;
    }
    setIsLoading(true);
    const newParams = getParams();

    setCustomers([]);

    const testMode = props.pass.testMode();
    SalesUICustomer.customers
      .getForPartner(newParams, props.pass.testMode())
      .then((customers) => {
        const currentParams = getParams();
        //Make sure rapid  changes doesn't overwrite slow response
        if (testMode !== props.pass.testMode() || JSON.stringify(newParams) !== JSON.stringify(currentParams)) {
          return;
        }
        setTotalCustomers(customers.total_count);
        setCustomers(customers.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error('Failed refreshing customers \n', e);
        if (e.status === 403) {
          props.pass.logout();
        }
        setIsLoading(false);

        context.setError(e.statusText);
      });
  };
  useEffect(() => {
    if (isMounted) {
      const details = customers.map((customer) => {
        return customer.community.slug;
      });
      stats.getActiveUsersByCommunities(details);
    }
  }, [params.activity]);

  const onFilterChanged = (event) => {
    let key = event.target.name,
      value = event.target.value;
    if (value === 'undefined' || (Array.isArray(value) && value.length === 0)) {
      query.delete(key);
      return;
    }
    if (Array.isArray(value) && key === 'policies') {
      value = value.filter((item) => !!item);

      if (value.includes('selectAll')) {
        selectAllServices();
        query.set('page_nr', 1);

        return;
      }
      if (value.includes('deselectAll')) {
        query.delete(key);
        query.set('page_nr', 1);

        return;
      }
    }
    query.set('page_nr', 1);
    query.set(key, value);
  };

  const setOrder = (event) => {
    event.persist();
    let currentOrder = params.sort_field,
      newOrder = '',
      field = event.target.id;

    if (!currentOrder || currentOrder.indexOf(event.target.id) === -1 || currentOrder.indexOf('_desc') !== -1) {
      newOrder = field + '_asc';
    } else {
      newOrder = field + '_desc';
    }

    query.set('sort_field', newOrder);
    query.set('page_nr', 1);
  };
  const onCustomerSelect = (customer_uuid) => (event) => {
    if (props.route) {
      props.route.history.push('/customer/' + customer_uuid + (props.pass.testMode() ? '?test=true' : ''));
      context.setSelectedCustomer(customer_uuid);
    }
  };

  const onNewCustomerClick = (event) => {
    if (props.route) {
      props.route.history.push('/customers/new' + (props.pass.testMode() ? '?test=true' : ''));
    }
  };

  const partnerFilterRows = context.partnerList
    ? context.partnerList
        .filter((partner) => !!partner.name)
        .sort((a, b) => {
          const aName = a.name.toLowerCase(),
            bName = b.name.toLowerCase();
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        })
        .map((partner) => {
          return (
            <MenuItem key={partner.tenant_id} value={partner.tenant_id}>
              {partner.name}
            </MenuItem>
          );
        })
    : [];
  partnerFilterRows.unshift(
    <MenuItem key={'all'} value={'undefined'}>
      {'All'}
    </MenuItem>
  );

  let familyFilterRows = Object.keys(familyFilter).map((key) => {
    let filter = familyFilter[key];
    return (
      <MenuItem key={key} value={filter.slug}>
        {filter.title}
      </MenuItem>
    );
  });

  let statusFilterRows = Object.keys(customersFilter).map((key) => {
    let filter = customersFilter[key];
    if (filter.staff && !props.pass.isStaff()) return undefined;

    return (
      <MenuItem key={key} value={filter.slug}>
        {filter.title}
      </MenuItem>
    );
  });
  let totalLicenses = 0,
    totalLicensed = 0,
    totalActive = 0,
    totalFiles = 0;

  function createCSV(rows) {
    function arrayToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = 'SEP=,\n\r';
      return array.reduce((str, next) => {
        str += `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(',')}\r\n`;
        return str;
      }, str);
    }

    const BOM = decodeURIComponent('%ef%bb%bf');
    const CSVData = BOM + arrayToCSV(rows);

    const blob = new Blob([CSVData], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', 'customers.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  function exportTable(rows) {
    const exportRows = [
      [
        'Name',
        'Created On',
        'Country',
        'Status',
        'Partner',
        'Licenses',
        'Users',
        'Active Users',
        'Media',
        'Documents',
        'All Files',
        'Product',
        'Address',
        'City',
        'Zip',
        'Contact Person Name',
        'Contact Person Email',
        'Contact person Phone',
        'Relation',
        'SSO Enabled',
        'Email/Password Enabled',
        'Pickit Stock Enabled',
        'Curator Enabled',
        'Content Filter',
      ],
      ...rows,
    ];

    createCSV(exportRows);
  }

  function customerToExportRow(customer) {
    return [
      customer.customer.details.customer.name || customer.community.name,
      format(new Date(customer.customer.created_on), 'd MMMM yyyy'),
      customer.customer.country || 'N/A',
      Utility.getStatus(customer.customer),
      customer.customer.partner_name ? customer.customer.partner_name : 'N/A',
      customer.community.max_number_of_users || 'N/A',
      customer.community.total_number_of_users + customer.community.total_number_of_starter_users || 'N/A',
      typeof customer.activeUsers === 'number'
        ? customer.activeUsers
        : typeof stats.activeUsersCommunities[customer.community.slug] === 'number'
        ? stats.activeUsersCommunities[customer.community.slug]
        : 'N/A',
      customer.customer.statistic.media || 'N/A',
      customer.customer.statistic.documents || 'N/A',
      customer.customer.statistic.all_files || 'N/A',
      customer.community.product.name || 'N/A',
      customer.customer.details.customer.address || 'N/A',
      customer.customer.details.customer.city || 'N/A',
      customer.customer.details.customer.postcode || 'N/A',
      customer.customer.details.customer.first_name + ' ' + customer.customer.details.customer.last_name,
      customer.customer.details.customer.email || 'N/A',
      customer.customer.details.customer.phone || 'N/A',
      customer.customer.has_self_service ? 'Self service' : 'Managed',
      customer.community.if_sso_enabled ? 'Yes' : 'No',
      customer.community.disable_email_login ? 'No' : 'Yes',
      customer.community.market_status === 'all' ? 'Yes' : 'No',
      customer.community.curator.is_active ? 'Yes' : 'No',
      customer.community.content_filter || 'N/A',
    ];
  }

  async function exportAllRows() {
    setExportStartDate(new Date());
    setIsExporting(true);
    const pages = Math.ceil(totalCustomers / 200);
    setTotalExportingProgress(pages * 2);
    const requests = Array.from(new Array(pages).keys()).map((page) => page + 1);
    Promise.all(
      requests.map((page) => {
        return new Promise(async (resolve, reject) => {
          const customers = await context.getCustomersForExport(page, getParams());
          setExportingProgress('increase');
          const slugsList = customers.data.map((customer) => customer.community.slug);
          const activeUsers = await stats.getActiveUsersByCommunities(slugsList);
          setExportingProgress('increase');
          Object.keys(activeUsers).forEach((slug) => {
            const customer = customers.data.find((customer) => customer.community.slug === slug);
            customer.activeUsers = activeUsers[slug];
          });
          resolve(customers.data);
        });
      })
    ).then(async (customers) => {
      const allCustomers = customers.reduce((results, customers) => {
        return [...results, ...customers];
      }, []);
      exportTable(allCustomers.map((customer) => customerToExportRow(customer)));
      setIsExporting(false);
    });
  }
  const selectAllServices = () => {
    query.set(
      'policies',
      allServices.map((policyService) => policyService.path.replace('access.', ''))
    );
  };

  let customerRows = !isLoading ? (
    customers.map((customer, index) => {
      let status = Utility.getStatus(customer.customer);
      totalLicenses += customer.community.max_number_of_users;
      totalLicensed += customer.community.total_number_of_users;
      totalActive += customer.customer.statistic.previous_thirty_days;
      totalFiles += customer.customer.statistic.all_files;
      const name = customer.customer.details.customer.name || customer.community.name;
      return (
        <TableRow hover key={index} className={classes.tableRow}>
          {customer.community.profile_image_url ? (
            <TableCell
              className={classes.customerLogo}
              style={{ backgroundColor: customer.community.colour }}
              onClick={onCustomerSelect(customer.customer.tenant_id)}
              padding="normal"
              key={index}
              width="1px">
              <img src={customer.community.profile_image_url} alt={'Customer Logo' + name} className={classes.customerLogoImg} />
            </TableCell>
          ) : (
            <TableCell
              className={`${classes.customerLogoNoBranding}`}
              onClick={onCustomerSelect(customer.customer.tenant_id)}
              padding="normal"
              key={index}
              width="1px">
              <div className="squares" style={{ width: '100%', height: '100%' }}></div>
              <span
                style={{
                  width: '100%',
                  height: '100%',
                  fontWeight: 500,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                N/A
              </span>
            </TableCell>
          )}
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)} className={classes.noWrap + ' ' + classes.tableCellFocus}>
            <Box style={{ textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden' }} title={name}>
              {name}
            </Box>
          </TableCell>
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)}>
            {new Date(customer.customer.created_on).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </TableCell>
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)} className={classes.noWrap + ' ' + classes.tableCellFocus}>
            {customer.customer.country}
          </TableCell>
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)} className={classes.noWrap}>
            <span>{startCase(status)}</span>
          </TableCell>
          {props.pass.isStaff() && (
            <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)} className={classes.noWrap}>
              {customer.customer.partner_name ? customer.customer.partner_name : 'N/A'}
            </TableCell>
          )}
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)}>{customer.community.max_number_of_users}</TableCell>
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)}>{customer.community.total_number_of_users}</TableCell>
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)} title={context.lastMonth}>
            {customer.customer.statistic.previous_thirty_days}
          </TableCell>
          <TableCell onClick={onCustomerSelect(customer.customer.tenant_id)}>{customer.customer.statistic.all_files}</TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow style={{ height: '50vh' }}>
      <TableCell colSpan={12}>
        <LinearProgress />
      </TableCell>
    </TableRow>
  );

  const CustomerPagination = (props) => {
    return (
      <>
        <Typography>Result: {props.totalCustomers}</Typography>
        <Typography style={{ marginLeft: 'auto', marginRight: 10 }}>Customers per page:</Typography>

        <Select
          value={Number(props.page_limit) || 25}
          onChange={(event) => {
            props.query.set('page_limit', parseInt(event.target.value, 10));
            window.scrollTo(0, 0);
          }}>
          {[25, 50, 100].map((rowsPerPage) => (
            <MenuItem value={rowsPerPage}>{rowsPerPage}</MenuItem>
          ))}
        </Select>
        <Pagination
          count={Math.ceil(props.totalCustomers / props.page_limit) || 0}
          page={Number(props.page_nr ?? 1)}
          onChange={(event, page) => {
            props.query.set('page_nr', Number(page));
            window.scrollTo(0, 0);
          }}
        />
      </>
    );
  };
  props.pass.setCounter(totalCustomers);

  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing} justifyContent={'center'}>
        <Grid item xs={12}>
          <Paper elevation={2} square>
            <Toolbar>
              {!props.pass.isReadOnly() && (
                <Button
                  variant="contained"
                  startIcon={<Icon>add</Icon>}
                  size="large"
                  color={'primary'}
                  onClick={onNewCustomerClick}
                  style={{ marginTop: 10 }}>
                  Add Customer
                </Button>
              )}
              <IconButton color={'primary'} onClick={getCustomers} style={{ marginLeft: 10, marginTop: 11 }}>
                <Icon className="material-icons" style={{ color: '#727272' }}>
                  refresh
                </Icon>
              </IconButton>
            </Toolbar>
            <div id="test" />
            <Toolbar>
              <Grid container wrap="nowrap" alignItems="center">
                <Snackbar
                  open={!!(exportingProgress && totalExportingProgress === exportingProgress)}
                  message={`Export finished in ${Math.round((differenceInMilliseconds(new Date(), exportStartDate) / 1000) * 100) / 100} seconds`}
                  TransitionProps={{
                    onEnter: () => {
                      setTimeout(() => {
                        setTotalExportingProgress(0);
                        setExportingProgress('reset');
                        setExportStartDate(null);
                      }, 6000);
                    },
                  }}
                />

                {props.pass.isStaff() && (
                  <>
                    <FormControl size="small" variant="filled" fullWidth style={{ maxWidth: 300 }}>
                      <InputLabel>Relation</InputLabel>
                      <Select
                        disableUnderline
                        name={'has_self_service'}
                        value={params.has_self_service || 'all'}
                        onChange={onFilterChanged}
                        MenuProps={{
                          variant: 'menu',
                          style: { maxHeight: 800 },
                        }}>
                        <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'false'}>Managed</MenuItem>
                        <MenuItem value={'true'}>Self Service</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl size="small" variant="filled" fullWidth style={{ marginLeft: 10, maxWidth: 300 }}>
                      <InputLabel>Partner</InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        disableUnderline
                        name={'partner'}
                        value={params.partner || 'undefined'}
                        onChange={onFilterChanged}
                        MenuProps={{
                          variant: 'menu',
                          style: { maxHeight: 800 },
                        }}>
                        {partnerFilterRows}
                      </Select>
                    </FormControl>
                  </>
                )}
                <FormControl size="small" variant="filled" fullWidth style={{ marginLeft: 10, maxWidth: 300 }}>
                  <InputLabel>Customer Status</InputLabel>
                  <Select
                    disableUnderline
                    name={'status'}
                    value={params.status || 'undefined'}
                    onChange={onFilterChanged}
                    MenuProps={{
                      variant: 'menu',
                      style: { maxHeight: 800 },
                    }}>
                    {statusFilterRows}
                  </Select>
                </FormControl>
                <FormControl size="small" variant="filled" fullWidth style={{ marginLeft: 10, maxWidth: 100 }}>
                  <InputLabel>Family</InputLabel>
                  <Select
                    disableUnderline
                    name={'family'}
                    value={params.family || 'all'}
                    onChange={onFilterChanged}
                    MenuProps={{
                      variant: 'menu',
                      style: { maxHeight: 300 },
                    }}>
                    {familyFilterRows}
                  </Select>
                </FormControl>

                {props.pass.isStaff() && (
                  <FormControl variant="filled" size="small" fullWidth style={{ marginLeft: 10, maxWidth: 300 }}>
                    <InputLabel id="policy-filter">Policies</InputLabel>
                    <Select
                      labelId="policy-filter"
                      id="policy-filter-select"
                      variant="filled"
                      multiple
                      value={params?.policies?.split(',') ?? []}
                      onChange={onFilterChanged}
                      input={<FilledInput disableUnderline label="Policies" />}
                      MenuProps={{
                        variant: 'menu',
                        style: { maxHeight: 800 },
                      }}
                      renderValue={(selected) => {
                        if (allServices.length === 0) return <></>;
                        return selected
                          .map(
                            (key) =>
                              allServices.find((service) => {
                                return service.path.includes(key);
                              })?.name ?? key
                          )
                          .join(', ');
                      }}
                      placeholder="Policies"
                      name="policies"

                      //MenuProps={MenuProps}
                    >
                      <Box fullWidth style={{ margin: 10, width: 120, display: 'inline-block' }} key="selectAll" value="selectAll">
                        <Button color="primary" variant="contained" fullWidth>
                          Select all
                        </Button>
                      </Box>
                      <Box fullWidth style={{ margin: 10, width: 120, display: 'inline-block' }} key="deselectAll" value="deselectAll">
                        <Button color="secondary" variant="contained" fullWidth>
                          Reset
                        </Button>
                      </Box>
                      <MenuItem disabled>Services</MenuItem>
                      {data.services?.services
                        ?.filter((feature) => !hiddenServiceKeys?.includes(feature.key || feature.slug))
                        ?.map((service) => (
                          <MenuItem
                            key={service.path + params.policies?.includes(service.path?.replace('access.', ''))}
                            value={service.path?.replace('access.', '')}>
                            <Checkbox color={'primary'} checked={params.policies?.includes(service.path?.replace('access.', ''))} />
                            <ListItemText primary={service.name} />
                          </MenuItem>
                        ))}
                      <MenuItem disabled>Features</MenuItem>
                      {data.services?.features
                        ?.filter((feature) => !hiddenServiceKeys?.includes(feature.key || feature.slug))
                        ?.map((feature) => (
                          <MenuItem
                            key={feature.path + params.policies?.includes(feature.path?.replace('access.', ''))}
                            value={feature.path?.replace('access.', '')}>
                            <Checkbox color={'primary'} checked={params.policies?.includes(feature.path?.replace('access.', ''))} />
                            <ListItemText primary={feature.name} />
                          </MenuItem>
                        ))}
                      <MenuItem disabled>Integrations</MenuItem>
                      {data.services?.integrations
                        ?.filter((feature) => !hiddenServiceKeys?.includes(feature.key || feature.slug))
                        ?.map((integration) => (
                          <MenuItem
                            key={integration.path + params.policies?.includes(integration.path?.replace('access.', ''))}
                            value={integration.path?.replace('access.', '')}>
                            <Checkbox color={'primary'} checked={params.policies?.includes(integration.path?.replace('access.', ''))} />
                            <ListItemText primary={integration.name} />
                          </MenuItem>
                        ))}{' '}
                    </Select>
                  </FormControl>
                )}

                <FormControl variant="filled" fullWidth style={{ marginLeft: 10, maxWidth: 300 }}>
                  <TextField
                    name={'search'}
                    size="small"
                    variant="filled"
                    value={params.search ?? ''}
                    onChange={(e) => query.set('search', e.target.value)}
                    label="Search"
                  />
                </FormControl>
                <IconButton color={'primary'} onClick={() => applyDefaultParams(true)} style={{ marginLeft: 'auto', marginTop: 4 }}>
                  <Icon className="material-icons" style={{ color: '#727272' }}>
                    rotate_left
                  </Icon>
                </IconButton>
                <Button size="large" disabled={isExporting} style={{ flexShrink: 0, marginLeft: 0 }} onClick={exportAllRows}>
                  {!isExporting ? 'Export results' : 'Exporting data... (' + Math.floor((exportingProgress / totalExportingProgress) * 100) + '%)'}
                </Button>
              </Grid>
            </Toolbar>

            <Toolbar>
              <CustomerPagination {...{ query, totalCustomers, page_nr: params.page_nr, page_limit: params.page_limit }} />
            </Toolbar>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Table style={{ tableLayout: 'auto' }} size={'small'} padding="checkbox">
                  <TableHead>
                    <TableRow>
                      <TableCell variant={'head'} size="small" padding="normal" width="1px">
                        Branding
                      </TableCell>
                      <TableCell variant={'head'} size="small">
                        <TableSortLabel
                          active={params.sort_field === 'name_asc' || params.sort_field === 'name_desc'}
                          direction={params.sort_field === 'name_desc' ? 'desc' : 'asc'}
                          id="name"
                          onClick={setOrder}>
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant={'head'} size="small">
                        <TableSortLabel
                          active={params.sort_field === 'date_asc' || params.sort_field === 'date_desc'}
                          direction={params.sort_field === 'date_desc' ? 'desc' : 'asc'}
                          id="date"
                          onClick={setOrder}>
                          Created
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant={'head'} size="small">
                        <TableSortLabel
                          active={params.sort_field === 'country_asc' || params.sort_field === 'country_desc'}
                          direction={params.sort_field === 'country_desc' ? 'desc' : 'asc'}
                          id="country"
                          onClick={setOrder}>
                          Country
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant={'head'} size="small">
                        <TableSortLabel
                          active={params.sort_field === 'status_asc' || params.sort_field === 'status_desc'}
                          direction={params.sort_field === 'status_desc' ? 'desc' : 'asc'}
                          id="status"
                          onClick={setOrder}>
                          Status
                        </TableSortLabel>
                      </TableCell>
                      {props.pass.isStaff() && (
                        <TableCell variant={'head'} size="small">
                          <TableSortLabel
                            active={params.sort_field === 'partner_asc' || params.sort_field === 'partner_desc'}
                            direction={params.sort_field === 'partner_desc' ? 'desc' : 'asc'}
                            id="partner"
                            onClick={setOrder}>
                            Partner
                          </TableSortLabel>
                        </TableCell>
                      )}
                      <TableCell variant={'head'} size="small">
                        <TableSortLabel
                          active={params.sort_field === 'licenses_asc' || params.sort_field === 'licenses_desc'}
                          direction={params.sort_field === 'licenses_desc' ? 'desc' : 'asc'}
                          id="licenses"
                          onClick={setOrder}>
                          Licenses
                        </TableSortLabel>
                        <div style={{ fontSize: 10, marginTop: -6 }}>{totalLicenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>
                      </TableCell>
                      <TableCell variant={'head'} size="small">
                        <TableSortLabel
                          active={params.sort_field === 'licensed_asc' || params.sort_field === 'licensed_desc'}
                          direction={params.sort_field === 'licensed_desc' ? 'desc' : 'asc'}
                          id="licensed"
                          onClick={setOrder}>
                          Users
                        </TableSortLabel>
                        <div style={{ fontSize: 10, marginTop: -6 }}>{totalLicensed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>
                      </TableCell>
                      <TableCell variant={'head'} size="small" style={{ width: 125, whiteSpace: 'nowrap' }}>
                        <TableSortLabel
                          active={params.sort_field === 'activeusers_asc' || params.sort_field === 'activeusers_desc'}
                          direction={params.sort_field === 'activeusers_desc' ? 'desc' : 'asc'}
                          id="activeusers"
                          onClick={setOrder}>
                          Active Users <sup>*</sup>
                        </TableSortLabel>

                        <div style={{ fontSize: 10, marginTop: -6 }}>
                          {totalActive.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} / <sup>*</sup> for the last 30 days
                        </div>
                      </TableCell>
                      <TableCell variant={'head'} size="small" style={{ width: 125, whiteSpace: 'nowrap' }}>
                        <TableSortLabel
                          active={params.sort_field === 'files_asc' || params.sort_field === 'files_desc'}
                          direction={params.sort_field === 'files_desc' ? 'desc' : 'asc'}
                          id="files"
                          onClick={setOrder}>
                          Files
                        </TableSortLabel>

                        <div style={{ fontSize: 10, marginTop: -6 }}>{totalFiles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{customerRows}</TableBody>
                </Table>
              </Grid>
            </Grid>
            <Toolbar>
              <CustomerPagination {...{ query, totalCustomers, page_nr: params.page_nr, page_limit: params.page_limit }} />
            </Toolbar>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

Customers.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Customers);

import React, { useEffect, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PortalDataContext } from 'Contexts/PortalDataContext';

import {
  Grid,
  Paper,
  Typography,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Icon,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import PickitAPI from 'PickitAPI';
import clone from 'clone';
import SalesUIPartner from 'PickitAPI/Partner';
import LoadingScreen from 'Components/LoadingScreen';
import NativeSelect from '@material-ui/core/NativeSelect/NativeSelect';
import { set } from 'lodash';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import slugify from 'slugify';
import Utility from 'Services/utils';
import { Snackbar } from 'Components';
import usePresets from 'hooks/usePresets';

const gridSpacing = 5;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
    paddingBottom: 50,
  },
  paper: {
    minHeight: '60vh',
  },
  title: {
    paddingTop: 20,
  },
  grid: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30,
  },
  toolbarHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    paddingLeft: 15,
  },
  saveButton: {
    display: 'flex',
    padding: '5px 40px',
    minHeight: 24,
    minWidth: 64,
    boxShadow: 'none',
    alignItems: 'center',
  },
  cancelButton: {
    display: 'inline-block',
    backgroundColor: '#ffffff !important',
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  textValidator: {
    '& > label': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
  textValidatorWithDomain: {
    '& > label': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.4)',
      //whiteSpace: 'nowrap',
    },
    '& > div': {
      position: 'relative',

      '& > input': {
        textAlign: 'right',
        paddingRight: 80,
      },
    },
  },
  formControl: {
    width: '100%',
  },
  formControlWithDomain: {
    position: 'relative',

    '& > span': {
      position: 'absolute',
      right: 0,
      top: 25,
      fontWeight: 'bold',
    },
  },
  selectDisabled: {
    '& > div, & > label': {
      opacity: '0.25',
    },
  },
  removeUnderline: {
    '& > div::after': {
      border: 'none !important',
    },
    '& > div::before': {
      border: 'none !important  ',
    },
    '&::after': {
      border: 'none !important',
    },
    '&::before': {
      border: 'none !important  ',
    },
  },
});
function PartnerNew(props) {
  const form = useRef('form');
  const { classes } = props;
  let testMode = props.pass.isStaff ? !!props.pass.testMode() : false;
  const context = useContext(PortalDataContext);

  const [request, setRequest] = useState({
    partner: {
      type: 'reseller',
      parent: '',
      name: '',
      address: '',
      postcode: '',
      country: 'SE',
      city: '',

      email: '',
      tenant_id: '',
      contact_name: '',
      domain: '',
      is_test: testMode,
    },
    community_url: '',
  });
  const [countries, setCountries] = useState(Utility.getCountries());
  const [creating, setCreating] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { data } = usePresets();

  const loadingMessage = 'Preparing data...';
  let request_ = clone(request);

  useEffect(() => {
    // Allow only Distributors (staff) with admin rights
    if (props.pass.isReadOnly() || !props.pass.isDistributor()) {
      props.route.history.push('/partners/' + (props.pass.testMode() ? '?test=true' : ''));
    }

    props.pass.setCounter(undefined);
    props.pass.setTitle('Create New Partner');

    if (PickitAPI.cache.get('sales_ui_countries')) {
      setCountries(PickitAPI.cache.get('sales_ui_countries'));
    } else {
      PickitAPI.helpers.getCountries().then((countries) => {
        setCountries(countries);

        PickitAPI.cache.set('sales_ui_countries', countries);
      });
    }

    ValidatorForm.addValidationRule('isSlugMatch', (value) => {
      return value !== undefined && value.length && /^[0-9a-z-]+$/i.test(value.toLowerCase());
    });

    let request_ = clone(request);
    if (PickitAPI.cache.get('latest_partner_parent') && props.pass.isStaff()) {
      request_.partner.parent = PickitAPI.cache.get('latest_partner_parent');
    }
    if (PickitAPI.cache.get('latest_partner_country')) {
      request_.partner.country = PickitAPI.cache.get('latest_partner_country');
    }
    // Setup Distributor tenant_id as parent for any new Partner
    if (!props.pass.isStaff()) {
      request_.partner.parent = props.pass.getPartnerTenantId();
      request_.partner.customer_preset_id = props.pass.getPartnerData().customer_preset_id;
    }
    setRequest(request_);
  }, []);

  const handleFieldUpdate = (event) => {
    let state_ = { request: clone(request) },
      value_ = event.target.value;

    set(state_, event.target.name, value_);

    if (event.target.name === 'request.partner.name') {
      let slug = slugify(event.target.value);
      slug = slug.toLowerCase().replace(/[^0-9a-zA-Z]+/g, '');
      set(state_, 'request.community_url', slug);
    }
    if (event.target.value === 'distributor') {
      set(state_, 'request.partner.parent', '');
    }
    if (event.target.name !== 'request.partner.type') {
      if (props.pass.isStaff()) {
        PickitAPI.cache.set('latest_partner_parent', state_.request.partner.parent);
      }
      PickitAPI.cache.set('latest_partner_country', state_.request.partner.country);
    }
    if (
      event.target.name === 'request.partner.type' &&
      event.target.value === 'reseller' &&
      PickitAPI.cache.get('latest_partner_parent') &&
      props.pass.isStaff()
    ) {
      state_.request.partner.parent = PickitAPI.cache.get('latest_partner_parent');
    }

    setRequest(state_.request);
  };

  const onCancelNewPartner = () => {
    if (props.route) {
      props.route.history.push('/partners/' + (props.pass.testMode() ? '?test=true' : ''));
    }
  };

  const onCreateNewPartner = () => {
    setCreating(true);

    SalesUIPartner.partners
      .post(request)
      .then((data) => {
        context.addPartner(data.data[0]);
        setTimeout(() => {
          props.route.history.push('/partner/' + data.data[0].tenant_id + (props.pass.testMode() ? '?test=true' : ''));
        }, 500);
      })
      .catch((e) => {
        e.json().then((errorJson) => {
          console.warn('errorJson', errorJson);
          if (errorJson.errors) {
            let msg = errorJson.error.toString() + '. ';
            Object.keys(errorJson.errors).forEach((k) => {
              msg += errorJson.errors[k] + '. ';
            });
            setCreating(false);
            setSnackbarOpen(true);
            setSnackbarMessage('Something went wrong. ' + msg, 'error');
          } else {
            setCreating(false);
            setSnackbarOpen(true);
            setSnackbarMessage('Something went wrong. ' + errorJson.error.toString(), 'error');
          }
        });
      });
  };

  const onSnackbarClose = (event, reason) => {
    setSnackbarOpen(false);
  };
  if (!!props.pass.testMode() !== request.partner.is_test) {
    request_.partner.is_test = props.pass.isStaff ? !!props.pass.testMode() : false;
    request_.partner.parent = '';
    setRequest(request_);
  }

  if (!countries) {
    return <LoadingScreen loadingMessage={loadingMessage} />;
  }

  let partner = request.partner;
  let country_keys = Object.keys(countries);
  country_keys.sort((a, b) => countries[a].localeCompare(countries[b]));

  const countriesRows = country_keys.map((country) => {
    return (
      <option value={country} key={country}>
        {countries[country]}
      </option>
    );
  });

  const distributorRows = context.partnerList
    ? context.partnerList
        .filter((i) => i.type.toLowerCase() === 'distributor')
        .map((distributor) => {
          return (
            <option value={distributor.tenant_id} key={distributor.id}>
              {distributor.name || distributor.tenant_id} {distributor.parent ? '(' + distributor.parent.name + ')' : ''}
            </option>
          );
        })
    : [];

  distributorRows.unshift(
    <option value={''} key={'NaN'}>
      {'Distributor*'}
    </option>
  );
  const saveDisabled =
    (!request_.partner.parent && props.pass.isStaff() && request_.partner.type === 'reseller') ||
    !request_.partner.name ||
    !request_.partner.contact_name ||
    !request_.partner.email;
  return (
    <div className={classes.root}>
      <ValidatorForm ref={form} onSubmit={onCreateNewPartner}>
        <Grid container spacing={gridSpacing} justifyContent={'center'}>
          <Grid item xs={7}>
            <Paper elevation={2} square>
              <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12}>
                  <Typography variant={'h5'} className={classes.title} style={{ paddingTop: 4 }}>
                    {'Partner Information'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {props.pass.isStaff() && (
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={6}>
                        <Typography variant={'h7'} className={classes.title} style={{ paddingTop: 20 }}>
                          {'Partner Type'}
                        </Typography>
                        <FormControl component={'fieldset'} className={classes.formControl}>
                          <RadioGroup
                            name={'request.partner.type'}
                            className={classes.group}
                            value={partner.type}
                            onChange={handleFieldUpdate}
                            style={{ flexDirection: 'row' }}>
                            <FormControlLabel
                              key={'reseller'}
                              value={'reseller'}
                              control={<Radio color={'primary'} />}
                              label={'Reseller'}
                              style={{ marginRight: 40 }}
                              disabled={creating}
                            />
                            <FormControlLabel
                              key={'distributor'}
                              value={'distributor'}
                              control={<Radio color={'primary'} />}
                              label={'Distributor'}
                              disabled={creating}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                  )}
                  <Grid container spacing={gridSpacing}>
                    {props.pass.isStaff() && (
                      <Grid item xs={3} style={{ paddingTop: 10, paddingBottom: 0 }}>
                        <FormControl className={classes.formControl} fullWidth>
                          {request.partner.type === 'reseller' ? (
                            <NativeSelect
                              name={'request.partner.parent'}
                              label={'Distributor'}
                              onChange={handleFieldUpdate}
                              value={partner.parent}
                              validators={['required']}
                              errorMessages={['this field is required']}
                              className={`${classes.textValidator} ${classes.removeUnderline}`}
                              required
                              disabled={creating}>
                              {distributorRows}
                            </NativeSelect>
                          ) : (
                            <SelectValidator
                              disabled
                              name={'request.partner.parent'}
                              label={'Distributor'}
                              onChange={handleFieldUpdate}
                              value={partner.parent}
                              errorMessages={['this field is required']}
                              className={classes.textValidator + ' ' + classes.selectDisabled}
                              required={false}>
                              {distributorRows}
                            </SelectValidator>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} style={{ padding: 0 }} />
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'request.partner.name'}
                          label={'Partner Name'}
                          onChange={handleFieldUpdate}
                          value={partner.name}
                          validators={['required']}
                          errorMessages={['this field is required']}
                          className={classes.textValidator}
                          required
                          disabled={creating}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'request.partner.address'}
                          value={partner.address || ''}
                          label={'Invoice/Physical Address'}
                          onChange={handleFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'request.partner.city'}
                          value={partner.city}
                          label={'City'}
                          onChange={handleFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'request.partner.postcode'}
                          value={partner.postcode}
                          label={'Zip'}
                          onChange={handleFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={3}>
                      <FormControl className={classes.formControl} fullWidth>
                        <FormHelperText>{partner.country ? 'Country' : ''}</FormHelperText>
                        <NativeSelect
                          name={'request.partner.country'}
                          value={partner.country}
                          placeholder={'Country'}
                          className={`${classes.textValidator} ${classes.removeUnderline}`}
                          onChange={handleFieldUpdate}
                          disabled={creating}>
                          {countriesRows}
                        </NativeSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h6'} className={classes.title}>
                    {'Administrator'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'request.partner.contact_name'}
                          label={'Name of the User'}
                          value={partner.contact_name}
                          onChange={handleFieldUpdate}
                          validators={['required']}
                          errorMessages={['this field is required']}
                          className={classes.textValidator}
                          required
                          disabled={creating}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'request.partner.email'}
                          label={'E-mail Address'}
                          value={partner.email}
                          onChange={handleFieldUpdate}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'incorrect E-mail']}
                          className={classes.textValidator}
                          required
                          disabled={creating}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h6'} className={classes.title}>
                    {'Pickit image bank'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          disabled
                          label={'Owner E-mail Address'}
                          onChange={handleFieldUpdate}
                          value={partner.email}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'incorrect E-mail']}
                          className={classes.textValidator}
                          required={false}
                          fullWidth
                        />
                      </FormControl>
                      <Tooltip
                        arrow
                        title={
                          <p style={{ margin: 0, padding: 0, fontSize: '.75rem' }}>
                            The partner administrator is initially used as the imagebank owner
                          </p>
                        }>
                        <Icon style={{ alignSelf: 'flex-end', marginLeft: 5 }}>info</Icon>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                      <FormControl className={classes.formControlWithDomain} fullWidth>
                        <TextValidator
                          name={'request.community_url'}
                          onChange={handleFieldUpdate}
                          value={request.community_url}
                          validators={['isSlugMatch']}
                          label={'Community URL'}
                          errorMessages={['incorrect URL format']}
                          className={classes.textValidatorWithDomain}
                          disabled={creating}
                          fullWidth
                        />
                        <span>.pickit.com</span>
                      </FormControl>
                      <Tooltip arrow title={<p style={{ margin: 0, padding: 0, fontSize: '.75rem' }}>The URL used for content curation</p>}>
                        <Icon style={{ alignSelf: 'flex-end', marginLeft: 5 }}>info</Icon>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                {props.pass.isStaff() && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant={'h6'} className={classes.title}>
                        {'Policy Configuration'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={gridSpacing}>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl} style={{ marginBottom: 20 }}>
                            <InputLabel>Policy preset</InputLabel>
                            <Select
                              label="Policy preset"
                              name={'request.partner.customer_preset_id'}
                              value={partner.customer_preset_id}
                              onChange={handleFieldUpdate}>
                              {data?.presets?.map((preset) => (
                                <MenuItem key={preset.preset_id} value={preset.preset_id}>
                                  {preset.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}></Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item xs={6} style={{ display: 'flex', marginTop: 50 }}>
                  <br />
                  <br />
                  <Button variant={'contained'} color={'primary'} type={'submit'} className={classes.saveButton} disabled={creating || saveDisabled}>
                    {creating ? <CircularProgress size={24} /> : 'Create'}
                  </Button>
                  <Button
                    variant={'text'}
                    disableRipple
                    color={'primary'}
                    type={'reset'}
                    className={classes.cancelButton}
                    onClick={onCancelNewPartner}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              <br />
              <br />
            </Paper>
          </Grid>
        </Grid>
      </ValidatorForm>
      <Snackbar open={snackbarOpen} message={snackbarMessage} severity="error" onClose={onSnackbarClose} />
    </div>
  );
}

PartnerNew.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PartnerNew);

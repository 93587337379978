import React, { useState, useContext, useEffect } from 'react';
import { clone, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PortalDataContext } from 'Contexts/PortalDataContext';

import {
  Grid,
  Paper,
  TableCell,
  Tabs,
  Tab,
  Tooltip,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TablePagination,
  TableFooter,
  TextField,
  InputAdornment,
  Icon,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import ErrorScreen from 'Components/ErrorScreen';
import LoadingScreen from 'Components/LoadingScreen';
import PartnerInfo from 'Components/PartnerInfo';
import Button from '@material-ui/core/Button';
import { Snackbar } from 'Components';

import Utility from 'Services/utils.js';
import Users from 'Components/Users';
import useRouterQuery from 'hooks/useRouterQuery';
import SalesUICustomer from 'PickitAPI/Customer';
import SalesUIPartner from 'PickitAPI/Partner';
import useUsage from 'hooks/useUsage';
import { subDays } from 'date-fns';

const gridSpacing = 2;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
  },
  paper: {
    minHeight: '89%',
  },
  tab: {
    width: '50%',
  },
  noWrap: {
    cursor: 'pointer',
    fontSize: 16,
    paddingTop: 30,
    paddingBottom: 30,
  },
  paperTab: {
    width: '50%',
    maxWidth: 'none',
    height: 70,
    fontSize: '1rem',
    textTransform: 'none',
  },
  paperTabFull: {
    width: '100%',
  },
  tabs: {
    height: 70,

    '& > div > div > span': {
      height: 4,
    },
  },
  focusCell: {
    fontWeight: 500,
  },
  descriptor: {
    position: 'absolute',
    bottom: 0,
    margin: 0,
    fontSize: 12,
    color: 'gray',
    whiteSpace: 'nowrap',
  },
});

let searchTimeout = undefined;
function Partner(props) {
  const stats = useUsage(null, subDays(new Date(), 30), new Date());
  const context = useContext(PortalDataContext);

  const { classes } = props;

  const [loader, setLoader] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Getting partner...');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogPartner, setDialogPartner] = useState({ customers: [] });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState(undefined);
  const [partnerCustomers, setPartnerCustomers] = useState([]);
  const [totalPartnerCustomers, setTotalPartnerCustomers] = useState(0);
  const [partnerPartners, setPartnerPartners] = useState([]);
  const [totalPartnerPartners, setTotalPartnerPartners] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const query = useRouterQuery();
  const { params } = query;
  const defaultParams = [
    { key: 'page_nr', value: 1 },
    { key: 'page_limit', value: 25 },
  ];
  const setBaseParams = () => {
    defaultParams.forEach(({ key, value }) => {
      if (!params[key]) {
        query.set(key, value);
      }
    });
  };
  useEffect(() => {
    // Null counter
    props.pass.setCounter(undefined);
    if (props.route.match.params.id === props.pass.getPartnerTenantId()) {
      setTabIndex(1);
    }
    setLoader(true);
    setLoadingMessage('Getting partner..');

    setBaseParams();
    try {
      setActivePartner();
    } catch (e) {
      console.error(e);
    }
    if (context.selectedPartner?.partner?.tenant_id) {
      getFields();
    }
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (context.selectedPartner?.partner) getFields();
    }
  }, [params.page_nr, params.page_limit, context.selectedPartner?.partner?.tenant_id]);

  useEffect(() => {
    if (isMounted) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        if (params.page_nr === 1 || params.page_nr === '1') {
          getFields();
        } else {
          query.set('page_nr', 1);
        }
      }, 1000);
    }
  }, [params.search]);

  useEffect(() => {
    if (partnerCustomers?.length > 0) {
      const communities = partnerCustomers.map((customer) => customer.community.slug).filter((community) => !stats.activeUsersCommunities[community]);
      stats.getActiveUsersByCommunities(communities);
    }
  }, [partnerCustomers.length]);

  const getFields = async () => {
    setPartnerCustomers([]);
    setPartnerPartners([]);
    setTotalPartnerCustomers(0);
    setTotalPartnerPartners(0);
    const newParams = clone(params);
    if (newParams.search) {
      newParams.search = newParams.search.toLowerCase();
    }
    if (context.selectedPartner.partner.type === 'reseller') {
      const result = await SalesUICustomer.customers.getForPartner(
        {
          partner: context.selectedPartner.partner.tenant_id,
          parent: props.pass.isDistributor() ? props.pass.getPartnerTenantId() : undefined,
          community_slug: context.selectedPartner.partnerCustomer.community.slug,
          ...newParams,
        },
        props.pass.testMode()
      );
      setPartnerCustomers(result.data);
      setTotalPartnerCustomers(result.total_count);
    }
    if (context.selectedPartner.partner.type === 'distributor') {
      const result = await SalesUIPartner.partners.getFiltered(
        {
          parent: context.selectedPartner.partner.tenant_id,
          ...newParams,
        },
        props.pass.testMode(),
        context.selectedPartner.partnerCustomer.community.slug
      );
      setPartnerPartners(result.data);
      setTotalPartnerPartners(result.total_count);
    }
  };

  if (props.route.match.params.id === props.pass.getPartnerTenantId() && tabIndex !== 1) {
    setTabIndex(1);
  }
  const setActivePartner = async () => {
    try {
      await context.setSelectedPartner(props.route.match.params.id);
      setLoader(false);
      //getUsers();
    } catch (e) {
      console.error(e);
      setError(true);
      setErrorMessage(typeof e === 'string' ? e : 'unknown');
    }
  };

  const onTabChange = (event, value) => {
    setTabIndex(value);
  };

  const onSnackbarClose = (event, reason) => {
    setSnackbarOpen(false);
  };

  const handleLoader = (loader, message) => {
    setLoader(loader);
    setLoadingMessage(message);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const onCustomerSelect = (customer_uuid) => (event) => {
    if (props.route) {
      props.route.history.push('/customer/' + customer_uuid + (props.pass.testMode() ? '?test=true' : ''));
    }
  };

  const onPartnerSelect = (partner_uuid) => (event) => {
    props.route.history.push('/partner/' + partner_uuid + (props.pass.testMode() ? '?test=true' : ''));
  };

  const onPartnerSelectDetails = (partner) => (event) => {
    setDialogOpen(true);
    setDialogPartner(partner);
  };

  const onPartnerSelectDetailsClose = (event) => {
    setDialogOpen(false);
  };

  const getCustomerRows = (partnerCustomers) => {
    let totalLicenses = 0,
      totalLicensed = 0,
      totalActive = 0;
    let partnerCustomerRows = partnerCustomers.map((partnerCustomer) => {
      let status = Utility.getStatus(partnerCustomer.customer);
      //if (partnerCustomer.customer.tenant_id === props.route.match.params.id) return undefined;
      if (partnerCustomer.customer.tenant_id !== props.route.match.params.id) {
        totalLicenses += partnerCustomer.community.max_number_of_users;
        totalLicensed += partnerCustomer.community.total_number_of_users;
        totalActive += partnerCustomer.customer.statistic.previous_thirty_days;
      }
      return (
        <TableRow hover key={partnerCustomer.customer.tenant_id}>
          <TableCell
            size="small"
            onClick={props.pass.isStaff() ? onCustomerSelect(partnerCustomer.customer.tenant_id) : () => {}}
            className={`${classes.noWrap} ${classes.focusCell}`}>
            {partnerCustomer.customer.details.customer.name || partnerCustomer.customer.tenant_id}
          </TableCell>
          <TableCell
            size="small"
            onClick={props.pass.isStaff() ? onCustomerSelect(partnerCustomer.customer.tenant_id) : () => {}}
            className={classes.noWrap}>
            {partnerCustomer.community.max_number_of_users}
          </TableCell>
          <TableCell
            size="small"
            onClick={props.pass.isStaff() ? onCustomerSelect(partnerCustomer.customer.tenant_id) : () => {}}
            className={classes.noWrap}>
            {partnerCustomer.community.total_number_of_users}
          </TableCell>
          <TableCell
            size="small"
            onClick={props.pass.isStaff() ? onCustomerSelect(partnerCustomer.customer.tenant_id) : () => {}}
            className={classes.noWrap}>
            {partnerCustomer.customer.statistic.previous_thirty_days}
          </TableCell>
          <TableCell
            size="small"
            onClick={props.pass.isStaff() ? onCustomerSelect(partnerCustomer.customer.tenant_id) : () => {}}
            className={classes.noWrap}>
            {startCase(status)}
          </TableCell>
        </TableRow>
      );
    });
    return { totalLicenses: totalLicenses, totalLicensed: totalLicensed, totalActive: totalActive, partnerCustomerRows: partnerCustomerRows };
  };

  const getPartnerRows = (partnerPartners) => {
    let totalCustomers = 0;
    const partnerRows = partnerPartners.map((partner) => {
      if (partner.tenant_id !== props.route.match.params.id) {
        totalCustomers += partner.customers_amount || 0;
      }
      return (
        <TableRow hover key={partner.tenant_id}>
          <TableCell size="small" onClick={onPartnerSelect(partner.tenant_id)} className={`${classes.noWrap} ${classes.focusCell}`}>
            {partner.name}
          </TableCell>
          <TableCell size="small" onClick={onPartnerSelectDetails(partner.tenant_id)} className={classes.noWrap}>
            {partner.customers_amount || 0}
          </TableCell>
          <TableCell size="small" onClick={onPartnerSelect(partner.tenant_id)} className={classes.noWrap}>
            {startCase(partner.status)}
          </TableCell>
        </TableRow>
      );
    });
    return { totalCustomers, partnerRows };
  };

  if (error) {
    return <ErrorScreen errorMessage={errorMessage} />;
  }
  if (!context.selectedPartner) {
    return <LoadingScreen loadingMessage={loadingMessage} />;
  }
  const { partner } = context.selectedPartner;

  if (!partner || loader) {
    return <LoadingScreen loadingMessage={loadingMessage} />;
  }
  const pagination = (
    <TablePagination
      key="CustomerPagination"
      labelRowsPerPage={`${partner.type === 'distributor' ? 'Partners' : 'Customers'} per page:`}
      rowsPerPageOptions={[25, 50, 100]}
      count={(partner.type === 'distributor' ? totalPartnerPartners : totalPartnerCustomers) || 0}
      rowsPerPage={params.page_limit || 25}
      page={Number(params.page_nr) - 1 || 0}
      onChangePage={(event, page) => {
        query.set('page_nr', Number(page) + 1);
        window.scrollTo(0, 0);
      }}
      onChangeRowsPerPage={(event) => {
        query.set('page_limit', parseInt(event.target.value, 10));

        window.scrollTo(0, 0);
      }}
    />
  );
  let sortedPartnerPartners = partnerPartners
    .filter((partner) => !!partner.name)
    .sort((a, b) => {
      try {
        if (a.name[0].toLowerCase() < b.name[0].toLowerCase()) {
          return -1;
        }
        if (a.name[0].toLowerCase() > b.name[0].toLowerCase()) {
          return 1;
        }
        return 0;
      } catch (e) {
        console.error(e, a, b);
        return 0;
      }
    });

  const isDistributor = partner.type === 'distributor';
  let {
    totalLicenses = 0,
    totalLicensed = 0,
    totalActive = 0,
    partnerCustomerRows,
  } = getCustomerRows(isDistributor ? dialogPartner.customers : partnerCustomers);
  let { totalCustomers = 0, partnerRows } = getPartnerRows(sortedPartnerPartners || []);
  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing} justifyContent={'center'}>
        <Grid item md={12} lg={4}>
          <PartnerInfo
            key={window.location.pathname}
            partner={partner}
            distributors={partnerPartners}
            handleLoader={handleLoader}
            handleSnackbar={handleSnackbar}
            {...props.pass}
          />
        </Grid>

        <Grid item md={12} lg={8} /* md={8} */>
          {props.route.match.params.id !== props.pass.getPartnerTenantId() && (
            <Paper elevation={2} square className={classes.paper} style={{ marginBottom: 20, minHeight: 'auto' }}>
              <Tabs indicatorColor={'primary'} onChange={onTabChange} value={tabIndex} className={classes.tabs}>
                <Tab label={isDistributor ? 'Partners' : 'Customers'} className={classes.paperTab} />
                {!props.pass.isReadOnly() && <Tab label={isDistributor ? 'Distributor Users' : 'Reseller Users'} className={classes.paperTab} />}
              </Tabs>
            </Paper>
          )}
          {tabIndex === 0 &&
            (isDistributor ? (
              <Paper elevation={2} square className={classes.paper} style={{ padding: '0 20px' }}>
                <Toolbar style={{ padding: 0 }}>
                  <Grid container spacing={gridSpacing} align="flex-start">
                    <Grid item xs={5}>
                      <TextField
                        style={{ marginTop: 20, marginLeft: 'auto' }}
                        name={'name'}
                        size="small"
                        variant="filled"
                        fullWidth
                        label="Search"
                        value={params.search}
                        onChange={(e) => query.set('search', e.target.value)}
                        placeholder={'Type to filter...'}
                        startAdornment={
                          <InputAdornment position="start">
                            <Icon className="material-icons">search</Icon>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <Table>
                  <TableHead>
                    <TableRow>{pagination}</TableRow>
                    <TableRow>
                      <TableCell variant={'head'} style={{ width: '60%' }}>
                        {'Name'}
                      </TableCell>
                      <TableCell variant={'head'} style={{ position: 'relative' }}>
                        {'Number of Customers'}
                        <p className={classes.descriptor}>{'Total: ' + totalCustomers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
                      </TableCell>
                      <TableCell variant={'head'}>{'Status'}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{partnerRows}</TableBody>
                  <TableFooter>{pagination}</TableFooter>
                </Table>
              </Paper>
            ) : (
              <Paper elevation={2} square className={classes.paper} style={{ padding: '0 20px' }}>
                <Toolbar style={{ padding: 0 }}>
                  <Grid container spacing={gridSpacing} align="flex-start">
                    <Grid item xs={5}>
                      <TextField
                        style={{ marginTop: 20, marginLeft: 'auto' }}
                        name={'name'}
                        size="small"
                        variant="filled"
                        fullWidth
                        label="Search"
                        value={params.search}
                        onChange={(e) => query.set('search', e.target.value)}
                        placeholder={'Type to filter...'}
                        startAdornment={
                          <InputAdornment position="start">
                            <Icon className="material-icons">search</Icon>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <Table>
                  <TableHead>
                    <TableRow>{pagination}</TableRow>
                    <TableRow>
                      <TableCell variant={'head'}>{'Customer'}</TableCell>
                      <TableCell variant={'head'} style={{ position: 'relative' }}>
                        {'Licenses'}
                        <Tooltip
                          arrow
                          title={<p style={{ margin: 0, padding: 0, fontSize: '.75rem' }}>Exluding the licenses from {partner.name}</p>}
                          children={
                            <p className={classes.descriptor}>{'Total: ' + totalLicenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
                          }></Tooltip>
                      </TableCell>
                      <TableCell variant={'head'} style={{ position: 'relative' }}>
                        {'Users'}
                        <Tooltip
                          arrow
                          title={<p style={{ margin: 0, padding: 0, fontSize: '.75rem' }}>Exluding the Users from {partner.name}</p>}
                          children={
                            <p className={classes.descriptor}>{'Total: ' + totalLicensed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
                          }></Tooltip>
                      </TableCell>
                      <TableCell variant={'head'} style={{ position: 'relative' }}>
                        {'Active Users'}
                        <Tooltip
                          arrow
                          title={<p style={{ margin: 0, padding: 0, fontSize: '.75rem' }}>Exluding the active users from {partner.name}</p>}
                          children={
                            <p className={classes.descriptor}>{'Total: ' + totalActive.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
                          }></Tooltip>
                      </TableCell>
                      <TableCell variant={'head'}>{'Status'}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{partnerCustomerRows}</TableBody>
                  <TableFooter>{pagination}</TableFooter>
                </Table>
              </Paper>
            ))}

          {tabIndex === 1 && (
            <Users
              pass={props.pass}
              route={props.route}
              handleLoader={handleLoader}
              handleSnackbar={handleSnackbar}
              setError={setError}
              setErrorMessage={setErrorMessage}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} message={snackbarMessage} severity={snackbarSeverity} onClose={onSnackbarClose} />
      <br />
      <br />
      {isDistributor && (
        <Dialog open={dialogOpen} fullWidth maxWidth={'md'}>
          <DialogTitle id="max-width-dialog-title">{dialogPartner.name || dialogPartner.tenant_id}</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant={'head'}>{'Customer'}</TableCell>
                  <TableCell variant={'head'} style={{ position: 'relative' }}>
                    {'Licenses'}
                    <p className={classes.descriptor}>{'Total: ' + totalLicenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
                  </TableCell>
                  <TableCell variant={'head'} style={{ position: 'relative' }}>
                    {'Licensed'}
                    <p className={classes.descriptor}>{'Total: ' + totalLicensed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
                  </TableCell>
                  <TableCell variant={'head'} style={{ position: 'relative' }}>
                    {'Active Users'}
                    <p className={classes.descriptor}>{'Total: ' + totalActive.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
                  </TableCell>
                  <TableCell variant={'head'}>{'Status'}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{partnerCustomerRows}</TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={onPartnerSelectDetailsClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

Partner.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Partner);

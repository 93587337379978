import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from '@material-ui/core/styles';

import Root from './SalesUI/index.jsx';
import './index.scss';
import preval from 'preval.macro'; // Used for adding build time on compile

export const buildTime = preval`module.exports = new Date().toLocaleString();`;
console.info('Latest build ' + buildTime);

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f5f2f4',
    },
    primary: {
      main: '#fb1790',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        paddingBottom: 6,
        paddingTop: 6,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.9rem',
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 8,
        overflow: 'hidden',
      },
    },
    MuiTab: {
      root: {
        fontWeight: 'normal',
        color: '#000000',
        '&$selected': {
          fontWeight: 'bold',
        },
      },
    },
    MuiDialog: {
      paper: { width: '100%' },
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Root />
    </MuiThemeProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

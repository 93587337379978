import Auth from './Auth';

let api_host = 'https://' + window.location.hostname.replace(/portal\./, 'proxy.');
let files_api_host = process?.env?.REACT_APP_API_URL.replace("proxy", 'files');
let proxy_host = 'https://' + window.location.hostname.replace(/proxy\./, '');
const baseUrl = (api_host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : api_host) + '/api';
const baseUrlOld =
  (api_host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_OLD_URL}` : proxy_host) + '/api';
class PickitAPI {
  static getOldHeaders() {
    const authHeaders = new Headers();

    if (Auth.isAuthenticated()) {
      let user = Auth.getUser();
      authHeaders.append('Simpletoken-Auth', user.jwt || user.simple_token);
    }

    return authHeaders;
  }

  static async getCustomerStorageSize(props) {
    const headers = await PickitAPI.getHeaders(props.slug);
    const requestOptions = {
      method: 'GET',
      headers: headers,
    };
    try {
      return await fetch(
        files_api_host + `/community/storage/size?storage=${props.storage}`,
        requestOptions
      )
      .then(res =>  res.json())
      .then(data => {
        return `${(data.size / 1073741824).toFixed(2)} Gb`
      });
    } catch(err) {
      console.log(err + " in getCustomerStorageSize()")
      return 0;
    }
  }

  static async getCustomerInsights(props) {
    const headers = await PickitAPI.getHeaders();
    const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            community: props.community,
            period: props.period
          }),
    };
    try {
      const data = await fetch(files_api_host + "/internal/get_company_insight", requestOptions)
      .then(res =>  res.json())
      .then(data => {
        return data
      });
      return {
        dataRetrieved: true,
        data: data,
      };
    } catch(err) {
        console.log(err + " in getCustomerInsights()")
      return {
        dataRetrieved: false,
      }
    }
  }

  static async getHeadersOld(overrideSlug) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('cache-control', 'no-cache');
    headers.append('pickit-api-key', '23bc1999-ae93-4bd1-acc7-641dbf27e7b9');

    if (Auth.isAuthenticated()) {
      headers.append('Authorization', `JWT ${Auth.getUser().jwt}`);
    }
    headers.append(
      'pickit-community-slug',
      `${overrideSlug || (!Auth.isAuthenticated() || Auth.getProfile().is_staff ? '' : Auth.getProfile().community.slug)}`
    );
    return headers;
  }
  static async getHeaders(overrideSlug) {
    const headers = {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'pickit-api-key': '23bc1999-ae93-4bd1-acc7-641dbf27e7b9',
      'pickit-community-slug': `${overrideSlug || (!Auth.isAuthenticated() || Auth.getProfile().is_staff ? '' : Auth.getProfile().community.slug)}`,
    };
    if (Auth.isAuthenticated()) {
      headers.Authorization = `JWT ${Auth.getUser().jwt}`;
    }

    return headers;
  }
  static waitForToken() {
    return new Promise((resolve, reject) => {
      let checkExist = setInterval(() => {
        const { rotateTokenInProgress } = this;
        if (!rotateTokenInProgress) {
          resolve(undefined);
          clearInterval(checkExist);
        }
      }, 100);
    });
  }

  static async checkToken() {
    try {
      const headers = await PickitAPI.getHeaders();
      await fetch(baseUrl + '/profile', {
        method: 'GET',
        headers: headers,
      });
    } catch (e) {
      await this.rotateToken();
    }
  }

  static async rotateToken() {
    const { rotateTokenInProgress } = this;
    const djangoProfile = Auth.getProfile();
    const token = Auth.getUser();
    if (rotateTokenInProgress) {
      return this.waitForToken();
    }
    if (!token?.jwt) {
      return false;
    }
    try {
      this.rotateTokenInProgress = true;
      // TODO: Change this to "proxy" when prototype is ready.
      let data = await fetch(baseUrl + '/rotate-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-api-key': '23bc1999-ae93-4bd1-acc7-641dbf27e7b9',
        },
        body: JSON.stringify({
          refresh_token: token.jwt_refresh_token,
          rotate: 'jwt_refresh_token',
          user: {
            user_id: djangoProfile.id,
          },
        }),
      });
      if (!data.ok || data.status === 404) {
        throw data;
      }
      let newToken = await data.json();
      token.jwt = newToken.jwt;
      token.simple_token = newToken.simple_token;
      Auth.authenticate(token);

      this.rotateTokenInProgress = false;
      return token;
    } catch (e) {
      localStorage.removeItem('user');
      localStorage.removeItem('profile');
      localStorage.clear();
      window.location = '/';
      console.error('Unable to refresh token');
    }
  }

  static async getJWTFromSimpleToken(simpleToken) {
    if (Auth.getUser().jwt) return Auth.getUser().jwt;
    const [start, end] = baseUrl.split('//');
    const response = await this.request(start + '//' + end + '/pickit/connect', {
      headers: {
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
        'pickit-api-key': '23bc1999-ae93-4bd1-acc7-641dbf27e7b9',
      },
      method: 'POST',
      body: JSON.stringify({
        simple_token: simpleToken,
        mode: 'login',
        keep_me_logged_in: true,
      }),
    });
    const json = await response.json();
    return json.jwt;
  }

  static stringifyParams(params) {
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        delete params[key];
      }
    });
    if (typeof params !== 'object') {
      return '';
    }
    return Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
  }

  static async request(url, settings, hasRotated) {
    try {
      const req = await fetch(url, settings);
      if (!req.ok) {
        throw req;
      }
      return req;
    } catch (response) {
      if (!response.ok) {
        if (!hasRotated && (response.status === 401 || response.status === 403)) {
          const token = await this.rotateToken();
          settings.headers = this.getHeaders();
          if (token) settings.headers.Authorization = `jwt ${token.jwt}`;
          return this.request(url, settings, true);
        }
        throw response;
      }
    }
  }
  static getAITimeRange(statistic_range) {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    let startAt = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    startAt = new Date(startAt.getTime() - 40 * 24 * 60 * 60 * 1000);
    startAt = new Date(startAt.getFullYear(), startAt.getMonth(), 1);

    let middleAt = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    middleAt = new Date(middleAt.getTime() - 20 * 24 * 60 * 60 * 1000);
    middleAt = new Date(middleAt.getFullYear(), middleAt.getMonth(), 1);

    let finishAt = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    if (statistic_range === '7-days') {
      startAt = new Date(currentDate.getTime() - 15 * 24 * 60 * 60 * 1000);
      middleAt = new Date(currentDate.getTime() - 8 * 24 * 60 * 60 * 1000);
      finishAt = new Date(currentDate.getTime() - 1000);
    } else if (statistic_range === '30-days') {
      startAt = new Date(currentDate.getTime() - 61 * 24 * 60 * 60 * 1000);
      middleAt = new Date(currentDate.getTime() - 31 * 24 * 60 * 60 * 1000);
      finishAt = new Date(currentDate.getTime() - 1000);
    }
    return { startAt, middleAt, finishAt };
  }

  static get cache() {
    /*
     * Cache layer
     * */
    let currentDate = new Date().getTime();

    return {
      get: function (cache_key) {
        if (!localStorage.getItem(cache_key)) return null;

        let cache_value = localStorage.getItem(cache_key).split('::'),
          expire = parseInt(cache_value[1], 10);

        if (expire < currentDate) {
          localStorage.removeItem(cache_key);

          return null;
        }

        cache_value = cache_value[0];
        if (cache_value !== 'undefined') {
          return JSON.parse(cache_value);
        }
      },
      set: function (cache_key, cache_value, cache_ttl = 60 * 60 * 1000) {
        cache_value = JSON.stringify(cache_value) + '::' + (currentDate + cache_ttl).toString();
        localStorage.setItem(cache_key, cache_value);
      },
    };
  }

  static get helpers() {
    let _self = this;

    return {
      getCountries: async function () {
        let countries = await _self.request(baseUrlOld + '/countries/', {});
        try {
          return countries.json();
        } catch (e) {
          console.error(e);
        }
      },
      getPackages: async function () {
        let packages = await _self.request(baseUrlOld + '/sales/products/', {
          method: 'GET',
          headers: _self.getOldHeaders(),
        });
        try {
          return packages.json();
        } catch (e) {
          console.error(e);
        }
      },
      getUserPartner: async function (slug, tenant_id, jwt, is_test) {
        let url = baseUrl + `/portal/partners?partner=${tenant_id}` + (is_test ? '&show_test=1' : '');
        let headers = await _self.getHeaders(slug);

        headers.authorization = `JWT ${jwt}`;
        let packages = await _self.request(url, {
          method: 'GET',
          headers: headers,
        });
        try {
          return packages.json();
        } catch (e) {
          console.error(e);
        }
      },
    };
  }

  static profile() {
    let _self = this;
    return new Promise((resolve, reject) => {
      _self.getHeaders().then((headers) => {
        _self
          .request(baseUrl + '/profile/', {
            method: 'GET',
            headers: headers,
          })
          .then(function (data) {
            resolve(data.json());
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  static stats(year, month, day) {
    var _self = this;
    if (month) {
      month = '/' + month;
    } else {
      month = '';
    }
    if (day) {
      day = '/' + day;
    } else {
      day = '';
    }

    return new Promise((resolve, reject) => {
      _self.getHeaders().then((headers) => {
        _self
          .request(baseUrl + '/sales/stats/' + year + month + day, {
            method: 'GET',
            headers: headers,
          })
          .then(function (data) {
            resolve(data.json());
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  static get user() {
    var _self = this;
    return {
      getById: function (id) {
        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            _self
              .request(baseUrl + '/users/' + id, {
                method: 'GET',
                headers: headers,
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
      getAll: function (slug, page_nr, page_limit, role, search, sort_order, sort_field, signal) {
        if (signal?.aborted){
          return Promise.reject(new DOMException("Aborted", "AbortError"));
        }

        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            _self
              .request(baseUrl + `/community/users?page_nr=${page_nr+1}&page_limit=${page_limit}&sort_order=${sort_order}&sort_field=${sort_field}&role=${role}&search=${encodeURIComponent(search)}`, {
                method: 'GET',
                headers: {
                  ...headers,
                  'pickit-community-slug': slug
                },
                signal
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
      setPassword: function(slug, user_id, password) {
        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            _self
              .request(baseUrl + `/community/users`, {
                method: 'PUT',
                headers: {
                  ...headers,
                  'pickit-community-slug': slug
                },
                body: JSON.stringify({
                  user_id,
                  password,
                  action: 'override_set_password'
                })
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
      sendOutInviteEmail: function(slug, user_id) {
        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            _self
              .request(baseUrl + `/community/users`, {
                method: 'PUT',
                headers: {
                  ...headers,
                  'pickit-community-slug': slug
                },
                body: JSON.stringify({
                  user_id,
                  action: 'send_out_invite_email'
                })
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      }
    };
  }

  static get account() {
    let _self = this;
    return {
      getToken: function (username, password) {
        let form_data = new FormData();
        form_data.append('username', username);
        form_data.append('password', password);
        form_data.append('keep_me_logged_in', 'true');

        return new Promise((resolve, reject) => {
          _self
            .request(baseUrl + '/token/create/', {
              method: 'POST',
              headers: _self.getOldHeaders(),
              body: form_data,
            })
            .then(function (data) {
              resolve(data.json());
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      login: function (username, password) {
        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            let body = {
              user: {
                username: username,
                password: password,
              },
              mode: 'login',
              keep_me_logged_in: true,
            };
            _self
              .request(baseUrl + '/pickit/connect', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body),
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
      MSlogin: function (data) {
        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            let body = {
              ...data,
              mode: 'login',
              keep_me_logged_in: true,
            };
            _self
              .request(baseUrl + '/microsoft/connect', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body),
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
      restorePassword: function (username) {
        let form_data = new FormData();
        form_data.append('username_email', username);

        return new Promise((resolve, reject) => {
          _self
            .request(baseUrl + '/forgot-password/', {
              method: 'POST',
              headers: _self.getOldHeaders(),
              body: form_data,
            })
            .then(function (data) {
              resolve(data.json());
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getInfo: function () {
        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            _self
              .request(baseUrl + '/account/', {
                method: 'GET',
                headers: headers,
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
      getAll: function () {
        return new Promise((resolve, reject) => {
          _self.getHeaders().then((headers) => {
            _self
              .request(baseUrl + '/communities/', {
                headers: headers,
              })
              .then(function (data) {
                resolve(data.json());
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
    };
  }

  static get community() {
    let _self = this;
    return {
      putMaxNumberOfUsers: async function (id, form_data) {
        let response = await _self.request(baseUrl + '/community/' + id + '/', {
          method: 'PUT',
          headers: _self.getOldHeaders(),
          body: form_data,
        });

        try {
          return response.json();
        } catch (e) {
          console.error(e);
        }
      },

      getById: async function (id) {
        let data = await _self.request(baseUrl + '/community/' + id, {});
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
      getCustomerInfo: async function (id) {
        /*
         * Awaiting response
         * [
         *   {
         *     "name": '',
         *     "type": '',
         *     "status": '',
         *     "email": '',
         *     "url": '',
         *     "parent": {
         *       "name": ''
         *     }
         *   },
         *   ...
         * ]
         * */
        let headers = await _self.getHeaders();
        var data = await _self.request(baseUrl + '/sales/customer/?community_slug=' + id + '&format=json', {
          method: 'GET',
          headers: headers,
        });
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
      saveCustomerInfo: async function (settings) {
        var json = JSON.stringify(settings.data);
        let headers = await _self.getHeaders();
        var data = await _self.request(baseUrl + '/sales/customer/', {
          method: 'POST',
          headers: headers,
          body: json,
        });
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
      getCustomerInvoice: async function (id) {
        let headers = await _self.getHeaders();
        var data = await _self.request(baseUrl + '/sales/invoice/?community_slug=' + id + '&format=json', {
          method: 'GET',
          headers: headers,
        });
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
      saveCustomerInvoice: async function (settings) {
        settings.data.community_slug = settings.id;
        var json = JSON.stringify(settings.data);
        let headers = await _self.getHeaders();
        var data = await _self.request(baseUrl + '/sales/invoice/', {
          method: 'POST',
          headers: headers,
          body: json,
        });
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
      getPackages: async function () {
        var data = await _self.request(baseUrlOld + '/sales/products/', {
          method: 'GET',
          headers: _self.getOldHeaders(),
        });
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
      getCurrentPackage: async function (id) {
        let headers = await _self.getHeaders();
        var data = await _self.request(baseUrl + '/sales/package/?community_slug=' + id + '&format=json', {
          method: 'GET',
          headers: headers,
        });
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
      saveProducts: async function (settings) {
        settings.data.community_slug = settings.id;
        var json = JSON.stringify(settings.data);
        let headers = await _self.getHeaders();
        var data = await _self.request(baseUrl + '/sales/package/', {
          method: 'POST',
          headers: headers,
          body: json,
        });
        try {
          return data.json();
        } catch (e) {
          console.error(e);
        }
      },
    };
  }
}

export default PickitAPI;

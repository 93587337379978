import { Button } from '@material-ui/core';
import React from 'react';
import microsoft from 'images/ms.svg';
import Dialog from 'Services/Dialog';
import { inTeams } from 'Services/platform';

export default function SSO(props) {
  const [microsoftError, setMicrosoftError] = React.useState('');

  const [isConnecting, setIsConnecting] = React.useState(false);

  function ssoTimer(timer) {
    const newTime = timer ? timer + 10000 : 10000;
    console.warn(`Tracked sso timeout:${newTime}`);
  }

  function connectWithMicrosoft(select_account = false) {
    setInterval(ssoTimer, 10000);
    setIsConnecting('sso');
    const teams = inTeams();
    const dialog = new Dialog(
      `${window.location.origin}/Auth.html?cache=${Math.random()}${select_account ? '&select_account' : ''}${teams ? '&teams' : ''}`,
      1000,
      700
    );
    dialog.open(
      async (result) => {
        clearInterval(ssoTimer);
        if (result && typeof result === 'string') {
          try {
            await props.onLogin({
              ms_access_token: result,
              keep_me_logged_in: true,
              mode: 'login',
            });

            setIsConnecting(false);
          } catch (e) {
            console.error(e);
            setIsConnecting(false);
            setMicrosoftError(
              `E002: Something went wrong. We have been notified, please try again later. If the error persists, please contact support.`
            );
          }
        } else {
          console.error(result);
          setIsConnecting(false);
          setMicrosoftError(
            `E003: Something went wrong. We have been notified, please try again later. If the error persists, please contact support.`
          );
        }
      },
      (error) => {
        clearInterval(ssoTimer);

        console.error(error);
        setIsConnecting(false);
        if (error) {
          setMicrosoftError(
            `E001: Something went wrong. We have been notified, please try again later. If the error persists, please contact support.`
          );
        }
      }
    );
  }

  return (
    <>
      <Button
        disabled={isConnecting === 'sso'}
        loading={isConnecting === 'sso'}
        fullWidth
        style={{ background: '#FFF' }}
        onClick={() => connectWithMicrosoft()}>
        <img src={microsoft} alt="Microsoft" style={{ width: '70%', marginTop: 2, minHeight: 18, opacity: isConnecting === 'sso' ? 0.2 : 1 }} />
      </Button>
      {microsoftError && <p style={{ color: 'red' }}>{microsoftError}</p>}
    </>
  );
}

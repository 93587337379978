import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Divider,
  Toolbar,
  Typography,
  IconButton,
  Icon,
  Drawer,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Grid,
  Switch as UISwitch,
} from '@material-ui/core';

import { BrowserRouter as Router, Route, Switch, Redirect, Link, useLocation } from 'react-router-dom';

import PickitAPI from 'PickitAPI/';
import Auth from 'PickitAPI/Auth';

import { PortalDataContext, PortalDataProvider, CustomerManagementProvider } from 'Contexts';
import { Customers, Partners, Customer, Partner, CustomerNew, PartnerNew, Login, Presets } from 'Views';
import { LoadingScreen, ErrorScreen } from 'Components';

import Logo from 'images/logotype_full-color.svg';
import LogoFooter from 'images/logotype_filled_black.svg';
import useRouterQuery from 'hooks/useRouterQuery';
import useTokenManagement from 'hooks/useTokenManagement';

const drawerWidth = 200;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 10,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    color: '#666666',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    backgroundColor: theme.palette.secondary,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px 0 0 ',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
    overflow: 'auto',
  },
  menuIcon: {
    marginLeft: 10,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
  },
  rootGrid: {
    zIndex: 10,
  },
  menuText: {
    fontSize: '0.9rem',
  },
  testBanner: {
    position: 'absolute',
    bottom: -4,
    background: '#F1B301',
    width: '100vw',
    height: 4,
    zIndex: 30,
    '&>div': {
      background: '#F1B301',
      margin: 'auto',
      width: 'fit-content',
      padding: '1px 5px',
      borderRadius: 4,
      color: '#fff',
      fontSize: 13,
    },
  },
});
const TestSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#F1B301',
    },
    '&$checked + $track': {
      backgroundColor: '#F1B301',
    },
  },
  checked: {},
  track: {},
})(UISwitch);

function LocationHandler(props) {
  const { setActivePath, setChangeTestMode, changeTestMode } = props;
  const query = useRouterQuery();
  const { params } = query;
  const { test } = params;

  let location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname || '');
  }, [setActivePath, location]);

  useEffect(() => {
    if (changeTestMode) {
      let newMode = !test;
      setChangeTestMode(false);

      newMode ? query.set('test', true) : query.delete('test');

      window.localStorage.setItem('testmode', newMode);
    }
  }, [changeTestMode]);

  return props.children;
}

const version = '4.0.0';

function SalesUI(props) {
  const { classes } = props;
  const [changeTestMode, setChangeTestMode] = useState(false);
  const [title, setTitle] = useState('');
  const [counter, setCounter] = useState(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [userCommunitySlug, setUserCommunitySlug] = useState(undefined);
  const [partner, setPartner] = useState({
    isReseller: false,
    isDistributor: false,
    tenant_id: '',
    data: {},
  });
  const [redirect, setRedirect] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  const [activePath, setActivePath] = useState(window.location.pathname);

  const { testToken, tokenChecked } = useTokenManagement();

  useEffect(() => {
    init();
  }, []);
  const init = async () => {
    if (window.localStorage.getItem('latest_version') !== version && Auth.isAuthenticated()) {
      Object.keys(window.localStorage).forEach((key) => {
        if (key.indexOf('sales_ui') !== -1) {
          window.localStorage.removeItem(key);
        }
      });
      window.localStorage.setItem('latest_version', version);
      Auth.deauthenticate();
      return;
    }
    let profile = Auth.getProfile();
    let isAuthenticated = Auth.isAuthenticated();
    const user = Auth.getUser();
    if (!user) return;
    if (!tokenChecked) await testToken();

    if (isAuthenticated && profile) {
      setIsAuthenticated(true);

      if (profile) {
        setAuthenticated(true, profile);
      } else {
        PickitAPI.profile().then((profile) => {
          let slug = getCommunitySlug(profile);
          if (!profile.is_staff && slug) {
            PickitAPI.helpers
              .getUserPartner(slug)
              .then((partner) => {
                profile.partner = partner.data;

                setAuthenticated(true, profile);
              })
              .catch((error) => {
                setIsAuthenticated(false);
              });
          }
        });
      }
    }
  };
  const setAuthenticated = async (isAuthenticated, profile, is_test = false) => {
    if (isAuthenticated && profile) {
      window.localStorage.setItem('latest_version', version);
      Auth.saveProfile(profile);
      if (!tokenChecked) await testToken();

      if (profile.is_staff) {
        setPartner({
          isReseller: false,
          isDistributor: false,
          tenant_id: undefined,
          data: {},
        });
        setIsAuthenticated(true);
        setIsStaff(true);
        if (profile.is_superuser) {
          setIsSuperUser(true);
        }
        setIsReadOnly(false);
      } else if (profile.partner.tenant_id) {
        setChangeTestMode(is_test && !window.location.href.includes('test=true'));
        let slug = getCommunitySlug(profile);
        setChangeTestMode(profile.partner.is_test && !window.location.href.includes('test=true'));
        setUserCommunitySlug(slug);
        setPartner({
          isReseller: profile.partner.type !== 'distributor',
          isDistributor: profile.partner.type === 'distributor',
          tenant_id: profile.partner.tenant_id,
          data: profile.partner,
        });
        setIsAuthenticated(true);
        setIsStaff(false);
        setIsReadOnly(profile.community.groups.indexOf(slug + '_portalwrite') === -1);
      } else {
        Auth.deauthenticate();
      }
    } else {
      Auth.deauthenticate();
    }
  };
  const setCommunitySlug = (profile) => {
    if (!profile) {
      return false;
    }
    if (profile.community.slug) {
      setUserCommunitySlug(profile.community.slug);

      return profile.community.slug;
    }

    let readGroup = '';

    for (let i = 0; i < profile.community.groups.length; i++) {
      if (profile.community.groups[i].indexOf('_portalread') !== -1) {
        readGroup = profile.community.groups[i];
      }
    }
    if (readGroup === '') {
      return false;
    }
    let slug = '';
    let parts = readGroup.split('_');
    slug += parts[0];
    parts.pop();
    parts.shift();
    if (parts) {
      parts.forEach((part) => {
        slug += `_${part}`;
      });
    }
    setUserCommunitySlug(slug);

    return slug;
  };
  const getCommunitySlug = (profile) => {
    if (userCommunitySlug) {
      return userCommunitySlug;
    }

    return setCommunitySlug(profile);
  };

  const logoutUser = () => {
    setAuthenticated(false);
  };

  const ToggleTest = () => {
    let href = window.location.href;
    setChangeTestMode(true);
    if (href.indexOf('/new') === -1) {
      doRedirect(href.indexOf('/customer') !== -1 ? '/customers' : '/partners');
    }
  };
  const doRedirect = (to) => {
    setRedirect(true);
    setRedirectTo(to);
  };

  const passToRouter = {
    setTitle: (title_) => {
      if (title !== title_) {
        setTitle(title);
      }
    },
    setCounter: (counter_) => {
      if (counter !== counter_) {
        setCounter(counter);
      }
    },
    isReseller: () => {
      return partner.isReseller || isStaff;
    },
    isDistributor: () => {
      return partner.isDistributor || isStaff;
    },
    isStaff: () => {
      return isStaff;
    },
    isSuperUser: () => {
      return isStaff && isSuperUser;
    },
    isReadOnly: () => {
      return isReadOnly;
    },
    isAuthenticated: () => {
      return isAuthenticated;
    },
    getPartnerTenantId: () => {
      return partner.tenant_id;
    },
    getPartnerName: () => {
      return partner.data.name;
    },
    getPartnerData: () => {
      return partner.data;
    },
    logout: () => {
      logoutUser();
    },
    getUserCommunitySlug: () => {
      return userCommunitySlug;
    },
    redirect: (to) => {
      doRedirect(to);
    },
    testMode: () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('test');
    },
  };

  /* Since we need to pass parameters to the component we create a object containing all the views */
  const Views = {
    Customers: (history) => {
      return <Customers pass={passToRouter} route={history} />;
    },
    Customer: (history) => {
      return <Customer pass={passToRouter} route={history} partner={partner} />;
    },
    CustomerNew: (history) => {
      return <CustomerNew pass={passToRouter} route={history} />;
    },

    Partners: (history) => {
      return <Partners pass={passToRouter} route={history} />;
    },
    Partner: (history) => {
      return <Partner pass={passToRouter} route={history} />;
    },
    PartnerNew: (history) => {
      return <PartnerNew pass={passToRouter} route={history} />;
    },
    Presets: (history) => {
      return <Presets pass={passToRouter} route={history} />;
    },
    ErrorScreen: (history) => {
      return <ErrorScreen pass={passToRouter} route={history} errorMessage={{ statusText: 'PAGE NOT FOUND', status: 404 }} />;
    },
  };
  const ROUTES = {
    PARTNERS: <Route exact path="/partners/:test?" key="partners" render={Views.Partners} />,
    PARTNER: (
      <Route exact path="/partner/:id/:test?" key="partner/:id" render={(props) => <Views.Partner key={window.location.pathname} {...props} />} />
    ),
    PARTNERS_NEW: <Route exact path="/partners/new/:test?" key="partners/new" render={Views.PartnerNew} />,
    CUSTOMERS: <Route exact path="/customers/:test?" key="customers" render={Views.Customers} />,
    CUSTOMER: <Route exact path="/customer/:id/:test?" key="customer/:id" render={Views.Customer} />,
    CUSTOMERS_NEW: <Route exact path="/customers/new/:test?" key="customers/new" render={Views.CustomerNew} />,
    PRESETS: <Route exact path="/presets/:test?" key="presets" render={Views.Presets} />,
  };

  let restrictedRoutes = [];

  if (isStaff) {
    restrictedRoutes = [ROUTES.PARTNERS_NEW, ROUTES.PARTNER, ROUTES.PARTNERS, ROUTES.CUSTOMERS_NEW, ROUTES.CUSTOMER, ROUTES.CUSTOMERS];
    if (isSuperUser) {
      restrictedRoutes.push(ROUTES.PRESETS);
    }
  } else if (partner.isReseller) {
    restrictedRoutes = [ROUTES.CUSTOMERS_NEW, ROUTES.PARTNER, ROUTES.CUSTOMER, ROUTES.CUSTOMERS];
  } else if (partner.isDistributor) {
    restrictedRoutes = [ROUTES.PARTNERS_NEW, ROUTES.CUSTOMER, ROUTES.PARTNER, ROUTES.PARTNERS];
  }

  if (!Auth.isAuthenticated()) {
    return <Login setAuthenticated={setAuthenticated} />;
  }

  let backStyle = { marginRight: 25 };

  if (window.location.pathname.length === 1) {
    backStyle.visibility = 'hidden';
  }
  if (redirect && window.location.href.indexOf(redirectTo) !== -1) {
    setRedirect(false);
  }
  const isTesting = window.location.search.indexOf('test=true') !== -1;
  if (!passToRouter || !tokenChecked) return <></>;
  return (
    <Router basename="/">
      <PortalDataProvider pass={passToRouter}>
        <PortalDataContext.Consumer>
          {(context) => {
            if (redirect && (window.location.href.indexOf(redirectTo) === -1 || window.location.href.indexOf(redirectTo + '/') !== -1)) {
              setTimeout(() => {
                setRedirect(false);
              }, 100);
              return <Redirect to={redirectTo} />;
            }
            return (
              <>
                <CustomerManagementProvider>
                  <LocationHandler
                    setActivePath={setActivePath}
                    setChangeTestMode={setChangeTestMode}
                    changeTestMode={changeTestMode}
                    pass={passToRouter}>
                    <div className={classes.root}>
                      <AppBar position={'absolute'} elevation={1} className={classNames(classes.appBar)} color={'secondary'}>
                        <Toolbar disableGutters={false} style={{ padding: '0 24px 0 0', position: 'relative' }}>
                          {isTesting && passToRouter.isStaff() && (
                            <div className={classes.testBanner}>
                              <div>Viewing test data</div>
                            </div>
                          )}
                          <div style={{ width: drawerWidth, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 24 }}>
                          <MenuItem button component={Link} to={'/customers' + (isTesting ? '?test=true' : '')} className={classes.MenuItem} style={{ backgroundColor: 'transparent' }}>
                            <img src={Logo} style={{ height: 40, cursor: 'pointer' }} alt={'Pickit'}/>
                          </MenuItem>
                          </div>
                          <Typography variant={'h6'} color={'inherit'} className={classes.title}>
                            {title + (counter ? ' (Total: ' + counter + ')' : '')}
                          </Typography>

                          <IconButton color={'inherit'} onClick={logoutUser}>
                            <Icon className="material-icons">exit_to_app</Icon>
                          </IconButton>
                        </Toolbar>
                      </AppBar>

                      <Drawer
                        variant={'permanent'}
                        classes={{
                          paper: classNames(classes.drawerPaper),
                        }}
                        color={'secondary'}
                        open
                        elevation={0}>
                        <div className={classes.toolbar}>
                          {/* <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton> */}
                        </div>

                        <MenuList style={{ padding: '22px 0 8px' }}>
                          {(passToRouter.isDistributor() || passToRouter.isReseller()) && !passToRouter.isStaff() && !passToRouter.isReadOnly() && (
                            <MenuItem
                              button
                              component={Link}
                              to={'/partner/' + passToRouter.getPartnerTenantId() + (isTesting ? '?test=true' : '')}
                              className={classes.MenuItem}>
                              <ListItemIcon className={classes.menuIcon}>
                                <Icon
                                  className="material-icons"
                                  style={
                                    activePath === '/partner/' + passToRouter.getPartnerTenantId() ? { color: '#fb1790' } : { color: 'inherit' }
                                  }>
                                  home
                                </Icon>
                              </ListItemIcon>
                              <ListItemText className={classes.menuText} style={{ fontSize: '0.9rem' }}>
                                <span style={{ fontSize: '0.9rem' }}>Home</span>
                              </ListItemText>
                            </MenuItem>
                          )}

                          {passToRouter.isReseller() && (
                            <MenuItem button component={Link} to={'/customers' + (isTesting ? '?test=true' : '')} className={classes.MenuItem}>
                              <ListItemIcon className={classes.menuIcon}>
                                <Icon
                                  style={activePath.indexOf('/customer') !== -1 ? { color: '#fb1790' } : { color: 'inherit' }}
                                  className="material-icons">
                                  assignment_ind
                                </Icon>
                              </ListItemIcon>
                              <ListItemText className={classes.menuText} style={{ fontSize: '0.9rem' }}>
                                <span style={{ fontSize: '0.9rem' }}>Customers</span>
                              </ListItemText>
                            </MenuItem>
                          )}
                          {passToRouter.isDistributor() && (
                            <MenuItem button component={Link} to={'/partners' + (isTesting ? '?test=true' : '')} className={classes.MenuItem}>
                              <ListItemIcon className={classes.menuIcon}>
                                <Icon
                                  className="material-icons"
                                  style={
                                    activePath.indexOf('/partner') !== -1 && activePath !== '/partner/' + passToRouter.getPartnerTenantId()
                                      ? { color: '#fb1790' }
                                      : { color: 'inherit' }
                                  }>
                                  people
                                </Icon>
                              </ListItemIcon>
                              <ListItemText className={classes.menuText} style={{ fontSize: '0.9rem' }}>
                                <span style={{ fontSize: '0.9rem' }}>Partners</span>
                              </ListItemText>
                            </MenuItem>
                          )}
                          {passToRouter.isSuperUser() && (
                            <MenuItem button component={Link} to={'/presets' + (isTesting ? '?test=true' : '')} className={classes.MenuItem}>
                              <ListItemIcon className={classes.menuIcon}>
                                <Icon
                                  className="material-icons"
                                  style={activePath.indexOf('/presets') !== -1 ? { color: '#fb1790' } : { color: 'inherit' }}>
                                  offline_pin
                                </Icon>
                              </ListItemIcon>
                              <ListItemText className={classes.menuText} style={{ fontSize: '0.9rem' }}>
                                <span style={{ fontSize: '0.9rem' }}>Policy Presets</span>
                              </ListItemText>
                            </MenuItem>
                          )}
                        </MenuList>
                        {isStaff && (
                          <>
                            <Divider />
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, marginTop: 16 }}>
                              <div>
                                <TestSwitch onChange={ToggleTest} value={isTesting} checked={isTesting} />
                              </div>
                              {isTesting ? (
                                <Typography style={{ fontSize: '0.8rem' }}>Viewing test data</Typography>
                              ) : (
                                <Typography style={{ fontSize: '0.8rem' }}>View test data</Typography>
                              )}
                            </div>
                          </>
                        )}
                        <p style={{ fontSize: 13, opacity: 0.5, top: '97vh', left: 2, position: 'fixed'}}>V {version}</p>
                      </Drawer>

                      <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <div className="app">
                          <div className="view">
                            <Grid container spacing={0} justifyContent={'center'}>
                              <Grid item xs={11} className={classes.rootGrid}>
                                {!isStaff && !partner.isDistributor && !partner.isReseller ? (
                                  <LoadingScreen loadingMessage={'Loading...'} />
                                ) : (
                                  <Switch>
                                    {restrictedRoutes}
                                    <Redirect
                                      from="/"
                                      to={
                                        (!passToRouter.isStaff() && !passToRouter.isReseller() ? '/partners' : '/customers') +
                                        (isTesting ? '?test=true' : '')
                                      }
                                    />
                                    <Route component={Views.ErrorScreen} />

                                    <Redirect
                                      to={
                                        passToRouter.isDistributor() &&
                                        (!passToRouter.isStaff() ? '/partners' : '/customers') + (isTesting ? '?test=true' : '')
                                      }
                                    />
                                  </Switch>
                                )}
                              </Grid>
                            </Grid>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <img src={LogoFooter} style={{ margin: '0 auto 50px', height: '52px' }} alt={'Logo'} />
                            </div>
                          </div>
                        </div>
                      </main>
                    </div>
                  </LocationHandler>
                </CustomerManagementProvider>
              </>
            );
          }}
        </PortalDataContext.Consumer>
      </PortalDataProvider>
    </Router>
  );
}

SalesUI.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SalesUI);

import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PortalDataContext } from 'Contexts/PortalDataContext';

import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  TextField,
  Icon,
  InputLabel,
  Tooltip,
  useTheme,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

import PickitAPI from 'PickitAPI';
import clone from 'clone';
import LoadingScreen from 'Components/LoadingScreen';
import SalesUICustomer from 'PickitAPI/Customer';
import NativeSelect from '@material-ui/core/NativeSelect/NativeSelect';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { set } from 'lodash';
import slugify from 'slugify';
import Utility from 'Services/utils.js';
import { Services, Snackbar } from 'Components';
import usePresets from 'hooks/usePresets';

const gridSpacing = 5;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
    paddingBottom: 50,
  },
  paper: {
    minHeight: '60vh',
  },
  title: {
    paddingTop: 20,
  },
  grid: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30,
  },
  toolbarHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    paddingLeft: 15,
  },
  saveButton: {
    padding: '5px 40px',
    minHeight: 24,
    minWidth: 64,
    margin: '20px auto 20px auto',
    boxShadow: 'none',
    alignItems: 'center',
  },
  cancelButton: {
    display: 'inline-block',
    backgroundColor: '#ffffff !important',
    color: theme.palette.primary.main,
    boxShadow: 'none',
  },
  textValidator: {
    '& > label': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.4)',
    },
    '& > div': {
      marginTop: 20,
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  textValidatorWithDomain: {
    '& > label': {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.4)',
    },
    '& > div': {
      marginTop: 20,
      position: 'relative',

      '& > input': {
        textAlign: 'right',
        paddingRight: 80,
      },
    },
  },
  formControl: {
    minWidth: 100,
  },
  formControlWithDomain: {
    position: 'relative',

    '& > span': {
      position: 'absolute',
      right: 0,
      top: 25,
      fontWeight: 'bold',
    },
  },
  removeUnderline: {
    '& > div::after': {
      border: 'none !important',
    },
    '& > div::before': {
      border: 'none !important  ',
    },
    '&::after': {
      border: 'none !important',
    },
    '&::before': {
      border: 'none !important  ',
    },
  },
});

function CustomerNew(props) {
  const { classes } = props;
  const form = useRef('form');
  const theme = useTheme();

  let testMode = props.pass.isStaff ? !!props.pass.testMode() : false;
  const context = useContext(PortalDataContext);
  const { data, getPresets, checkPresetExistance } = usePresets();
  const [policy, setPolicy] = useState(undefined);
  const [policyOverride, setPolicyOverride] = useState({
    access: {
      features: {},
      integrations: {},
      services: {},
      metadata: {},
    },
  });

  const [request, setRequest] = useState({
    customer: {
      name: '',
      address: '',
      postcode: '',
      city: '',
      country: 'SE',
      tenant_id: '',
      o365_admin_email: '',
      domain: '',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      seats: 0,
      is_test: testMode,
      status: 'paying',
      license_display_name: 'enterprise',
    },
    community_url: '',
    product: {
      seats: 50,
      slug: 'pickit-one-us_monthly',
    },
    policy: {},
    policy_preset_id: checkPresetExistance(props?.pass?.getPartnerData?.()?.customer_preset_id) ?? 15,
    partner: props.pass.isStaff() ? '' : props.pass.getPartnerTenantId(),
    trial_days: 0,
  });
  const [countries, setCountries] = useState(Utility.getCountries());
  const [creating, setCreating] = useState(false);
  const loadingMessage = 'Preparing data...';
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  useEffect(() => {
    // Null counter
    props.pass.setCounter(undefined);
    props.pass.setTitle('Create New Customer');

    if (PickitAPI.cache.get('sales_ui_countries')) {
      setCountries(PickitAPI.cache.get('sales_ui_countries'));
    } else {
      PickitAPI.helpers.getCountries().then((countries) => {
        setCountries(countries);

        PickitAPI.cache.set('sales_ui_countries', countries);
      });
    }

    // Add form validators
    ValidatorForm.addValidationRule('isUUIDMatch', (value) => {
      return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value) || !value.length;
    });

    ValidatorForm.addValidationRule('isSlugMatch', (value) => {
      return value !== undefined && value.length && /^[0-9a-z-]+$/i.test(value.toLowerCase());
    });

    ValidatorForm.addValidationRule('isSlugCharsMatch', (value) => {
      return !value || (value.length && /^[0-9a-z-]+$/i.test(value.toLowerCase()));
    });

    ValidatorForm.addValidationRule('isDigit', (value) => {
      value = value + '';
      return value !== undefined && value.length && /^[0-9]+$/i.test(value);
    });
    ValidatorForm.addValidationRule('largerThanOne', (value) => {
      return value !== undefined && value > 0;
    });
    ValidatorForm.addValidationRule('lessThanHundredThousand', (value) => {
      return value !== undefined && value < 100000;
    });
    ValidatorForm.addValidationRule('maxSlugLength', (value) => {
      return !value || (value + '').length <= 30;
    });

    let request_ = clone(request);

    if (PickitAPI.cache.get('latest_customer_partner') && props.pass.isStaff()) {
      request_.partner = PickitAPI.cache.get('latest_customer_partner');
    }
    if (props.pass.isReseller()) {
      request_.partner = props.pass.getPartnerTenantId();
    }
    if (PickitAPI.cache.get('latest_customer_country')) {
      request_.customer.country = PickitAPI.cache.get('latest_customer_country');
    }
    if (PickitAPI.cache.get('latest_customer_status')) {
      request_.customer.status = PickitAPI.cache.get('latest_customer_status');
      if (request_.customer.status === 'trialactive') {
        request_.trial_days = 30;
      }
    }
    if (request_.partner) {
      const partner = context?.partnerList?.find((partner) => request_.partner === partner.tenant_id) ?? props?.pass?.getPartnerData();
      if (checkPresetExistance(partner?.customer_preset_id)) {
        request_.policy_preset_id = partner?.customer_preset_id;
      } else if (request_.customer.status === 'trialactive') {
        request_.customer.license_display_name = 'enterprise';
      }
    }
    setRequest(request_);
    if (props.pass.isStaff()) getPresets();
  }, []);

  const onFieldUpdate = (event) => {
    let request_ = clone(request);
    let { name, value } = event.target;
    if (name === 'product.seats') {
      value = Number(value);
    }
    set(request_, name, value);

    if (name === 'customer.name') {
      let slug = slugify(value);
      slug = slug
        .toLowerCase()
        .replace(/[^0-9a-zA-Z]+/g, '')
        .slice(0, 30);
      set(request_, 'community_url', slug);
    }
    if (props.pass.isStaff()) {
      PickitAPI.cache.set('latest_customer_partner', request_.partner);
    }

    if (name === 'customer.status') {
      request_.trial_days = value === 'trialactive' ? 7 : 0;
      const partner = context?.partnerList?.find((partner) => request_.partner === partner.tenant_id) ?? props?.pass?.getPartnerData();

      if (!checkPresetExistance(partner?.customer_preset_id) && value === 'trialactive') {
        request_.policy_preset_id = 15;
      }
      request_.customer.license_display_name = request_.policy_preset_id === 15 ? 'enterprise' : 'business';
    }

    if (name === 'partner') {
      const partner = context.partnerList.find((partner) => request_.partner === partner.tenant_id);
      request_.policy_preset_id = checkPresetExistance(partner?.customer_preset_id) || 15;
      request_.customer.license_display_name = request_.policy_preset_id === 15 ? 'enterprise' : 'business';
    }
    if (name === 'policy_preset_id') {
      if (request_.policy_preset_id) {
        request_.customer.license_display_name = request_.policy_preset_id === 15 ? 'enterprise' : 'business';
      } else {
        request_.customer.license_display_name = undefined;
      }
    }

    PickitAPI.cache.set('latest_customer_country', request_.customer.country);
    PickitAPI.cache.set('latest_customer_status', request_.customer.status);
    setRequest(request_);
  };

  const onCancelNewCustomer = () => {
    if (props.route) {
      props.route.history.push('/customer/' + (props.pass.testMode() ? '?test=true' : ''));
    }
  };
  const onError = (msg) => {
    setCreating(false);
    setSnackbarOpen(true);
    setSnackbarMessage('Something went wrong. ' + msg);
  };
  const onCreateNewCustomer = async () => {
    setCreating(true);
    //Catch everything because of the partner errors
    try {
      let request_ = clone(request);
      if (request_.customer.status !== 'trialactive') {
        set(request_, 'trial_days', 0);
      }
      //if the user is from a reseller they should be the customer partner
      if (props.pass.isReseller() && !props.pass.isStaff()) {
        set(request_, 'partner', props.pass.getPartnerTenantId());
      }
      request_.trial_days = Number(request_.trial_days);
      request_.policy = { ...request_.policy, ...policyOverride };

      if (!request_.policy_preset_id) {
        delete request_.policy_preset_id;
      }
      if (!props.pass.isStaff() && request_.policy_preset_id === 15) {
        set(request_, 'customer.license_display_name', 'enterprise');
      }
      try {
        const response = await SalesUICustomer.customers.post(request_);

        let partner_name = undefined;
        if (props.pass.isStaff()) {
          let partner = context.partnerList.find((partner) => {
            if (partner.tenant_id === request.partner) {
              return partner.name;
            }
            return false;
          });
          partner_name = partner.name;
        } else {
          partner_name = props.pass.getPartnerName();
        }
        response.data[0].customer.partner_name = partner_name;
        context.addCustomer({ ...response.data[0] });
        props.route.history.push('/customer/' + response.data[0].customer.tenant_id + (props.pass.testMode() ? '?test=true' : ''));
      } catch (e) {
        console.error(e);
        try {
          e.json().then((errorJson) => {
            if (errorJson.errors) {
              console.error(e);
              let msg = errorJson.error.toString() + '. ';
              Object.keys(errorJson.errors).forEach((k) => {
                msg += errorJson.errors[k] + '. ';
              });
              onError(msg);
            } else {
              console.error(e, errorJson);

              onError(errorJson?.error?.toString() || errorJson?.status?.toString() || `${errorJson?.title} | ${errorJson.description}`);
            }
          });
        } catch (er) {
          onError(e.toString());

          console.error(e);
        }
      }
    } catch (error) {
      onError(error.toString());
      console.error(error);
    }
  };
  useEffect(() => {
    if (props?.pass?.getPartnerData() && !props.pass.isStaff() && request.policy_preset_id !== props?.pass?.getPartnerData()?.customer_preset_id) {
      const request_ = clone(request);
      request_.policy_preset_id = checkPresetExistance(props?.pass?.getPartnerData()?.customer_preset_id) ?? 15;
      request_.customer.license_display_name = request_.policy_preset_id === 15 ? 'enterprise' : 'business';
      setRequest(request_);
    }
  }, [checkPresetExistance(props?.pass?.getPartnerData()?.customer_preset_id)]);

  useEffect(() => {
    if (request.policy_preset_id && data.presets && data.presets.length > 0) {
      const preset = data.presets.find((preset) => preset.preset_id === request.policy_preset_id);
      if (!preset) return;
      setPolicy(preset?.services);
      const override = clone(policyOverride);
      Object.keys(preset.services).forEach((group) =>
        preset.services[group].forEach((service) => (override.access[group][service.key] = service.value))
      );
      setPolicyOverride(override);
    }
  }, [request.policy_preset_id, data?.presets?.length]);

  // loader active or no packages or no countries
  if (!countries || !data.presets) {
    return <LoadingScreen loadingMessage={loadingMessage} />;
  }

  if (context.partnerList && !props.pass.isReseller()) {
    return <LoadingScreen loadingMessage={loadingMessage} />;
  }
  if (!!context.partnerList && context.partnerList.length <= 0 && props.pass.isStaff()) {
    return <LoadingScreen loadingMessage={loadingMessage} />;
  }

  let customer = request.customer;
  let country_keys = Object.keys(countries);
  country_keys.sort((a, b) => countries[a].localeCompare(countries[b]));

  const countriesRows = country_keys.map((country) => {
    return (
      <option value={country} key={country}>
        {countries[country]}
      </option>
    );
  });

  const partnerRows = context.partnerList
    ? context.partnerList
        .filter((i) => i.type.toLowerCase() === 'reseller')
        .map((partner) => {
          return (
            <option value={partner.tenant_id} key={partner.tenant_id}>
              {`${partner.name || partner.tenant_id} ${partner.parent ? '(' + partner.parent.name + ')' : ''}`}
            </option>
          );
        })
    : [];
  partnerRows.unshift(
    <option value={''} key={'NaN'}>
      {`Reseller*`}
    </option>
  );

  const saveDisabled =
    !request.partner ||
    !request.customer ||
    !request.customer.name ||
    !request.customer.country ||
    !request.customer.first_name ||
    !request.customer.last_name ||
    !request.customer.email ||
    !request.product.seats > 0 ||
    !request.customer.license_display_name;
  /*  console.table({
    partner: request.partner,
    customer: !!request.customer,
    name: request.customer.name,
    country: request.customer.country,
    first_name: request.customer.first_name,
    last_name: request.customer.last_name,
    email: request.customer.email,
    seats: request.product.seats > 0,
    getPartnerTenantId: props.pass.getPartnerTenantId(),
  }); */
  return (
    <div className={classes.root}>
      <ValidatorForm ref={form} onSubmit={onCreateNewCustomer}>
        <Grid container spacing={gridSpacing} justifyContent="center">
          <Grid item xs={7}>
            <Paper elevation={2} square className={classes.paper}>
              <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12}>
                  <Typography variant={'h6'} className={classes.title} style={{ paddingTop: 4 }}>
                    {'Customer information'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      {props.pass.isReseller() && !props.pass.isStaff() ? (
                        ''
                      ) : (
                        <>
                          <NativeSelect
                            name={'partner'}
                            label={'Reseller'}
                            onChange={onFieldUpdate}
                            value={request.partner}
                            className={`${classes.textValidator} ${classes.removeUnderline}`}
                            required
                            disabled={creating}>
                            {partnerRows}
                          </NativeSelect>
                          {/* <LinearProgress /> */}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'customer.name'}
                          label={'Company name'}
                          onChange={onFieldUpdate}
                          value={customer.name}
                          validators={['required']}
                          errorMessages={['this field is required']}
                          required
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'customer.address'}
                          value={customer.address}
                          label={'Address'}
                          onChange={onFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'customer.postcode'}
                          value={customer.postcode}
                          label={'Zip'}
                          onChange={onFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth style={{ height: '100%' }}>
                        <NativeSelect
                          name={'customer.country'}
                          value={customer.country}
                          label={'Country'}
                          onChange={onFieldUpdate}
                          className={`${classes.removeUnderline}`}
                          style={{ marginTop: 'auto' }}
                          required
                          disabled={creating}>
                          {countriesRows}
                        </NativeSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'customer.city'}
                          value={customer.city}
                          label={'City'}
                          onChange={onFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <br />
                  <Typography variant={'h6'} className={classes.title}>
                    {'Office tenant information'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'customer.domain'}
                          value={customer.domain}
                          label={'Domain name (e.g. pickit.com)'}
                          onChange={onFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex' }}>
                      <FormControl className={classes.formControlWithDomain} fullWidth>
                        <TextValidator
                          name={'community_url'}
                          onChange={onFieldUpdate}
                          value={request.community_url}
                          validators={['isSlugCharsMatch', 'maxSlugLength']}
                          label={'Community URL'}
                          errorMessages={['incorrect URL format', "can't be longer than 30 characters"]}
                          className={classes.textValidatorWithDomain}
                          disabled={creating}
                          fullWidth
                        />
                        <span>.pickit.com</span>
                      </FormControl>
                      <Tooltip arrow title={<p style={{ margin: 0, padding: 0, fontSize: '.75rem' }}>The URL used for content curation</p>}>
                        <Icon style={{ alignSelf: 'flex-end', marginLeft: 5 }}>info</Icon>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <br />
                  <Typography variant={'h6'} className={classes.title}>
                    {'Contact and legal owner of Pickit'}
                  </Typography>
                  <Typography variant={'subtitle1'} className={classes.title} style={{ paddingTop: 0 }}>
                    {'The person at the customer who will agree to the Pickit terms and will be the owner of the customer account'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'customer.first_name'}
                          label={'First name'}
                          onChange={onFieldUpdate}
                          value={customer.first_name}
                          validators={['required']}
                          errorMessages={['this field is required']}
                          className={classes.textValidator}
                          required
                          disabled={creating}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'customer.last_name'}
                          label={'Last name'}
                          onChange={onFieldUpdate}
                          value={customer.last_name}
                          validators={['required']}
                          errorMessages={['this field is required']}
                          className={classes.textValidator}
                          required
                          disabled={creating}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'customer.email'}
                          label={'Email address'}
                          onChange={onFieldUpdate}
                          value={customer.email}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'incorrect email']}
                          className={classes.textValidator}
                          required
                          disabled={creating}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextField
                          name={'customer.phone'}
                          value={customer.phone}
                          label={'Phone'}
                          onChange={onFieldUpdate}
                          disabled={creating}
                          className={classes.textValidator}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <br />
                  <Typography variant={'h6'} className={classes.title}>
                    {'Plan'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <TextValidator
                          name={'product.seats'}
                          label={'Licenses'}
                          onChange={onFieldUpdate}
                          value={request.product.seats}
                          validators={['isDigit', 'largerThanOne']}
                          errorMessages={[
                            'This field is required. Needs to be a number of 1 or more',
                            'This field is required. Needs to be a number of 1 or more',
                          ]}
                          className={classes.textValidator}
                          required
                          disabled={creating}
                          fullWidth
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <br />
                      <Typography variant={'h6'} className={classes.title}>
                        {'Subscription'}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <RadioGroup
                        name={'customer.status'}
                        className={classes.group}
                        value={customer.status}
                        onChange={onFieldUpdate}
                        /* style={{ flexDirection: 'row' }} */
                      >
                        {props.pass.isStaff() && (
                          <>
                            <FormControlLabel key={'NFR'} value={'nfr'} disabled={creating} control={<Radio color={'primary'} />} label={'NFR'} />
                            <FormControlLabel
                              key={'Starter'}
                              value={'starter'}
                              disabled={creating}
                              control={<Radio color={'primary'} />}
                              label={'Starter'}
                            />
                          </>
                        )}
                        <FormControlLabel
                          key={'Paying'}
                          value={'paying'}
                          disabled={creating}
                          control={<Radio color={'primary'} />}
                          label={'Paying'}
                        />
                        <FormControlLabel
                          key={'TrialActive'}
                          value={'trialactive'}
                          disabled={creating}
                          control={<Radio color={'primary'} />}
                          label={'Trial'}
                        />
                      </RadioGroup>
                    </Grid>

                    {customer.status === 'trialactive' && (
                      <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end', paddingTop: 0, paddingBottom: theme.spacing(4) }}>
                        <FormControl className={classes.formControl} fullWidth>
                          <TextValidator
                            name={'trial_days'}
                            label={'Trial Days'}
                            onChange={onFieldUpdate}
                            value={request.trial_days}
                            validators={['isDigit', 'largerThanOne', 'lessThanHundredThousand']}
                            errorMessages={[
                              'This field is required and must be a positive number',
                              'Must be at least 1 trial day',
                              "Can't be more than 100 000 trial days",
                            ]}
                            className={`${classes.textValidator} ${classes.noWrap}`}
                            required
                            disabled={creating}
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                  {props.pass.isStaff() && (
                    <Grid item xs={12}>
                      <Typography variant="h6" style={{ paddingTop: 20, paddingBottom: 20 }}>
                        Policy configuration
                      </Typography>
                      <Grid container xs={12}>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl} style={{ maxWidth: '175px' }}>
                            <InputLabel>Policy preset</InputLabel>
                            <Select label="Policy preset" name={'policy_preset_id'} value={request.policy_preset_id} onChange={onFieldUpdate}>
                              {data.presets.map((preset) => (
                                <MenuItem key={preset.preset_id} value={preset.preset_id}>
                                  {preset.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Custom policy configurations will disconnect the preset.</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          {props.pass.isStaff() && (
                            <>
                              <FormControl className={classes.formControl} disabled={!props.pass.isStaff()} style={{ width: 175 }}>
                                <InputLabel>License Display Name*</InputLabel>
                                <Select
                                  name={'customer.license_display_name'}
                                  value={customer.license_display_name}
                                  onChange={onFieldUpdate}
                                  required>
                                  <MenuItem value="business">Business</MenuItem>
                                  <MenuItem value="enterprise">Enterprise</MenuItem>
                                </Select>
                                <FormHelperText>This value will be presented in the app as the license type.</FormHelperText>
                                {!request.customer.license_display_name && (
                                  <FormHelperText style={{ color: '#ff0000aa' }}>*This field is required</FormHelperText>
                                )}
                              </FormControl>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Services
                        key={request.policy_preset_id}
                        preset={{ services: policy }}
                        onChange={(services, change) => {
                          const service = change[0].service;
                          const newOverride = set(policyOverride, service.path, service.value);
                          onFieldUpdate({ target: { name: 'policy_preset_id', value: undefined } });
                          setPolicyOverride(newOverride);
                        }}
                        style={{ marginTop: 20 }}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <br />
                  <br />
                  <Button variant={'contained'} color={'primary'} type={'submit'} className={classes.saveButton} disabled={creating || saveDisabled}>
                    {creating ? <CircularProgress size={24} /> : 'Create'}
                  </Button>
                  <Button
                    variant={'text'}
                    disableRipple
                    color={'secondary'}
                    type={'reset'}
                    className={classes.cancelButton}
                    onClick={onCancelNewCustomer}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              <br />
              <br />
            </Paper>
          </Grid>
        </Grid>
      </ValidatorForm>

      <Snackbar open={snackbarOpen} message={snackbarMessage} severity="error" onClose={() => setSnackbarOpen(false)} />
    </div>
  );
}

CustomerNew.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerNew);

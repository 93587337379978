import { Box, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import React from 'react';

export const ServiceField = (props) => {
  const { service, onChange, disabled } = props;
  const component =
    typeof service.value === 'string' ? (
      <TextField
        value={service.value}
        onChange={(event) => onChange(event.target.value)}
        label={service.name}
        size="small"
        disabled={disabled}
        key={service.name}
      />
    ) : (
      <FormControlLabel
        control={<Checkbox color="primary" checked={service.value} onChange={() => onChange(!service.value)} disabled={disabled} />}
        label={service.name}
      />
    );
  return (
    <Grid xs item style={{ maxWidth: 220 }}>
      <Box style={{ width: 220 }}>{component}</Box>
    </Grid>
  );
};
export default ServiceField;

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PortalDataContext } from 'Contexts/PortalDataContext';
import { CustomerManagement as CustomerManagementContext } from 'Contexts/CustomerManagement';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

import {
  FormControl,
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Icon,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

import clone from 'clone';
import SalesUIPartner from 'PickitAPI/Partner';
import SalesUICustomer from 'PickitAPI/Customer';
import PickitAPI from 'PickitAPI';
import usePresets from 'hooks/usePresets';
import { Services } from 'Components/Presets';

const gridSpacing = 2;

const styles = (theme) => ({
  root: {
    marginTop: 30,
  },
  customerLogo: {
    textAlign: 'center',
    padding: '4px 0',
    height: 100,
  },
  customerLogoImg: {
    maxHeight: 75,
    maxWidth: '100%',
    margin: '0 auto',
  },
  grid: {
    padding: '0 30px 30px',
  },
  formControl: {
    width: '100%',
    paddingBottom: 20,
  },
  title: {
    paddingTop: 20,
  },
  subheading: {},
  saveButton: {
    margin: '0 auto',
    display: 'block',
  },
  squarePaperOverride: {
    '& > div > div ': {
      borderRadius: 0,
    },
  },
});

function CustomerConfiguration(props) {
  const { classes, handleOpenSuspend, handleOpenDelete } = props;
  const context = useContext(PortalDataContext);

  const CustomerManagement = useContext(CustomerManagementContext);
  const { handleCustomerChange, customer: cust, originalCustomer } = CustomerManagement;
  const { customer, community, policy = undefined, policy_preset_id } = cust;

  const { data } = usePresets();
  const mergedServices = clone(data.services);
  const [dialogConvertOpen, setDialogConvertOpen] = useState(false);
  const [dialogSendOpen, setDialogSendOpen] = useState(false);
  const [hasChangedPolicy, setHasChangedPolicy] = useState(false);

  useEffect(() => {
    if (
      policy_preset_id &&
      data.presets &&
      data.presets.length > 0 &&
      (originalCustomer?.policy_preset_id !== policy_preset_id || hasChangedPolicy)
    ) {
      const preset = data.presets.find((preset) => preset.preset_id === policy_preset_id);
      const newPolicy = clone(policy) ?? {};
      Object.keys(preset?.services).forEach((group) => {
        if (!newPolicy.access) {
          newPolicy.access = {};
        }
        if (!newPolicy.access[group]) {
          newPolicy.access[group] = {};
        }
        preset?.services[group].forEach((service) => (newPolicy.access[group][service.key] = service.value));
      });
      setHasChangedPolicy(true);
      handleCustomerChange({ path: 'policy', value: newPolicy });
    }
  }, [policy_preset_id, data?.presets?.length]);

  if (mergedServices) {
    Object.keys(mergedServices).forEach(
      (key) =>
        (mergedServices[key] = mergedServices[key].map((service) => {
          const value = policy?.access?.[key]?.[service.slug];
          service.value = value;
          return service;
        }))
    );
  }

  const contentFilter = {
    safe: 'Safe',
    moderate: 'Moderate',
    allowing: 'Allowing (NSFW and some racyness)',
  };
  const pickitMarketVisibility = {
    all: 'Show for all members',
    admin: "Don't show (Always available for Admins inside Admin portal)",
    specified: 'Selected members',
  };
  const selfServiceScenario = {
    '': 'Default',
    dropbox: 'Dropbox',
    shutterstock: 'Shutterstock',
  };

  const curatorAccess = {
    enabled: 'Enabled',
    disabled: 'Disabled',
  };

  const handleChange = (event, value) => {
    const { customer: customer_, community: community_ } = clone(cust);
    event?.persist?.();
    const field = event.target.name || event.target.parentElement.parentElement.name;
    switch (field) {
      case 'content_filter':
        handleCustomerChange({ path: 'community.content_filter', value });
        return;
      case 'market_status':
        handleCustomerChange({ path: 'community.market_status', value });
        return;
      case 'curator':
        handleCustomerChange({ path: 'community.curator.is_active', value: value === 'enabled' ? true : false });
        return;
      case 'preset_id':
        handleCustomerChange({ path: 'policy_preset_id', value: event.target.value });
        return;
      case 'license_display_name':
        handleCustomerChange({ path: 'customer.license_display_name', value: event.target.value });
        return;
      case 'self_service_scenario':
        handleCustomerChange({ path: 'policy.access.metadata.self_service_scenario', value: event.target.value });
        return;
      case 'convert':
        customer_.is_test = !customer.is_test;
        break;

      default:
        break;
    }

    SalesUICustomer.customers
      .put({
        customer: customer_,
        community: community_,
      })
      .then((data) => {
        props.handleSnackbar('Customer updated', 'success');
        if (field === 'convert') {
          //TODO: Create a way to remove customer from cached list instead of reload
          PickitAPI.cache.set('sales_ui_customers', undefined);
          window.location.href = window.location.origin + '/customers' + (customer.is_test ? '?test=true' : '');
        } else {
          context.updateCustomer(data.data[0]);
        }
      })
      .catch((e) => {
        console.error(e);
        props.handleSnackbar('Something went wrong. ' + e.statusText.toString(), 'error');
        handleDialogClose();
      });
  };
  const handlePolicyChange = (path, value) => {
    handleCustomerChange({ path: 'policy.' + path, value });
    if (policy_preset_id) handleChange({ target: { name: 'preset_id', value: undefined } });
  };

  const handleLoginAsCurator = () => {
    let host = 'https://app.' + window.location.hostname.replace(/portal\./, '');
    let hostname = host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : host;
    let url =
      `${hostname}/#/` +
      `?auth=${community.curator.simple_token}` +
      `&user_id=${community.curator.id}` +
      `&community=${community.host || community.slug}` +
      `&clear_cache=1`;
    url = url.replace('proxy.', 'app.');
    window.open(url, '_blank');
  };
  const copy = (event) => {
    event.stopPropagation();
    let host = 'https://app.' + window.location.hostname.replace(/portal\./, '');
    let hostname = host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : host;
    let url =
      `${hostname}/#/` +
      `?auth=${community.curator.simple_token}` +
      `&user_id=${community.curator.id}` +
      `&community=${community.host || community.slug}` +
      `&clear_cache=1`;
    url = url.replace('proxy.', 'app.');
    navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(url);
      }
    });
  };

  const handleSendPassword = () => {
    SalesUIPartner.users
      .put(
        {
          partner: customer.partner,
          action: 'notify',
          users_id: [community.owner_id],
        },
        community.slug
      )
      .then((data) => {
        props.handleSnackbar('Email with password was sent', 'success');
        handleDialogClose();
      })
      .catch((e) => {
        e.json().then((errorJson) => {
          props.handleSnackbar('Something went wrong. ' + errorJson.error.toString(), 'error');
        });
      });
  };

  const handleClickOpenConvert = () => {
    setDialogConvertOpen(true);
  };

  const handleDialogClose = () => {
    setDialogConvertOpen(false);
    setDialogSendOpen(false);
  };
  if (!policy?.access?.metadata?.self_service_scenario) {
    if (!policy?.access?.metadata) {
      policy.access.metadata = {};
    }
    policy.access.metadata.self_service_scenario = '';
  }

  const curatorEnabled =
    originalCustomer.customer.status &&
    originalCustomer.customer.status.toLowerCase() !== 'deleted' &&
    originalCustomer.customer.status.toLowerCase() !== 'suspended' &&
    originalCustomer.community.curator.is_active;
  if (!data.services) return <></>;
  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Paper elevation={2} square className={classes.paper}>
            <Grid container spacing={gridSpacing} className={classes.grid}>
              <Grid item xs={12}>
                {customer.status && customer.status.toLowerCase() !== 'deleted' && (
                  <div>
                    <>
                      <Typography variant="h6" style={{ paddingTop: 20 }}>
                        Policy configuration
                      </Typography>
                      <FormControl className={classes.formControl} disabled={!props.pass.isStaff()} style={{ width: 175 }}>
                        <InputLabel>Policy preset</InputLabel>
                        <Select label="Policy preset" name={'preset_id'} value={policy_preset_id} onChange={handleChange}>
                          {data.presets.map((preset) => (
                            <MenuItem key={preset.preset_id} value={preset.preset_id}>
                              {preset.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Custom policy configurations will disconnect the preset</FormHelperText>
                      </FormControl>
                      <Services
                        key={Object.keys(policy ?? {})}
                        accordion
                        services={mergedServices}
                        onChange={(services, changes) => handlePolicyChange(changes[0].service.path, changes[0].service.value)}
                        disabled={!props.pass.isStaff()}
                      />
                    </>
                    <Typography variant={'h6'} className={classes.title}>
                      {'Settings'}
                    </Typography>
                    {props.pass.isStaff() && (
                      <>
                        <Typography variant={'subtitle1'} className={classes.subheading}>
                          {'License Display Name'}
                        </Typography>
                        <FormControl className={classes.formControl} disabled={!props.pass.isStaff()} style={{ width: 175 }}>
                          <Select name={'license_display_name'} value={customer.license_display_name} onChange={handleChange}>
                            <MenuItem value="business">Business</MenuItem>
                            <MenuItem value="enterprise">Enterprise</MenuItem>
                          </Select>
                          <FormHelperText>This value will be presented in the app as the license type.</FormHelperText>
                        </FormControl>
                      </>
                    )}
                    <Typography variant={'subtitle1'} className={classes.subheading}>
                      {'Self Service Scenario'}
                    </Typography>
                    <FormControl component={'fieldset'} className={classes.formControl}>
                      <RadioGroup
                        name={'self_service_scenario'}
                        className={classes.group}
                        value={policy?.access?.metadata?.self_service_scenario}
                        onChange={handleChange}>
                        {Object.keys(selfServiceScenario).map((key) => {
                          return (
                            <FormControlLabel
                              key={key}
                              value={key}
                              control={<Radio color={'primary'} />}
                              label={selfServiceScenario[key]}
                              checked={
                                key === policy?.access?.metadata?.self_service_scenario ||
                                (key === '' && !key && key === policy?.access?.metadata?.self_service_scenario)
                              }
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                    <Typography variant={'subtitle1'} className={classes.subheading}>
                      {'Content Filter'}
                    </Typography>
                    <FormControl component={'fieldset'} className={classes.formControl}>
                      <RadioGroup name={'content_filter'} className={classes.group} value={community.content_filter} onChange={handleChange}>
                        {Object.keys(contentFilter).map((key) => {
                          return <FormControlLabel key={key} value={key} control={<Radio color={'primary'} />} label={contentFilter[key]} />;
                        })}
                      </RadioGroup>
                    </FormControl>

                    <Typography variant={'subtitle1'} className={classes.subheading}>
                      {'Pickit Stock visibility'}
                    </Typography>
                    <FormControl component={'fieldset'} className={classes.formControl}>
                      <RadioGroup name={'market_status'} className={classes.group} value={community.market_status} onChange={handleChange}>
                        {Object.keys(pickitMarketVisibility).map((key) => {
                          return (
                            <FormControlLabel
                              key={key}
                              value={key}
                              control={<Radio color={'primary'} />}
                              label={pickitMarketVisibility[key]}
                              disabled={key === 'specified'}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>

                    {
                      <>
                        <Typography variant={'subtitle1'} className={classes.subheading}>
                          {'Curator access'}
                        </Typography>
                        <FormControl component={'fieldset'} className={classes.formControl}>
                          <RadioGroup
                            name={'curator'}
                            className={classes.group}
                            value={community.curator.is_active ? 'enabled' : 'disabled'}
                            onChange={handleChange}>
                            {Object.keys(curatorAccess).map((key) => {
                              return (
                                <FormControlLabel
                                  key={key}
                                  value={key}
                                  control={<Radio color={'primary'} />}
                                  label={curatorAccess[key]}
                                  disabled={key === 'specific'}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </>
                    }
                    <br />
                    <hr />
                    <br />
                  </div>
                )}

                <Typography variant={'h6'}>{'Actions'}</Typography>
                <List>
                  {
                    <ListItem
                      button={curatorEnabled}
                      component="a"
                      disabled={!curatorEnabled}
                      onClick={curatorEnabled ? handleLoginAsCurator : () => {}}>
                      <ListItemIcon>
                        <Icon>launch</Icon>
                      </ListItemIcon>
                      <ListItemText inset>{'Login as Curator'}</ListItemText>
                      {props.pass.isStaff() && (
                        <IconButton onClick={copy} style={{ marginLeft: 'auto' }} title="Copy link. Only available for staff">
                          <FilterNoneIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  }
                  {customer.status && customer.status.toLowerCase() !== 'deleted' && customer.status.toLowerCase() !== 'suspended' && (
                    <ListItem button onClick={() => setDialogSendOpen(true)}>
                      <ListItemText inset>{'Send email with login and new password to community owner'}</ListItemText>
                    </ListItem>
                  )}
                  {customer.status && customer.status.toLowerCase() !== 'deleted' && customer.status.toLowerCase() !== 'suspended' && (
                    <ListItem button onClick={handleOpenSuspend}>
                      <ListItemText inset>{'Suspend Customer'}</ListItemText>
                    </ListItem>
                  )}

                  {customer.status && customer.status.toLowerCase() === 'suspended' && (
                    <ListItem button onClick={handleOpenSuspend}>
                      <ListItemText inset>{'Activate Customer'}</ListItemText>
                    </ListItem>
                  )}
                  <ListItem button onClick={handleOpenDelete}>
                    <ListItemText inset>{'Delete Customer'}</ListItemText>
                  </ListItem>
                  {props.pass.isStaff() &&
                    (customer.tenant_id !== customer.partner ? (
                      <ListItem name="convert" button onClick={handleClickOpenConvert}>
                        <ListItemText name="convert" inset>
                          {customer.is_test ? 'Convert to non test customer' : 'Convert to test customer'}
                        </ListItemText>
                      </ListItem>
                    ) : (
                      <ListItem button={false} disabled>
                        <ListItemText inset>
                          {'This customer is directly connected to a partner and can not be converted on its own.'}
                          <br />
                          {' Please convert the partner instead.'}
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={dialogConvertOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.squarePaperOverride}>
        <DialogTitle id="alert-dialog-title">{'Convert'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to convert this customer?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} onClick={handleDialogClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            variant={'text'}
            name="convert"
            onClick={(event) => {
              handleChange(event, true);
            }}
            color="primary">
            <span name="convert">Convert</span>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogSendOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.squarePaperOverride}>
        <DialogTitle id="alert-dialog-title">{'New Password'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to send a new password to the community owner??</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} onClick={handleDialogClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button variant={'text'} name="send" onClick={handleSendPassword} color="primary">
            <span name="send">Send</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CustomerConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerConfiguration);

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton as MuiIconButton,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { Services } from '.';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  accordion: {
    flexGrow: 1,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
});
const IconButton = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: '0px 12px',
  },
}))(MuiIconButton);

const Preset = (props) => {
  const { preset, classes, onSave, onDelete, firstOption, lastOption, disabled } = props;

  const [localPreset, setLocalPreset] = useState(preset);
  useEffect(() => {
    if (!localPreset || preset.order !== localPreset.order) {
      setLocalPreset(preset);
    }
  }, [preset, preset.order]);

  if (!localPreset) {
    return <></>;
  }
  // TODO: Fix this ugly solution but it works for now
  const isBusiness = localPreset.preset_id === 14 && localPreset.name === 'Business',
    isEnterprise = localPreset.preset_id === 15 && localPreset.name === 'Enterprise';
  return (
    <Accordion className={classes.accordion} square key={preset}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {/*Why display grid? No idea, it's the only one that makes overflow to be handeled properly*/}
        <Box display="grid">
          <Typography display="block" noWrap title={preset.name}>
            {preset.name}
          </Typography>
        </Box>
        {
          // Do not allow changing order or delete Business or Enterprise Presets
          !isBusiness && !isEnterprise && (
            <Box style={{ marginLeft: 'auto', minWidth: 144 }}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onSave({ action: 'moveUp', preset: localPreset, initialOrder: preset.order });
                }}
                disabled={firstOption || disabled}>
                <Icon>arrow_upward</Icon>
              </IconButton>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onSave({ action: 'moveDown', preset: localPreset, initialOrder: preset.order });
                }}
                disabled={lastOption || disabled}>
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(preset.preset_id);
                }}
                disabled={disabled}>
                <Icon>delete</Icon>
              </IconButton>
            </Box>
          )
        }
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {preset && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={localPreset.default}
                  onChange={() => setLocalPreset({ ...localPreset, default: !localPreset.default })}
                  disabled={disabled}
                />
              }
              label={'Use preset as default for customers'}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={localPreset.trial}
                  onChange={() => setLocalPreset({ ...localPreset, trial: !localPreset.trial })}
                  disabled={disabled}
                />
              }
              label={'Use preset for trial customers'}
            />
          </>
        )}
        <Services
          preset={preset}
          onChange={(services) => {
            setLocalPreset({ ...localPreset, services });
          }}
          disabled={disabled}
        />
      </AccordionDetails>
      <AccordionActions>
        <Button variant="contained" color={'primary'} onClick={() => onSave({ action: 'replace', preset: localPreset })} disabled={disabled}>
          Save
        </Button>
      </AccordionActions>
    </Accordion>
  );
};
export default withStyles(styles, { withTheme: true })(Preset);

class Utility {
  static getStatus(customer) {
    switch (true) {
      case customer.status.toLowerCase() === 'suspended':
        return 'Suspended';
      case customer.status.toLowerCase() === 'cancelled':
        return 'Canceled';
      case customer.status.toLowerCase() === 'deleted':
        return 'Deleted';
      case customer.status.toLowerCase() === 'starter':
        return 'Starter';
      case customer.status.toLowerCase() === 'free':
        return 'Free';
      case customer.status.toLowerCase() === 'paying':
        return 'Paying';
      case customer.status.toLowerCase() === 'trialexpired':
        return 'Trial Expired';
      case customer.status.toLowerCase() === 'trialactive':
        return 'Trial Active';
      case customer.status.toLowerCase() === 'nfr':
        return 'NFR';
      default:
        return 'Unknown';
    }
  }
  static getCountries() {
    return {
      BD: 'Bangladesh',
      BE: 'Belgium',
      BF: 'Burkina Faso',
      BG: 'Bulgaria',
      BA: 'Bosnia and Herzegovina',
      BB: 'Barbados',
      WF: 'Wallis and Futuna',
      BL: 'Saint Barthélemy',
      BM: 'Bermuda',
      BN: 'Brunei Darussalam',
      BO: 'Bolivia, Plurinational State of',
      BH: 'Bahrain',
      BI: 'Burundi',
      BJ: 'Benin',
      BT: 'Bhutan',
      JM: 'Jamaica',
      BV: 'Bouvet Island',
      BW: 'Botswana',
      WS: 'Samoa',
      BQ: 'Bonaire, Sint Eustatius and Saba',
      BR: 'Brazil',
      BS: 'Bahamas',
      JE: 'Jersey',
      BY: 'Belarus',
      BZ: 'Belize',
      RU: 'Russian Federation',
      RW: 'Rwanda',
      RS: 'Serbia',
      TL: 'Timor-Leste',
      RE: 'Réunion',
      TM: 'Turkmenistan',
      TJ: 'Tajikistan',
      RO: 'Romania',
      TK: 'Tokelau',
      GW: 'Guinea-Bissau',
      GU: 'Guam',
      GT: 'Guatemala',
      GS: 'South Georgia and the South Sandwich Islands',
      GR: 'Greece',
      GQ: 'Equatorial Guinea',
      GP: 'Guadeloupe',
      JP: 'Japan',
      GY: 'Guyana',
      GG: 'Guernsey',
      GF: 'French Guiana',
      GE: 'Georgia',
      GD: 'Grenada',
      GB: 'United Kingdom',
      GA: 'Gabon',
      GN: 'Guinea',
      GM: 'Gambia (The)',
      GL: 'Greenland',
      GI: 'Gibraltar',
      GH: 'Ghana',
      OM: 'Oman',
      TN: 'Tunisia',
      JO: 'Jordan',
      IT: 'Italy',
      HR: 'Croatia',
      HT: 'Haiti',
      HU: 'Hungary',
      HK: 'Hong Kong',
      HN: 'Honduras',
      HM: 'Heard Island and McDonald Islands',
      VE: 'Venezuela, Bolivarian Republic of',
      PR: 'Puerto Rico',
      PS: 'Palestine, State of',
      PW: 'Palau',
      PT: 'Portugal',
      KN: 'Saint Kitts and Nevis',
      PY: 'Paraguay',
      IQ: 'Iraq',
      PA: 'Panama',
      PF: 'French Polynesia',
      PG: 'Papua New Guinea',
      PE: 'Peru',
      PK: 'Pakistan',
      PH: 'Philippines',
      PN: 'Pitcairn',
      PL: 'Poland',
      PM: 'Saint Pierre and Miquelon',
      ZM: 'Zambia',
      EH: 'Western Sahara',
      EE: 'Estonia',
      EG: 'Egypt',
      ZA: 'South Africa',
      EC: 'Ecuador',
      SJ: 'Svalbard and Jan Mayen',
      VN: 'Viet Nam',
      SB: 'Solomon Islands',
      ET: 'Ethiopia',
      SO: 'Somalia',
      ZW: 'Zimbabwe',
      SA: 'Saudi Arabia',
      ES: 'Spain',
      ER: 'Eritrea',
      ME: 'Montenegro',
      MD: 'Moldova (the Republic of)',
      MG: 'Madagascar',
      MF: 'Saint Martin (French part)',
      MA: 'Morocco',
      MC: 'Monaco',
      UZ: 'Uzbekistan',
      MM: 'Myanmar',
      ML: 'Mali',
      MO: 'Macao',
      MN: 'Mongolia',
      MH: 'Marshall Islands',
      MK: 'Macedonia (the former Yugoslav Republic of)',
      MU: 'Mauritius',
      MT: 'Malta',
      MW: 'Malawi',
      MV: 'Maldives',
      MQ: 'Martinique',
      MP: 'Northern Mariana Islands',
      MS: 'Montserrat',
      MR: 'Mauritania',
      IM: 'Isle of Man',
      UG: 'Uganda',
      MY: 'Malaysia',
      MX: 'Mexico',
      MZ: 'Mozambique',
      FR: 'France',
      AW: 'Aruba',
      SH: 'Saint Helena, Ascension and Tristan da Cunha',
      AX: 'Åland Islands',
      FI: 'Finland',
      FJ: 'Fiji',
      FK: 'Falkland Islands  [Malvinas]',
      FM: 'Micronesia (the Federated States of)',
      FO: 'Faroe Islands',
      NI: 'Nicaragua',
      NL: 'Netherlands',
      NO: 'Norway',
      NA: 'Namibia',
      VU: 'Vanuatu',
      NC: 'New Caledonia',
      NE: 'Niger',
      NF: 'Norfolk Island',
      NG: 'Nigeria',
      NZ: 'New Zealand',
      NP: 'Nepal',
      NR: 'Nauru',
      NU: 'Niue',
      CK: 'Cook Islands',
      CI: "Côte d'Ivoire",
      CH: 'Switzerland',
      CO: 'Colombia',
      CN: 'China',
      CM: 'Cameroon',
      CL: 'Chile',
      CC: 'Cocos (Keeling) Islands',
      CA: 'Canada',
      CG: 'Congo',
      CF: 'Central African Republic',
      CD: 'Congo (the Democratic Republic of the)',
      CZ: 'Czech Republic',
      CY: 'Cyprus',
      CX: 'Christmas Island',
      CR: 'Costa Rica',
      CW: 'Curaçao',
      CV: 'Cape Verde',
      CU: 'Cuba',
      SZ: 'Swaziland',
      SY: 'Syrian Arab Republic',
      SX: 'Sint Maarten (Dutch part)',
      KG: 'Kyrgyzstan',
      KE: 'Kenya',
      SS: 'South Sudan',
      SR: 'Suriname',
      KI: 'Kiribati',
      KH: 'Cambodia',
      SV: 'El Salvador',
      KM: 'Comoros',
      ST: 'Sao Tome and Principe',
      SK: 'Slovakia',
      KR: 'Korea (the Republic of)',
      SI: 'Slovenia',
      KP: "Korea (the Democratic People's Republic of)",
      KW: 'Kuwait',
      SN: 'Senegal',
      SM: 'San Marino',
      SL: 'Sierra Leone',
      SC: 'Seychelles',
      KZ: 'Kazakhstan',
      KY: 'Cayman Islands',
      SG: 'Singapore',
      SE: 'Sweden',
      SD: 'Sudan',
      DO: 'Dominican Republic',
      DM: 'Dominica',
      DJ: 'Djibouti',
      DK: 'Denmark',
      VG: 'Virgin Islands (British)',
      DE: 'Germany',
      YE: 'Yemen',
      AT: 'Austria',
      DZ: 'Algeria',
      US: 'United States',
      UY: 'Uruguay',
      YT: 'Mayotte',
      UM: 'United States Minor Outlying Islands',
      TZ: 'Tanzania, United Republic of',
      LC: 'Saint Lucia',
      LA: "Lao People's Democratic Republic",
      TV: 'Tuvalu',
      TW: 'Taiwan (Province of China)',
      TT: 'Trinidad and Tobago',
      TR: 'Turkey',
      LK: 'Sri Lanka',
      LI: 'Liechtenstein',
      LV: 'Latvia',
      TO: 'Tonga',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      LR: 'Liberia',
      LS: 'Lesotho',
      TH: 'Thailand',
      TF: 'French Southern Territories',
      TG: 'Togo',
      TD: 'Chad',
      TC: 'Turks and Caicos Islands',
      LY: 'Libya',
      VA: 'Holy See  [Vatican City State]',
      VC: 'Saint Vincent and the Grenadines',
      AE: 'United Arab Emirates',
      AD: 'Andorra',
      AG: 'Antigua and Barbuda',
      AF: 'Afghanistan',
      AI: 'Anguilla',
      VI: 'Virgin Islands (U.S.)',
      IS: 'Iceland',
      IR: 'Iran (the Islamic Republic of)',
      AM: 'Armenia',
      AL: 'Albania',
      AO: 'Angola',
      AQ: 'Antarctica',
      AS: 'American Samoa',
      AR: 'Argentina',
      AU: 'Australia',
      IL: 'Israel',
      IO: 'British Indian Ocean Territory',
      IN: 'India',
      LB: 'Lebanon',
      AZ: 'Azerbaijan',
      IE: 'Ireland',
      ID: 'Indonesia',
      UA: 'Ukraine',
      QA: 'Qatar',
    };
  }
}
export default Utility;

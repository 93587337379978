import React from 'react';
import { withStyles, Grid, Paper, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import InternalServerError from 'images/err-500.svg';
import ServiceUnavailable from 'images/err-503.svg';
import NotFound from 'images/err-404.svg';

const gridSpacing = 2;

const styles = (theme) => ({
  root: {
    marginTop: '5vh',
    marginBottom: 50,
  },
  grid: {
    margin: 0,
    padding: 5,
  },
  paperLoader: {
    minHeight: '35vh',
    paddingTop: '20vh',
    paddingBottom: '10vh',
  },
  paper: {
    minHeight: '60vh',
  },
});
function ErrorScreen(props) {
  const { errorMessage } = props;
  const getPicture = () => {
    if (errorMessage) {
      if (errorMessage.status === 404) {
        return (
          <React.Fragment>
            <img src={NotFound} alt={'ERROR 404'} style={{ width: '40%' }} />
          </React.Fragment>
        );
      }
      if (errorMessage.status === 500) {
        return (
          <React.Fragment>
            <img src={InternalServerError} alt={'ERROR 500'} style={{ width: '40%' }} />
          </React.Fragment>
        );
      }
      if (errorMessage.status === 503) {
        return (
          <React.Fragment>
            <img src={ServiceUnavailable} alt={'ERROR 503'} style={{ width: '40%' }} />
          </React.Fragment>
        );
      }
    }
  };
  const { classes } = props;

  let errorPicture = getPicture();

  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Paper elevation={2} square className={classes.paperLoader} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {errorPicture}

            <Typography variant={'h6'} color={'primary'} align={'center'}>
              {'Something went wrong...'}
              <svg
                height="24"
                style={{ fill: '#FFF', display: 'table', margin: '15px auto 5px auto' }}
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
              </svg>
            </Typography>
            <br />

            <Typography variant={'subtitle1'} color={'primary'} align={'center'}>
              {errorMessage ? errorMessage : 'UNKNOWN ERROR'}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

ErrorScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(ErrorScreen);

import { Checkbox, FormControl, LinearProgress, NativeSelect, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

function User(props) {
  const { partnerUser, classes, selectedUsers, handleSelectUser, changingRole, handleAction, roleRows, changingUser } = props;
  return (
    <TableRow hover key={partnerUser.id}>
      <TableCell size="small" className={classes.noWrap} style={{ width: '0%', margin: 0, padding: 0 }}>
        <Checkbox
          value={'' + partnerUser.id}
          color={'primary'}
          onChange={(event) => handleSelectUser(event, partnerUser.id)}
          checked={selectedUsers.indexOf(partnerUser.id) !== -1}
        />
      </TableCell>
      <TableCell size="small" className={`${classes.noWrap} ${classes.focusCell}`} style={{ width: '30%' }}>
        {partnerUser.name || partnerUser.id}
      </TableCell>
      <TableCell
        size="small"
        className={classes.noWrap}
        style={{ width: '40%', fontSize: 14, overflow: 'hidden', maxWidth: 300, textOverflow: 'ellipsis' }}>
        {partnerUser.email}
      </TableCell>
      <TableCell size="small" className={classes.noWrap} style={{ width: '30%' }}>
        <FormControl fullWidth>
          <NativeSelect disabled={changingRole} name={'user_' + partnerUser.id} value={partnerUser.role} onChange={handleAction}>
            {roleRows}
          </NativeSelect>
          {changingRole && 'user_' + partnerUser.id === changingUser ? <LinearProgress /> : ''}
        </FormControl>
      </TableCell>
    </TableRow>
  );
}

export default User;

import { useRouter } from './useRouter';

function useRouterQuery(defaultParams) {
  const Router = useRouter();
  const params = new URLSearchParams(Router.location.search);

  const values = {};
  params.forEach((value, key) => {
    values[key] = value;
  });

  function redirect() {
    Router.history.replace(`${Router.location.pathname}?${params.toString()}`);
  }

  function applyDefaultParams(force) {
    const currentParams = new URLSearchParams(Router.location.search);

    if (defaultParams) {
      let currentValues = {};
      currentParams.forEach((value, key) => (currentValues[key] = value));

      defaultParams.forEach(({ key, value }) => {
        if (!currentValues[key] || force) {
          if (typeof value === 'undefined') {
            params.delete(key);
            return;
          }
          params.set(key, value);
        }
      });
      redirect();
      return;
    }
  }

  function saveParams(key) {
    const currentParams = new URLSearchParams(Router.location.search);
    let currentValues = {};
    currentParams.forEach((value, key) => (currentValues[key] = value));
    const saveObject = JSON.parse(window.localStorage.getItem('params') ?? '{}');
    saveObject[key] = currentValues;
    window.localStorage.setItem('params', JSON.stringify(saveObject));
  }
  function loadParams(key) {
    const savedParams = JSON.parse(window.localStorage.getItem('params') ?? '{}');
    if (savedParams[key]) {
      Object.keys(savedParams[key]).forEach((param) => params.set(param, savedParams[key][param]));
      redirect();
      return;
    }
    applyDefaultParams();
  }
  return {
    params: values,
    set: (name, value) => {
      params.set(name, value);
      redirect();
    },
    delete: (key) => {
      params.delete(key);
      redirect();
    },
    applyDefaultParams,
    saveParams,
    loadParams,
  };
}

export default useRouterQuery;

import React, { useState, useContext, useEffect } from 'react';
import PickitAPI from 'PickitAPI';
import { PortalDataContext } from 'Contexts/PortalDataContext';

import SalesUIPartner from 'PickitAPI/Partner';
import clone from 'clone';
import { set, startCase } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Button, FormControl, FormHelperText, Grid, Input, Toolbar, Typography, Checkbox, CircularProgress, Paper } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import PropTypes from 'prop-types';
import Utility from 'Services/utils';
import SaveIcon from '@mui/icons-material/Save';
import usePresets from 'hooks/usePresets';

const gridSpacing = 1;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
  },
  cacheMessage: {
    textAlign: 'right',
  },
  toolbar: {
    backgroundColor: '#fb1790',
    color: '#ffffff !important',
    textAlign: 'center',
    height: 70,
  },
  paper: {
    minHeight: '60vh',
    maxHeight: 700,
  },
  gridInfo: {
    padding: 15,
  },
  paperLoader: {
    minHeight: '35vh',
    paddingTop: '25vh',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tab: {
    width: '50%',
  },
  saveButton: {
    display: 'flex',
    padding: '5px 40px',
    minHeight: 24,
    minWidth: 64,
    margin: '32px auto 0px auto',
    boxShadow: 'none',
    alignItems: 'center',
  },
  saveBox: {
    marginTop: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});
function PartnerInfo(props) {
  const context = useContext(PortalDataContext);
  const { classes, distributors, getPartnerTenantId, isStaff } = props;
  const { data } = usePresets();

  const [partner, setPartner] = useState(undefined);
  const [partnerTypes, setPartnerTypes] = useState({
    distributor: {
      title: 'Distributor',
      slug: 'distributor',
    },
    reseller: {
      title: 'Reseller',
      slug: 'reseller',
    },
  });

  const [countries, setCountries] = useState(Utility.getCountries());

  const [saving, setSaving] = useState(false);
  useEffect(() => {
    let partnerTypes_ = partnerTypes;
    let partnerData_ = clone(context.selectedPartner.partner);
    const customer = clone(context.selectedPartner.partnerCustomer.customer);
    partnerData_.country = customer.details.customer.country;
    partnerData_.postcode = customer.details.customer.postcode;
    partnerData_.city = customer.details.customer.city;

    // Limited partner types for Distributors
    if (!isStaff()) {
      partnerTypes_ = {
        reseller: {
          title: 'Reseller',
          slug: 'reseller',
        },
      };

      // For non staff user we must have current partner tenant id on any request
      partnerData_.partner = getPartnerTenantId();
    }
    setPartnerTypes(partnerTypes_);
    setPartner(partnerData_);

    if (PickitAPI.cache.get('sales_ui_countries')) {
      setCountries(PickitAPI.cache.get('sales_ui_countries'));
    } else {
      PickitAPI.helpers.getCountries().then((countries) => {
        setCountries(countries);

        PickitAPI.cache.set('sales_ui_countries', countries);
      });
    }
  }, []);

  const handlePartnerInfoSave = () => {
    setSaving(true);
    if (partner.customer_preset_id === '-') {
      partner.customer_preset_id = undefined;
    }
    SalesUIPartner.partners
      .put(
        {
          partner: partner,
        },
        props.getPartnerTenantId()
      )
      .then((data) => {
        setSaving(false);
        props.handleSnackbar('Partner updated', 'success');
        context.updatePartner(data.data[0]);
      })
      .catch((e) => {
        console.error(e);
        e.json().then((errorJson) => {
          if (errorJson.errors) {
            let msg = errorJson.error.toString() + '. ';
            Object.keys(errorJson.errors).forEach((k) => {
              msg += errorJson.errors[k] + '. ';
            });
            setSaving(false);
            console.error(msg);
          } else {
            setSaving(false);
          }
        });
      });
  };

  const onFieldUpdate = (event) => {
    // Do not allow update field if user has no admin rights
    if (props.isReadOnly()) return;

    let state_ = { partner: clone(partner) },
      value_ = event.target.value;

    if (event.target.name === 'partner.parent.tenant_id') {
      distributors.forEach((distributor) => {
        if (distributor.tenant_id === event.target.value) value_ = distributor;
      });
    }
    if (event.target.name === 'partner.type') {
      if (event.target.value === 'distributor') state_.partner.parent = { tenant_id: '' };
    }
    if (event.target.name === 'partner.is_test' || event.target.name === 'partner.is_allow_update_policy') {
      value_ = value_ === 'true' ? false : true;
    }

    set(state_, event.target.name, value_);
    setPartner(state_.partner);
  };

  let typeRows = Object.keys(partnerTypes).map((key) => {
    let filter = partnerTypes[key];

    return (
      <option key={key} value={filter.slug}>
        {filter.title}
      </option>
    );
  });

  let keys = Object.keys(countries);
  keys.sort((a, b) => countries[a].localeCompare(countries[b]));

  const countriesRows = keys.map((country) => {
    return (
      <option value={country} key={country}>
        {countries[country]}
      </option>
    );
  });

  const distributorRows = (context.partnerList || [])
    .filter((i) => i.type.toLowerCase() === 'distributor')
    .map((distributor) => {
      return (
        <option value={distributor.tenant_id} key={distributor.tenant_id}>
          {distributor.name || distributor.tenant_id}
        </option>
      );
    });
  if (!partner) return <div></div>;

  return (
    <>
      <Paper elevation={2} square>
        <form>
          <Toolbar className={classes.toolbar}>
            <Typography variant={'h6'} style={{ display: 'inline-block', width: '100%', color: '#fff' }}>
              {startCase(partner.status)}
            </Typography>
          </Toolbar>
          <Grid container spacing={gridSpacing} className={classes.gridInfo}>
            <Grid item xs={12}>
              {/* {(props.isStaff() || partner.type === 'reseller') && (
            <Link variant="body2" style={{ cursor: 'pointer' }}>
              Partner Customer<Icon fontSize={'inherit'}>open_in_new</Icon>
            </Link>
          )} */}
              {props.isStaff() && (
                <FormControl fullWidth>
                  <FormHelperText>{'Partner Type'}</FormHelperText>
                  <NativeSelect name={'partner.type'} value={partner.type} onChange={onFieldUpdate}>
                    {typeRows}
                  </NativeSelect>
                </FormControl>
              )}
              {props.isStaff() && partner.type === 'reseller' && (
                <FormControl fullWidth>
                  <FormHelperText>{'Distributor'}</FormHelperText>
                  <NativeSelect name={'partner.parent.tenant_id'} value={partner.parent ? partner.parent.tenant_id : ''} onChange={onFieldUpdate}>
                    <option value="">-</option>
                    {distributorRows}
                  </NativeSelect>
                </FormControl>
              )}
              <FormControl className={classes.formControl} fullWidth>
                <FormHelperText>{partner.name ? 'Partner Name' : ''}</FormHelperText>
                <Input
                  name={'partner.name'}
                  value={partner.name}
                  placeholder={'Partner Name'}
                  onChange={onFieldUpdate}
                  readOnly={props.isReadOnly()}
                />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <FormHelperText>{partner.url ? 'Domain name (e.g. pickit.com)' : ''}</FormHelperText>
                <Input
                  name={'partner.url'}
                  value={partner.url}
                  placeholder={'Domain name (e.g. pickit.com)'}
                  onChange={onFieldUpdate}
                  readOnly={props.isReadOnly()}
                />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                {props.isStaff() ? (
                  <>
                    <FormControl fullWidth>
                      <FormHelperText>{'Partner Customer Preset'}</FormHelperText>
                      <NativeSelect name={'partner.customer_preset_id'} value={partner.customer_preset_id} onChange={onFieldUpdate}>
                        <option value={undefined}>-</option>
                        {data.presets && data.presets.map((preset) => <option value={preset.preset_id}>{preset.name}</option>)}
                      </NativeSelect>
                    </FormControl>
                    {/*  <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        style={{ paddingLeft: 0 }}
                        name={'partner.is_allow_update_policy'}
                        onChange={onFieldUpdate}
                        color={'primary'}
                        checked={partner.is_allow_update_policy}
                        value={partner.is_allow_update_policy}
                      />
                      <Typography>Can update policies</Typography>
                    </div> */}

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        style={{ paddingLeft: 0 }}
                        name={'partner.is_test'}
                        onChange={onFieldUpdate}
                        color={'primary'}
                        checked={partner.is_test}
                        value={partner.is_test}
                      />
                      <Typography>Test Partner</Typography>
                    </div>
                  </>
                ) : null}
              </FormControl>
              <Typography variant={'h6'} className={classes.title}>
                {'Address'}
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <FormHelperText>{partner.address ? 'Address' : ''}</FormHelperText>
                <Input
                  name={'partner.address'}
                  value={partner.address}
                  placeholder={'Address'}
                  onChange={onFieldUpdate}
                  readOnly={props.isReadOnly()}
                />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <FormHelperText>{partner.city ? 'City' : ''}</FormHelperText>
                <Input name={'partner.city'} value={partner.city} placeholder={'City'} onChange={onFieldUpdate} readOnly={props.isReadOnly()} />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <FormHelperText>{partner.postcode ? 'Zip' : ''}</FormHelperText>
                <Input
                  name={'partner.postcode'}
                  value={partner.postcode}
                  placeholder={'Zip'}
                  onChange={onFieldUpdate}
                  readOnly={props.isReadOnly()}
                />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <FormHelperText>{partner.country ? 'Country' : ''}</FormHelperText>
                <NativeSelect name={'partner.country'} value={partner.country} placeholder={'Country'} onChange={onFieldUpdate}>
                  {countriesRows}
                </NativeSelect>
              </FormControl>
              <br />
              <br />
              <Typography variant={'h6'} className={classes.title}>
                {'Contact person'}
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <FormHelperText>{partner.email ? 'Email address' : ''}</FormHelperText>
                <Input
                  name={'partner.email'}
                  value={partner.email}
                  placeholder={'Email address'}
                  onChange={onFieldUpdate}
                  readOnly={props.isReadOnly()}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {!props.isReadOnly() && (
        /*  <Button
          variant={'contained'}
          disabled={saving}
          color={'primary'}
          type={'button'}
          className={classes.saveButton}
          onClick={handlePartnerInfoSave}>
          {saving ? <CircularProgress size={24} /> : 'Save'}
        </Button> */
        <Button
          disabled={saving /* || !hasChanges */}
          variant={'contained'}
          size="large"
          color={'primary'}
          type={'button'}
          fullWidth
          onClick={handlePartnerInfoSave}
          className={classes.saveBox}>
          {saving ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <SaveIcon className={classes.extendedIcon} /> Save
            </>
          )}
        </Button>
      )}
    </>
  );
}

PartnerInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  distributors: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(PartnerInfo);

import PickitAPI from './index';

let api_host = 'https://' + window.location.hostname.replace(/portal\./, 'proxy.');
const baseUrl = (api_host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : api_host) + '/api';

class SalesUICustomer extends PickitAPI {
  static get customers() {
    let _self = this;

    return {
      getAll: async function (testMode, page = 1, pageLimit = 25, self_service) {
        /*
         * Get customers list
         *
         * */

        let url = baseUrl + `/portal/customers?page_nr=${page}&page_limit=${pageLimit}&`;
        if (self_service !== undefined) {
          url += 'has_self_service=' + self_service + '&';
        }

        if (testMode) {
          url += 'show_test=1';
        }
        let headers = await _self.getHeaders();
        let customers = await _self.request(url, {
          headers: headers,
        });

        try {
          return customers.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      getForPartner: async function (params, testMode) {
        /*
         * Get customers list
         *
         * */
        let url = baseUrl + `/portal/customers?` + _self.stringifyParams(params);
        if (testMode) {
          url += '&show_test=1';
        }
        let headers = await _self.getHeaders();
        let customers = await _self.request(url, {
          headers: headers,
        });

        try {
          return customers.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      getForPartnerProxy: async function (params) {
        /*
         * Get customers list (Proxy)
         *
         * */
        const [start, end] = baseUrl.split('//');
        let url = start + '//' + end + `/portal/customers?` + _self.stringifyParams(params);

        const user = JSON.parse(localStorage.getItem('user'));
        let customers = await _self.request(url, {
          headers: {
            Authorization: `JWT ${user.jwt}`,
            'Content-Type': 'application/json',
            'pickit-api-key': '23bc1999-ae93-4bd1-acc7-641dbf27e7b9',
          },
        });

        try {
          return customers.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      getAllForPartner: async function (params, testMode, page = 1, prevResult) {
        /*
         * Get customers list
         *
         * */
        let url = baseUrl + `/portal/customers?page_limit=100&page_nr=${page}&` + _self.stringifyParams(params);
        if (testMode) {
          url += '&show_test=1';
        }
        let headers = await _self.getHeaders();
        let customers = await _self.request(url, {
          headers: headers,
        });

        let response = undefined;
        try {
          response = await customers.json();
        } catch (e) {
          console.error(e);

          return [];
        }
        const result = prevResult ? prevResult.concat(response.data) : response.data;
        if (response.total_count > result.length && response.data.length !== 0) {
          return this.getAllForPartner(params, testMode, page + 1, result);
        } else {
          return { total: response.total_count, data: result };
        }
      },
      /**
       *
       * @param {object} params
       * @param {bool} testMode
       *
       * Needed params are
       * customer = uu_id
       * partner = uu_id
       **/
      get: async function (params, testMode) {
        /*
         * Get customer by uuid
         *
         * */

        let url = baseUrl + `/portal/customers?` + _self.stringifyParams(params);

        if (testMode) {
          url += '&show_test=1';
        }
        let headers = await _self.getHeaders();
        let customer = await _self.request(url, {
          headers: headers,
        });

        try {
          return customer.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      getCustomer: async function (
        customer_uuid,
        partner,
        parent,
        testMode,
        page = 1,
        pageLimit = 25,
        self_service,
        sort_field,
        sort_order,
        filters
      ) {
        /*
         * Get customer by uuid
         *
         * */

        let url = baseUrl + `/portal/customers?page_nr=${page}&page_limit=${pageLimit}&`;
        if (self_service !== undefined) {
          url += 'has_self_service=' + self_service + '&';
        }
        if (customer_uuid !== undefined) {
          url += 'customer=' + customer_uuid + '&';
        }
        if (partner !== undefined) {
          url += 'partner=' + partner + '&';
        }
        if (parent !== undefined) {
          url += 'parent=' + parent + '&';
        }

        if (testMode) {
          url += 'show_test=1';
        }

        let headers = await _self.getHeaders();
        let customer = await _self.request(url, {
          headers: headers,
        });

        try {
          return customer.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      getAIStatistic: async (community_slug, statistic_range = 'month', host) => {
        let { startAt, middleAt, finishAt } = _self.getAITimeRange(statistic_range);

        let return_data = [middleAt];
        let api_data = [
          ['d267a5bd-e359-44c3-8669-63a237772632', 'koq0uypg0hariqics21tweqhzr13b7fahayvignj'],
          ['0fcd0a1a-ae11-4009-ab98-7c5d6eb5babb', 'bxbt5veap5zkcwdi664e0yb42rczfkmxtd8006u1'],
        ];

        let app_id = api_data[0][0];
        let app_key = api_data[0][1];
        let base_url = 'https://api.applicationinsights.io/v1/apps/' + app_id + '/events/customEvents';

        let url =
          base_url +
          "?$filter=customDimensions/Community eq '" +
          community_slug +
          "' and timestamp ge " +
          startAt.toISOString() +
          ' and timestamp le ' +
          finishAt.toISOString() +
          ' &$top=1000';

        let statistic_old = await _self.request(url, {
          headers: {
            'x-api-key': app_key,
          },
        });

        return_data.push(statistic_old);

        app_id = api_data[1][0];
        app_key = api_data[1][1];
        base_url = 'https://api.applicationinsights.io/v1/apps/' + app_id + '/events/customEvents';

        url =
          base_url +
          "?$filter=customDimensions/Community eq '" +
          community_slug +
          "' and timestamp ge " +
          startAt.toISOString() +
          ' and timestamp le ' +
          finishAt.toISOString() +
          ' &$top=1000';

        let statistic_new = await _self.request(url, {
          headers: {
            'x-api-key': app_key,
          },
        });

        return_data.push(statistic_new);
        if (host && host !== community_slug) {
          app_id = api_data[1][0];
          app_key = api_data[1][1];
          base_url = 'https://api.applicationinsights.io/v1/apps/' + app_id + '/events/customEvents';

          url =
            base_url +
            "?$filter=customDimensions/Community eq '" +
            host +
            "' and timestamp ge " +
            startAt.toISOString() +
            ' and timestamp le ' +
            finishAt.toISOString() +
            ' &$top=1000';

          let statistic_host = await _self.request(url, {
            headers: {
              'x-api-key': app_key,
            },
          });

          return_data.push(statistic_host);
        } else {
          return_data.push({
            json: async () => {
              return { value: [] };
            },
          });
        }

        return return_data;
      },
      getStatistic: async function (customer_uuid, range = 'month') {
        /*
         * Get customer statistic
         *
         * */
        let headers = await _self.getHeaders();
        let customer = await _self.request(baseUrl + '/portal/customers/' + customer_uuid + '/statistic?range=' + range, {
          headers: headers,
        });

        try {
          return customer.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      getAIActivity: async function (slugs = ['internal'], statistic_range = 'month') {
        let { finishAt, middleAt } =
          typeof statistic_range === 'string'
            ? _self.getAITimeRange(statistic_range)
            : { middleAt: statistic_range.start, finishAt: statistic_range.end };
        const key = 'bxbt5veap5zkcwdi664e0yb42rczfkmxtd8006u1';
        const id = '0fcd0a1a-ae11-4009-ab98-7c5d6eb5babb';
        const headers = {
          'X-API-Key': key,
          'Content-Type': 'application/json',
          'x-ms-client-request-info': 'query=UsageNonSegmentedTimeSeriesQuery&part=EventsBlade&chart=',
          'x-ms-app': 'AppInsightsPortalExtension',
          'Cache-Control': 'max-age=3600',
        };
        let communities = '';
        slugs.forEach((slug, index) => {
          if (index !== 0) {
            communities += ' or ';
          }
          communities += `(customDimensions["Community"] == "${slug}")`;
        });

        let query = `
				union customEvents
				| where timestamp between(datetime(${middleAt.toISOString()})..datetime(${finishAt.toISOString()}))
				| where name == "Contribution Downloaded" or name == "File Downloaded" or name == "Search" 
        | where ${communities}
				| summarize count() by tostring(User=case(customDimensions["User Id"] == "", user_Id, customDimensions["User Id"])),  tostring(customDimensions["Community"])
				`;

        const req = await fetch(`https://api1.applicationinsights.io/v1/apps/${id}/query?api-version=2018-04-20`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            query: query,
          }),
        });
        const data = await req.json();
        return data;
      },
      post: async function (post_data) {
        /*
         * Create new customer
         *
         * */
        let url = baseUrl + '/portal/customers';

        let headers = await _self.getHeaders();
        let response_data = await _self.request(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(post_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      put: async function (put_data) {
        /*
         * Update customer
         *
         * */
        let url = baseUrl + '/portal/customers';
        let headers = await _self.getHeaders();
        let response_data = await _self.request(url, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(put_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      delete: async function (customer_uuid, partner_uuid) {
        /*
         * Deactivate customer
         *
         * */
        let url = baseUrl + '/portal/customers?customer=' + customer_uuid + '&partner=' + partner_uuid;
        let headers = await _self.getHeaders();
        let response_data = await _self.request(url, {
          method: 'DELETE',
          headers: headers,
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
    };
  }
}

export default SalesUICustomer;

// React
import { React, useEffect, useState } from 'react';

// CSS
import './customerInsight.css';

// Material UI Icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GroupIcon from '@mui/icons-material/Group';
import DownloadIcon from '@mui/icons-material/Download';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import WebIcon from '@mui/icons-material/Web';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

// Material UI Other
import Tooltip from '@mui/material/Tooltip';
import { Paper } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

// Recharts
import { XAxis, YAxis, CartesianGrid, Legend, Area, AreaChart } from 'recharts';
import { Tooltip as TooltipGraph } from 'recharts';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PieChart, Pie, Cell } from 'recharts';

// d3-scale
import { scaleOrdinal } from 'd3-scale';
import { schemeSet2 } from 'd3-scale-chromatic';
import PickitAPI from 'PickitAPI';
import { Icon } from '@mui/material';

function Widget(props) {
  let args;
  let data = props?.data;
  let nbr = 0;
  let change = '';
  let period = data?.period;
  let changeDefinition = '';

  switch (props.type) {
    case 'ActiveUsers':
      nbr = data.activeUsers[0].activeUsers;
      change = data.activeUsers[0].change;
      if (parseInt(change) < 0) {
        changeDefinition = `Number of active users are down ${parseInt(change) * -1}% compared to the previous period`;
      } else {
        changeDefinition = `Number of active users are up ${change}% compared to the previous period`;
      }
      args = {
        title: 'Active users',
        data: `${nbr}`,
        change: `${change}`,
        period: `Last ${period} days`,
        definition: `Monthly active users last ${period} days`,
        changeDefinition: changeDefinition,
        icon: <GroupIcon fontSize="small" className="icon" />,
      };
      break;
    case 'FileDownloads':
      for (let i = 0; i < data.fileDownloads[0].data.value.length; i++) {
        nbr += data.fileDownloads[0].data.value[i];
      }
      change = data.fileDownloads[0].change;
      if (parseInt(change) < 0) {
        changeDefinition = `Files downloaded are down ${parseInt(change) * -1}% compared to the previous period`;
      } else {
        changeDefinition = `Files downloaded are up ${change}% compared to the previous period`;
      }
      args = {
        title: 'Downloads',
        data: `${nbr}`,
        change: `${change}`,
        period: `Last ${period} days`,
        definition: `Total amount of file downloads over the last ${period} days.`,
        changeDefinition: changeDefinition,
        icon: <DownloadIcon className="icon" />,
      };
      break;
    case 'FileSearches':
      for (let i = 0; i < data.fileSearches[0].data.value.length; i++) {
        nbr += data.fileSearches[0].data.value[i];
      }
      change = data.fileSearches[0].change;
      if (parseInt(change) < 0) {
        changeDefinition = `Logged file searches are down ${parseInt(change) * -1}% compared to the previous period`;
      } else {
        changeDefinition = `Logged file searches are up ${change}% compared to the previous period`;
      }
      args = {
        title: 'Searches',
        data: `${nbr}`,
        change: `${change}`,
        period: `Last ${period} days`,
        definition: `Total amount of file searches over the last ${period} days.`,
        changeDefinition: changeDefinition,
        icon: <ImageSearchIcon className="icon" />,
      };
      break;
    case 'FileCollectionViewed':
      for (let i = 0; i < data.fileCollectionViewed[0].data.value.length; i++) {
        nbr += data.fileCollectionViewed[0].data.value[i];
      }
      change = data.fileCollectionViewed[0].change;
      if (parseInt(change) < 0) {
        changeDefinition = `Collection viewed are down ${parseInt(change) * -1}% compared to the previous period`;
      } else {
        changeDefinition = `Collection viewed are up ${change}% compared to the previous period`;
      }
      args = {
        title: 'Collections',
        data: `${nbr}`,
        change: `${change}`,
        period: `Last ${period} days`,
        definition: `Collection viewed last ${period} days`,
        changeDefinition: changeDefinition,
        icon: <WebIcon className="icon" />,
      };
      break;
    case 'BoardsVisited':
      for (let i = 0; i < data.boardsVisited[0].data.value.length; i++) {
        nbr += data.boardsVisited[0].data.value[i];
      }
      change = data.boardsVisited[0].change;
      if (parseInt(change) < 0) {
        changeDefinition = `Boards visited are down ${parseInt(change) * -1}% compared to the previous period`;
      } else {
        changeDefinition = `Boards visited are up ${change}% compared to the previous period`;
      }
      args = {
        title: 'Boards visited',
        data: `${nbr}`,
        change: `${change}`,
        period: `Last ${period} days`,
        definition: `Boards visited last ${period} days`,
        changeDefinition: changeDefinition,
        icon: <DriveFileMoveIcon className="icon" />,
      };
      break;
    case 'TotalEvents':
      for (let i = 0; i < data.totalEvents[0].data.value.length; i++) {
        nbr += data.totalEvents[0].data.value[i];
      }
      change = data.totalEvents[0].change;
      if (parseInt(change) < 0) {
        changeDefinition = `Total events are down ${parseInt(change) * -1}% compared to the previous period`;
      } else {
        changeDefinition = `Total events are up ${change}% compared to the previous period`;
      }
      args = {
        title: 'Total events',
        data: `${nbr}`,
        change: `${change}`,
        period: `Last ${period} days`,
        definition: `Total events last ${period} days`,
        changeDefinition: changeDefinition,
        icon: <EmojiEventsIcon className="icon" />,
      };
      break;
    default:
      break;
  }

  return (
    <Paper elevation={2} square className="widget">
      <div className="left">
        <span className="title">{args.title}</span>
        <span className="counter">{args.data}</span>
        <span className="link">{args.period}</span>
      </div>
      <div className="right">
        {args.change < 0 ? (
          <Tooltip title={args.changeDefinition} arrow enterDelay={500} leaveDelay={200}>
            <div className="percentageNeg">
              <Icon>
                <KeyboardArrowDownIcon />
                {args.change.slice(1)} %
              </Icon>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={args.changeDefinition} arrow enterDelay={500} leaveDelay={200}>
            <div className="percentagePos">
              <Icon>
                <KeyboardArrowUpIcon />
              </Icon>
              {args.change} %
            </div>
          </Tooltip>
        )}
        <div className="icon">
          <Tooltip title={args.definition} arrow enterDelay={500} leaveDelay={200}>
            <Icon>{args.icon}</Icon>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
}

function Graph(props) {
  const data = [];

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  for (let i = 0; i <= parseInt(props.data.period) - 1; i++) {
    data.push({
      name: new Date(props.data.fileCollectionViewed[0].data.name[i]).toLocaleDateString('en-US', options),
      Downloads: props?.data?.fileDownloads[0]?.data?.value[i],
      Searches: props?.data?.fileSearches[0]?.data?.value[i],
      CollectionViewed: props?.data?.fileCollectionViewed[0]?.data?.value[i],
      BoardsVisited: props?.data?.boardsVisited[0]?.data?.value[i],
      TotalEvents: props?.data?.totalEvents[0]?.data?.value[i],
    });
  }

  return (
    <AreaChart width={730} height={300} data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
      <defs>
        <linearGradient id="colorSearches" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorDownloads" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorCollectionViewed" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorBoardsVisited" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#ffb6c1" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#ffb6c1" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorTotalEvents" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#add8e6" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#add8e6" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey="name" tick={false} />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <TooltipGraph />
      <Legend />
      {props.graphToDisplay.categories.showSearches && (
        // Purple
        <Area type="monotone" dataKey="Searches" stroke="#8884d8" fillOpacity={1} fill="url(#colorSearches)" />
      )}
      {props.graphToDisplay.categories.showDownloads && (
        // Green
        <Area type="monotone" dataKey="Downloads" stroke="#82ca9d" fillOpacity={1} fill="url(#colorDownloads)" />
      )}
      {props.graphToDisplay.categories.showCollectionViewed && (
        // Yellow
        <Area type="monotone" dataKey="CollectionViewed" stroke="#ffc658" fillOpacity={1} fill="url(#colorCollectionViewed)" />
      )}
      {props.graphToDisplay.categories.showBoardVisited && (
        // Pink
        <Area type="monotone" dataKey="BoardsVisited" stroke="#ffb6c1" fillOpacity={1} fill="url(#colorBoardsVisited)" />
      )}
      {props.graphToDisplay.categories.showTotalEvents && (
        // Lightblue
        <Area type="monotone" dataKey="TotalEvents" stroke="#add8e6" fillOpacity={1} fill="url(#colorTotalEvents)" />
      )}
    </AreaChart>
  );
}

function GraphHeader(props) {
  const names = ['Downloads', 'Searches', 'Collection viewed', 'Boards visited', 'Total events'];

  const [personName, setPersonName] = useState(names);
  const [graphToDisplay, setGraphToDisplay] = useState({
    categories: {
      showDownloads: true,
      showSearches: true,
      showCollectionViewed: true,
      showBoardVisited: true,
      showTotalEvents: true,
    },
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setGraphToDisplay({
      categories: {
        showDownloads: value.includes('Downloads'),
        showSearches: value.includes('Searches'),
        showCollectionViewed: value.includes('Collection viewed'),
        showBoardVisited: value.includes('Boards visited'),
        showTotalEvents: value.includes('Total events'),
      },
    });

    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <div className="graphHeader">
        <div className="graphHeaderTopLeftWrapper">
          <span className="title">Graphical representation over the last {props.data.period} days</span>
        </div>
        <div className="graphHeaderTopRightWrapper">
          <ThemeProvider
            theme={createTheme({
              palette: { primary: { main: '#FB1790' } },
            })}>
            <FormControl sx={{ m: 1 }} variant="standard" fullwidth={true} focused={true}>
              <Select
                autoWidth={true}
                multiple
                defualtOpen={true}
                value={personName}
                onChange={handleChange}
                renderValue={(selected) => 'Select'}
                displayEmpty={true}>
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      <Graph data={props.data} graphToDisplay={graphToDisplay} />
    </>
  );
}

function Channels(props) {
  const dataGraph = [];
  let dataDiagram = [];
  const colors = scaleOrdinal(schemeSet2).range();

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  for (let i = 0; i <= parseInt(props.data.period) - 1; i++) {
    dataGraph.push({
      name: new Date(props.data.fileCollectionViewed[0].data.name[i]).toLocaleDateString('en-US', options),
    });
  }

  dataGraph.forEach((obj, index) => {
    props?.data?.channels?.categories.forEach((category) => {
      let name = '';
      name = name + category;
      obj[name] = props.data.channels.data[props?.data?.channels?.categories.indexOf(name)][0]?.data?.value[index];
    });
  });

  //Structure data from API.
  props?.data?.channels?.categories.forEach((category, index) => {
    let name = '';
    name = name + category;
    let totalEvent = 0;
    props.data.channels.data[index][0]?.data?.value.forEach((nbr) => (totalEvent += nbr));
    dataDiagram.push({ name: name, value: totalEvent });
  });

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = 25 + innerRadius + (outerRadius - innerRadius);

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill={colors[index % 20]} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="channelContainer">
      <Paper elevation={2} square className="channelGraphContainer">
        <div className="channelGraphHeader">
          <div className="graphHeaderTopLeftWrapper">
            <span className="title">Channel usage last {props.data.period} days</span>
          </div>
          <div className="graphHeaderTopRightWrapper"></div>
        </div>
        <AreaChart width={450} height={300} data={dataGraph} margin={{ top: 0, right: 20, left: 0, bottom: 0 }}>
          <defs>
            {props?.data?.channels?.categories.map((category, index) => {
              let name = '';
              name = (name + category).replace(' ', '');
              return (
                <linearGradient id={name} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={colors[index % 20]} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={colors[index % 20]} stopOpacity={0} />
                </linearGradient>
              );
            })}
          </defs>
          <XAxis dataKey="name" tick={false} />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <TooltipGraph />
          <Legend />
          {props?.data?.channels?.categories.map((category, index) => {
            let name = '';
            name = name + category;
            // done for syntactic purposes
            let fillName = 'url(#' + name.replace(' ', '') + ')';
            return <Area type="monotone" dataKey={name} stroke={colors[index % 20]} fillOpacity={1} fill={fillName} />;
          })}
        </AreaChart>
      </Paper>
      <Paper elevation={2} square className="channelDiagramContainer">
        <div className="channelGraphHeader">
          <div className="graphHeaderTopLeftWrapper">
            <span className="title">Total channel usage last {props.data.period} days</span>
          </div>
        </div>
        {/* <ResponsiveContainer width="100%" height="100%"> */}
        <div className="diagramContainer">
          <PieChart width={260} height={300} className="diagramContainer">
            <Pie data={dataDiagram} cx="50%" cy="50%" label={renderCustomizedLabel} outerRadius={80} fill="#8884d8" dataKey="value">
              {props?.data?.channels?.categories.map((category, index) => {
                let name = '';
                name = name + category;
                return <Cell key={name} fill={colors[index % 20]} />;
              })}
            </Pie>
            <TooltipGraph />
            <Legend />
          </PieChart>
        </div>
      </Paper>
    </div>
  );
}

function CustomerInsight(props) {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cantLoadData, setCantLoadData] = useState(true);
  const [period, setPeriod] = useState('30d');

  const handleChange = (event) => {
    setPeriod(event.target.value);
  };

  async function getData() {
    const community = props.community.community.slug;
    const data = await PickitAPI.getCustomerInsights({
      community: community,
      period: period,
    });
    if (data.dataRetrieved) {
      setData(data.data);
      setCantLoadData(false);
    } else {
      setCantLoadData(true);
    }
    setIsLoaded(true);
  }

  useEffect(() => {
    setIsLoaded(false);
    getData();
  }, [period, cantLoadData]);

  return (
    <div className="CustomerInsight">
      <div className="topBarContainer">
        <div className="topLeftWrapper">
          <span className="logo">Insights</span>
        </div>
        <div className="topMidWrapper"></div>
        <div className="topRightWrapper">
          <ThemeProvider
            theme={createTheme({
              palette: { primary: { main: '#FB1790' } },
            })}>
            <FormControl sx={{ m: 1 }} variant="standard" fullwidth={true} focused={true}>
              <Select autoWidth={false} value={period} onChange={handleChange}>
                <MenuItem value={'7d'}>7 days</MenuItem>
                <MenuItem value={'14d'}>14 days</MenuItem>
                <MenuItem value={'30d'}>1 month</MenuItem>
                <MenuItem value={'90d'}>3 months</MenuItem>
                <MenuItem value={'180d'}>6 months</MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      {!isLoaded ? (
        <div className="loadingContainer">
          <AutorenewIcon className="loadingIcon" />
        </div>
      ) : (
        <>
          {cantLoadData ? (
            <div className="topBarContainer">
              <div className="topMidWrapper">
                <div className="title">Can't retrieve data, please try again</div>
              </div>
            </div>
          ) : (
            <>
              <div className="headerContainer"></div>
              <div className="widgetContainer">
                <Widget type="ActiveUsers" data={data} />
                <Widget type="FileDownloads" data={data} />
                <Widget type="FileSearches" data={data} />
              </div>
              <div className="widgetContainer">
                <Widget type="FileCollectionViewed" data={data} />
                <Widget type="BoardsVisited" data={data} />
                <Widget type="TotalEvents" data={data} />
              </div>
              <div className="graphWrapper">
                <Paper elevation={2} square className="graphContainer">
                  <GraphHeader data={data} />
                </Paper>
              </div>
              <div className="graphWrapper">
                <Channels data={data} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CustomerInsight;

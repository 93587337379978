import React, { useState } from 'react';

import { Button, TextField, LinearProgress, Card, CardHeader, CardContent, Grid, withStyles, Divider } from '@material-ui/core';

import PickitAPI from 'PickitAPI';
import Auth from 'PickitAPI/Auth';
import Logo from 'images/logotype_full-color.svg';
import SSO from 'Components/SSO';

const gridSpacing = 2;

const styles = (theme) => ({
  root: {
    height: '100vh',
    background: '#f5f2f4',
  },
  message: {
    fontSize: 14,
    color: '#fb1790',
  },
  loginField: {
    '& > div > fieldset': {
      borderColor: '#8E8E8C',
      borderWidth: '2px',
    },
    '& > div::after': {
      borderColor: '#8E8E8C',
    },
  },
  button: {
    padding: '5px 40px',
    minHeight: 24,
    minWidth: 64,
  },
});
function Login(props) {
  const { classes } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const [isRestoringPassword, setIsRestoringPassword] = useState(false);

  const onLogin = async (msSettings) => {
    setIsLoading(true);
    try {
      let response = undefined;
      if (msSettings) {
        response = await PickitAPI.account.MSlogin(msSettings);
      } else {
        response = await PickitAPI.account.login(username, password);
      }
      await init(response);
    } catch (e) {
      console.error('error', e);
      setPassword('');
      setIsLoading(false);
      setMessage('Email or password is invalid. Please try again');
    }
  };

  const init = async (user) => {
    if (user && user.simple_token) {
      let profile = user.profile;

      if (!profile.is_staff) {
        let validSpaces = user.spaces.filter(
          (space) => !space.is_demo && !!space && space.groups && space.groups.find((group) => group.indexOf('_portalread') !== -1)
        );
        let activeSpace = validSpaces.find((space) => space.active && !space.is_demo);
        if (!activeSpace || validSpaces.length <= 0) {
          setPassword('');
          setIsLoading(false);
          setMessage('Your account is not connected to an active community. Please contact support.');

          return false;
        }
        profile.community = activeSpace;
        let readGroup = '';

        for (let i = 0; i < activeSpace.groups.length; i++) {
          if (activeSpace.groups[i].indexOf('_portalread') !== -1) {
            readGroup = activeSpace.groups[i];
          }
        }
        if (readGroup === '') {
          setPassword('');
          setIsLoading(false);
          setMessage("You don't have access to panel");

          return false;
        }
        let slug = '';
        let parts = readGroup.split('_');
        slug += parts[0];
        parts.pop();
        parts.shift();
        if (parts) {
          parts.forEach((part) => {
            slug += `_${part}`;
          });
        }
        try {
          const partner = await PickitAPI.helpers.getUserPartner(slug, activeSpace.partner.id, user.jwt, activeSpace.customer.is_test);

          window.localStorage.setItem('testmode', activeSpace.customer.is_test);
          profile.partner = partner.data[0];

          // Cleanup cache for lists
          PickitAPI.cache.set('sales_ui_customers', [], 0);
          PickitAPI.cache.set('sales_ui_partners', [], 0);
          PickitAPI.cache.set('sales_ui_countries', [], 0);
          PickitAPI.cache.set('sales_ui_packages', [], 0);

          // Authenticate user
          setMessage('Success');
          Auth.authenticate(user);
          Auth.saveProfile(profile);
          props.setAuthenticated(true, profile, activeSpace.customer.is_test);
        } catch (e) {
          setPassword('');
          setIsLoading(false);
          setMessage(error.error);
          return false;
        }
      } else {
        // Cleanup cache for lists
        PickitAPI.cache.set('sales_ui_customers', [], 0);
        PickitAPI.cache.set('sales_ui_partners', [], 0);
        PickitAPI.cache.set('sales_ui_countries', [], 0);
        PickitAPI.cache.set('sales_ui_packages', [], 0);

        // Authenticate user
        setMessage('Success');
        Auth.authenticate(user);
        Auth.saveProfile(profile);

        props.setAuthenticated(true, profile);
      }
    } else {
      setPassword('');
      setIsLoading(false);
      setMessage('Email or password is invalid. Please try again');
    }
  };

  const handleRestorePassword = (event) => {
    setIsLoading(true);
    PickitAPI.account
      .restorePassword(username)
      .then((data) => {
        setIsLoading(false);
        setMessage('Email with recovery link was sent');
      })
      .catch((e) => {
        setIsLoading(false);
        setMessage('');
        setError('Incorrect email');
      });
  };

  const onKeypress = (e) => {
    if (e.key === 'Enter') {
      onLogin();
    }
  };
  const handleRestorePageChange = (newState) => {
    setIsRestoringPassword(newState);
    setMessage('');
  };

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xs={3} style={{ maxWidth: 400 }}>
        {isRestoringPassword ? (
          <Card elevation={1} style={{ padding: 20 }} square>
            <img src={Logo} alt="Pickit Portal" style={{ paddingLeft: 15, height: 45 }} />
            <CardHeader title="Restore Password" />
            <CardContent>
              <form onKeyPress={onKeypress}>
                <TextField
                  placeholder="Enter your email"
                  label="Email"
                  errortext={error}
                  fullWidth
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  className={classes.loginField}
                  variant="outlined"
                />
              </form>
              {message && <p className={classes.message}>{message}</p>}
              <br />
              <Button className={classes.button} color={'primary'} onClick={handleRestorePassword} variant={'contained'}>
                Restore Password
              </Button>
              <Button color={'primary'} variant={'text'} style={{ float: 'right', fontSize: 12 }} onClick={() => handleRestorePageChange(false)}>
                Cancel
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Card elevation={1} square style={{ padding: 20 }}>
            <img src={Logo} alt="Pickit Portal" style={{ paddingLeft: 15, height: 45 }} />
            <CardHeader title="Sign in" />
            <CardContent>
              <form onKeyPress={onKeypress}>
                <TextField
                  placeholder="Enter your email"
                  label="Email"
                  errortext={error}
                  fullWidth
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  className={classes.loginField}
                  variant="outlined"
                />
                <br />
                <br />

                <TextField
                  placeholder="Enter your password"
                  label="Password"
                  errortext={error}
                  fullWidth
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  className={classes.loginField}
                  variant="outlined"
                />
              </form>
              {message && <p className={classes.message}>{message}</p>}
              <br />
              <Button className={classes.button} color={'primary'} onClick={() => onLogin()} variant={'contained'}>
                Login
              </Button>

              <Button
                color={'primary'}
                variant={'text'}
                style={{ float: 'right', fontSize: 12 }}
                href={'https://pickit.com/forgot-password/'}
                target="_blank">
                Forgot Password?
              </Button>
              <Divider style={{ margin: '20px 0' }} />
              <SSO onLogin={onLogin} />
            </CardContent>
          </Card>
        )}

        <div className="outside">
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              {isLoading ? (
                <LinearProgress mode="indeterminate" color={'primary'} />
              ) : (
                <LinearProgress mode="indeterminate" style={{ opacity: 0 }} color={'primary'} />
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(Login);

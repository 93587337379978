import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PortalDataContext } from 'Contexts/PortalDataContext';

import { Grid, Typography, List, ListItem, ListItemText, Icon, FormControl, CircularProgress, FormHelperText, Button, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Table from '@material-ui/core/Table/Table';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import useUsage from 'hooks/useUsage';
import { endOfMonth, startOfMonth, subDays, subMonths } from 'date-fns';

const gridSpacing = 2;

const styles = (theme) => ({
  customerLogo: {
    textAlign: 'center',
    padding: '4px 0',
    height: 100,
  },
  customerLogoImg: {
    maxHeight: 75,
    maxWidth: '100%',
    margin: '0 auto',
  },
  grid: {
    padding: 15,
  },
  gridContent: {},
  toolbarGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    width: '100%',
    paddingBottom: 20,
  },
  subheading: {
    paddingTop: 20,
  },
  saveButton: {
    margin: '0 auto',
    display: 'block',
  },
  toolbar: {
    backgroundColor: '#f7f7f5',
  },
  card: {
    width: '100%',
    height: '100%',
  },
  cardContent: {
    padding: '10px 15px 15px',
    height: '200px',
  },
  statGrid: {},
});
function CustomerStatistic(props) {
  const context = useContext(PortalDataContext);
  const test = useRef('test');
  const { selectedCustomer } = context;
  const { customer, community } = selectedCustomer;
  const { classes } = props;
  const [statistic_range, setStatistic_range] = useState('30-days');
  const [showDetails, setShowDetails] = useState(false);
  const stats = useUsage(community.slug);

  const users = { amount: community.total_number_of_users + community.total_number_of_starter_users };

  useEffect(() => {
    if (statistic_range === '30-days') {
      stats.setStartPeriod(subDays(new Date(), 30));
      stats.setEndPeriod(new Date());
    }
    if (statistic_range === 'month') {
      stats.setStartPeriod(startOfMonth(subMonths(new Date(), 1)));
      stats.setEndPeriod(endOfMonth(subMonths(new Date(), 1)));
    }
    if (statistic_range === '7-days') {
      stats.setStartPeriod(subDays(new Date(), 7));
      stats.setEndPeriod(new Date());
    }
  }, [statistic_range]);

  useEffect(() => {
    if (stats.startPeriod && stats.endPeriod) {
      stats.getToplist();
    }
  }, [stats.startPeriod, stats.endPeriod]);

  const handleChangeStatisticRange = (event) => {
    const newRange = event.target.value;
    setStatistic_range(newRange);
  };

  const getMonthName = (monthNumber) => {
    monthNumber = monthNumber < 0 ? 11 : monthNumber;
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthNumber];
  };

  if (!stats || !customer) {
    return '';
  }

  let Loader = (props) => {
    return (
      <CardContent className={classes.cardContent}>
        <Typography variant={'subtitle1'} component={'strong'} style={{ width: '100%' }}>
          {props.dataTitle || 'NO TITLE'}
        </Typography>
        <Grid container spacing={gridSpacing} alignItems={'center'} style={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              marginTop: '-44px',
            }}>
            <CircularProgress variant="indeterminate" color={'primary'} size={20} />
          </Grid>
        </Grid>
      </CardContent>
    );
  };

  let Trand = (value) => {
    value = parseInt(value);

    if (value > 0) {
      return (
        <Icon className="material-icons" style={{ color: '#63D5A0', transform: 'rotate(45deg)' }}>
          arrow_upward
        </Icon>
      );
    } else if (value < 0) {
      return (
        <Icon className="material-icons" style={{ color: '#FF0000', transform: 'rotate(-45deg)' }}>
          arrow_downward
        </Icon>
      );
    } else {
      return (
        <Icon className="material-icons" style={{ color: '#FFDE58' }}>
          arrow_forward
        </Icon>
      );
    }
  };

  let TrandCard = (title, statistic) => {
    return (
      <CardContent className={classes.cardContent}>
        <Typography
          variant={'subtitle1'}
          component={'strong'}
          style={{
            fontSize: '0.8em',
            fontWeight: 500,
            color: 'rgba(0,0,0,0.45)',
          }}>
          {title}
        </Typography>
        <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography
            variant={'h2'}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              lineHeight: 1,
              marginTop: '-5%',
            }}>
            {statistic.amount || 0}
          </Typography>
          {statistic.difference && (
            <>
              <Typography
                variant={'subtitle1'}
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                {statistic.difference ? statistic.difference + '%' : '0%'}
              </Typography>
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}>
                {Trand(statistic.difference)}
              </div>
            </>
          )}
        </Box>
      </CardContent>
    );
  };

  let ListCard = (title, statistic) => {
    let sortedStatistic = statistic
      .sort(function (a, b) {
        return a[1] - b[1];
      })
      .reverse();
    let rows = sortedStatistic.slice(0, 5).map((row, index) => {
      let name = row[0];

      if (name.length > 22) {
        name = name.slice(0, 22) + '...';
      }

      return (
        <ListItem style={{ padding: 0 }} key={index}>
          <ListItemText primary={name} style={{ fontSize: '0.75rem', overflow: 'hidden', flexGrow: 0.9 }} title={row[0]} />
          <ListItemText primary={'(' + row[1] + ')'} style={{ fontSize: '0.75rem', flexGrow: 0.1, textAlign: 'right' }} />
        </ListItem>
      );
    });

    return (
      <CardContent className={classes.cardContent}>
        <Typography variant={'subtitle1'} component={'strong'} style={{ fontSize: '0.8rem', fontWeight: 500, color: 'rgba(0,0,0,0.45)' }}>
          {title}
        </Typography>
        {statistic.length === 0 ? (
          <p>No data</p>
        ) : (
          <List component="nav" dense>
            {rows}
          </List>
        )}
      </CardContent>
    );
  };

  return (
    <div>
      <br />
      <Grid container spacing={0} className={classes.gridContent}>
        <Grid item xs={5}>
          <br />
          <Typography variant={'h5'}>{'Stats overview'}</Typography>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2}>
          <FormControl>
            <FormHelperText>{'Period'}</FormHelperText>
            <Select value={statistic_range} onChange={handleChangeStatisticRange} disableUnderline>
              <MenuItem value="7-days">
                {'Last: '}
                <b>7 days</b>
              </MenuItem>
              <MenuItem value="30-days">
                {'Last: '}
                <b>30 days</b>
              </MenuItem>
              <MenuItem value="month">
                {'Last Month: '}
                <b>{getMonthName(new Date().getMonth() - 1)}</b>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={0} className={classes.gridContent}>
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.statGrid}>
            <Grid item xs={4} className={classes.toolbarGridItem} ref={test}>
              <Card elevation={0} className={classes.card}>
                {!stats.data.activity?.usageEvents ? (
                  <CircularProgress variant="indeterminate" color={'primary'} size={20} />
                ) : (
                  TrandCard('DOWNLOADS & INSERTS', {
                    amount: stats.data.activity.usageEvents.downloads || 0,
                  })
                )}
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.toolbarGridItem}>
              <Card elevation={0} className={classes.card}>
                {!stats.data.activity?.activeUsers ? (
                  <CircularProgress variant="indeterminate" color={'primary'} size={20} />
                ) : (
                  <CardContent className={classes.cardContent}>
                    <Typography
                      variant={'subtitle1'}
                      component={'strong'}
                      style={{
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.45)',
                      }}>
                      {'USER ACTIVITY'}
                    </Typography>
                    <br />
                    <Table size="small">
                      <TableHead>
                        <TableRow style={{ height: 20 }}>
                          <TableCell size="small" style={{ padding: 0 }}>
                            Search or Downloads
                          </TableCell>
                          <TableCell size="small" style={{ textAlign: 'right' }}>
                            Users
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{ height: 'auto' }}>
                          <TableCell
                            size="small"
                            style={{
                              borderBottom: 0,
                              fontWeight: 'bold',
                            }}>
                            0
                          </TableCell>
                          <TableCell
                            size="small"
                            style={{
                              borderBottom: 0,
                              fontWeight: 'bold',
                              textAlign: 'right',
                            }}>
                            {users.amount - stats.data?.activity?.activeUsers?.['1-4'] - stats.data?.activity?.activeUsers?.['>5']}
                          </TableCell>
                        </TableRow>
                        <TableRow style={{ height: 'auto' }}>
                          <TableCell size="small" style={{ borderBottom: 0, fontWeight: 'bold' }}>
                            1-4
                          </TableCell>
                          <TableCell
                            size="small"
                            style={{
                              borderBottom: 0,
                              fontWeight: 'bold',
                              textAlign: 'right',
                            }}>
                            {stats.data?.activity?.activeUsers?.['1-4']}
                          </TableCell>
                        </TableRow>
                        <TableRow />
                        <TableRow style={{ height: 40 }}>
                          <TableCell
                            style={{
                              borderBottom: 0,
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              backgroundColor: '#f7f7f5',
                              borderRadius: '2px 0 0 2px',
                            }}>
                            {'>'}5
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: 0,
                              fontWeight: 'bold',
                              textAlign: 'right',
                              fontSize: '1.2rem',
                              backgroundColor: '#f7f7f5',
                              borderRadius: '0 2px 2px 0',
                            }}>
                            {stats.data?.activity?.activeUsers?.['>5']}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                )}
              </Card>
            </Grid>
            <Grid item xs={4} className={classes.toolbarGridItem} ref={test}>
              <Card elevation={0} className={classes.card}>
                {!stats.data.activity?.usageEvents ? (
                  <CircularProgress variant="indeterminate" color={'primary'} size={20} />
                ) : (
                  TrandCard('SEARCHES', {
                    amount: stats.data.activity.usageEvents.searches || 0,
                  })
                )}
              </Card>
            </Grid>
            <Button style={{ width: '100%' }} onClick={() => setShowDetails(!showDetails)}>
              {!showDetails ? 'Get more details' : 'Hide details'}
            </Button>
            {stats.isLoading && !stats.data.top && <CircularProgress variant="indeterminate" color={'primary'} size={20} />}
            {showDetails && (
              <>
                <Grid item xs={4} className={classes.toolbarGridItem} ref={test}>
                  <Card elevation={0} className={classes.card}>
                    {!stats.data.top?.downloads ? (
                      <CircularProgress variant="indeterminate" color={'primary'} size={20} />
                    ) : (
                      ListCard('TOP 5 INSERTED IMAGES', stats.data.top.downloads)
                    )}
                  </Card>
                </Grid>
                <Grid item xs={4} className={classes.toolbarGridItem} ref={test}>
                  <Card elevation={0} className={classes.card}>
                    {!stats.data.top?.keywords ? <Loader dataTitle={'TOP 5 KEYWORDS'} /> : ListCard('TOP 5 KEYWORDS', stats.data.top.keywords)}
                  </Card>
                </Grid>
                <Grid item xs={4} className={classes.toolbarGridItem} ref={test}>
                  <Card elevation={0} className={classes.card}>
                    {!stats.data.top?.collections ? (
                      <CircularProgress variant="indeterminate" color={'primary'} size={20} />
                    ) : (
                      ListCard('TOP 5 COLLECTIONS', stats.data.top.collections)
                    )}
                  </Card>
                </Grid>
                <Grid item xs={4} className={classes.toolbarGridItem} ref={test}>
                  <Card elevation={0} className={classes.card}>
                    {users === undefined ? <Loader dataTitle={'USERS'} /> : TrandCard('USERS', users)}
                  </Card>
                </Grid>
                {/* <Grid item xs={4} className={classes.toolbarGridItem} ref={test}>
                    <Card elevation={0} className={classes.card}>
                      {statistic.app_started === undefined ? (
                        <Loader dataTitle={'APPLICATION STARTED'} />
                      ) : (
                        TrandCard('APPLICATION STARTED', statistic.app_started)
                      )}
                    </Card>
                  </Grid> */}
              </>
            )}
            <Grid item xs={4} className={classes.toolbarGridItem}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

CustomerStatistic.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerStatistic);

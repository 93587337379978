import React from 'react';
import { withStyles, Grid, Paper, Typography, LinearProgress } from '@material-ui/core';

import PropTypes from 'prop-types';

const gridSpacing = 2;

const styles = (theme) => ({
  root: {
    marginTop: 40,
    paddingBottom: 50,
  },
  grid: {
    margin: 0,
    padding: 5,
  },
  paperLoader: {
    minHeight: '35vh',
    paddingTop: '25vh',
    background: 'transparent',
    boxShadow: 'none',
  },
});
function LoadingScreen(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Paper elevation={2} square className={classes.paperLoader}>
            <Typography variant={'h6'} color={'primary'} align={'center'}>
              {props.loadingMessage}
            </Typography>
            <br />
            <Grid container spacing={gridSpacing} alignItems={'center'}>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <LinearProgress mode="indeterminate" color={'primary'} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

LoadingScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  loadingMessage: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(LoadingScreen);

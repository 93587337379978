import React from 'react';
import Auth from 'PickitAPI/Auth';
import { isBefore } from 'date-fns';

export default function useTokenManagement() {
  let api_host = 'https://' + window.location.hostname.replace(/portal\./, 'proxy.');
  const url = api_host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : api_host;

  const [isLoading, setIsLoading] = React.useState(false);
  const [tokenChecked, setTokenChecked] = React.useState(false);

  const waitForToken = () => {
    return new Promise((resolve, reject) => {
      let checkExist = setInterval(() => {
        if (!isLoading) {
          resolve(true);
          clearInterval(checkExist);
        }
      }, 100);
    });
  };

  const rotateToken = async () => {
    const djangoProfile = Auth.getProfile();
    const token = Auth.getUser();
    if (isLoading) {
      return waitForToken();
    }
    if (!token?.jwt) {
      return false;
    }
    try {
      setIsLoading(true);

      // TODO: Change this to "proxy" when prototype is ready.
      let requestUrl = url;
      if (requestUrl.indexOf('/api') === -1) {
        requestUrl += '/api'
      }
      let data = await fetch(requestUrl + '/rotate-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'pickit-api-key': '23bc1999-ae93-4bd1-acc7-641dbf27e7b9',
        },
        body: JSON.stringify({
          refresh_token: token.jwt_refresh_token,
          rotate: 'jwt_refresh_token',
          user: {
            user_id: djangoProfile.id,
          },
        }),
      });
      if (!data.ok || data.status === 404) {
        throw data;
      }
      let newToken = await data.json();
      setIsLoading(false);
      token.jwt = newToken.jwt;
      token.simple_token = newToken.simple_token;
      Auth.authenticate(token);
      setTokenChecked(true);
      return token;
    } catch (e) {
      localStorage.removeItem('user');
      localStorage.removeItem('profile');
      localStorage.clear();
      window.location = '/';
      console.error('Unable to refresh token');
      setTokenChecked(true);
    }
  };
  const checkToken = async () => {
    if (isLoading) {
      return waitForToken();
    }
    const user = Auth.getUser();
    if (!user) {
      setTokenChecked(true);
      return Auth.deauthenticate();
    }
    const tokenExpired = isBefore(new Date(user.oauth2['Pickit AddIn'].access_token_expires), new Date());
    const rotateExpired = isBefore(new Date(user.oauth2['Pickit AddIn'].refresh_token_expires), new Date());

    if (rotateExpired) {
      setTokenChecked(true);
      return Auth.deauthenticate();
    }
    if (tokenExpired) {
      await rotateToken();
    }
    setTokenChecked(true);
  };

  const testToken = async (tokenTested) => {
    if (isLoading) {
      return waitForToken();
    }
    const user = Auth.getUser();
    if (!user) {
      setTokenChecked(true);
      return Auth.deauthenticate();
    }
    const token = JSON.parse(localStorage.getItem('user') || '{}')?.jwt;
    const req = await fetch(url + '/api/profile', {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    req.json();
    if (!req.ok) {
      if (tokenTested) {
        setTokenChecked(true);
        return Auth.deauthenticate();
      }
      await rotateToken();
      return testToken(true);
    }

    setTokenChecked(true);
  };
  return {
    isLoading,
    checkToken,
    tokenChecked,
    rotateToken,
    testToken,
  };
}

class Auth {
  static authenticate(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static saveProfile(profile) {
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  static getProfile() {
    if (localStorage.getItem('profile')) {
      try {
        return JSON.parse(localStorage.getItem('profile'));
      } catch (e) {
        localStorage.removeItem('profile');
      }
      return false;
    }
  }

  static deauthenticate() {
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.clear();
    window.location = '/';
  }

  static isAuthenticated() {
    return !!localStorage.getItem('user');
  }

  static getUser() {
    if (localStorage.getItem('user')) {
      try {
        return JSON.parse(localStorage.getItem('user'));
      } catch (e) {
        localStorage.removeItem('user');
      }
      return false;
    }
  }
}

export default Auth;

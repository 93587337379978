import React, { useEffect } from 'react';
import { clone } from 'lodash';
import useRequest from './useRequest';

export default function usePresets() {
  let api_host = 'https://' + window.location.hostname.replace(/portal\./, 'proxy.');
  const url =
    (api_host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : api_host) + '/policy/preset';

  const [isLoading, setIsLoading] = React.useState(true);
  const request = useRequest();
  const [data, setData] = React.useReducer((state, action) => {
    const newState = clone(state);
    if (action.replace) {
      newState[action.key] = action.data;
      newState[action.key + '_replaced_at'] = new Date();
    }
    if (action.delete) {
      delete newState[action.key];
    }
    return { ...newState, presetIds: newState.presets.map((preset) => preset.preset_id) };
  }, []);
  useEffect(() => {
    getPresets();
    getPresetServices();
  }, []);

  async function getPresets() {
    setIsLoading(true);
    setData({
      replace: true,
      key: 'presets',
      data: [],
    });
    try {
      const res = await request(url);
      setData({
        replace: true,
        key: 'presets',
        data: res.data,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  async function createPreset(body) {
    setIsLoading(true);
    try {
      const res = await request(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setData({
        replace: true,
        key: 'presets',
        data: [...data.presets, res.data],
      });
      setIsLoading(false);
      return res.data;
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  async function updatePreset(body) {
    setIsLoading(true);
    try {
      const res = await request(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const newData = data.presets.map((preset) => {
        return preset.preset_id === res.data.preset_id ? res.data : preset;
      });
      setData({
        replace: true,
        key: 'presets',
        data: [...newData],
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  async function deletePreset(body) {
    setIsLoading(true);
    try {
      const res = await request(url, {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setData({
        replace: true,
        key: 'presets',
        data: res.data,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  async function getPresetServices() {
    setIsLoading(true);
    setData({
      replace: true,
      key: 'services',
      data: undefined,
    });
    try {
      const res = await request(`${url}/service`);
      const data = res.data;
      data.services = data.services.sort((a, b) => a.order - b.order);
      data.integrations = data.integrations.sort((a, b) => a.order - b.order);
      data.features = data.features.sort((a, b) => a.order - b.order);
      setData({
        replace: true,
        key: 'services',
        data: data,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  async function createPresetService(body) {
    setIsLoading(true);
    try {
      const res = await request(`${url}/service`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const newData = data.services;
      newData[body.group] = [...newData[body.group], res.data];
      setData({
        replace: true,
        key: 'services',
        data: newData,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }
  async function updatePresetService(body) {
    setIsLoading(true);
    try {
      const res = await request(`${url}/service`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const newServices = clone(data.services);
      const group = data.services[body.group];
      newServices[body.group] = group.map((service) => {
        return service.service_id === res.data.service_id ? res.data : service;
      });

      setData({
        replace: true,
        key: 'services',
        data: newServices,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  async function deletePresetService(body) {
    setIsLoading(true);
    try {
      const res = await request(`${url}/service`, {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setData({
        replace: true,
        key: 'services',
        data: res.data,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }

  const checkPresetExistance = (preset_id) => data?.presetIds?.find((item) => item === preset_id);

  return {
    data,
    isLoading,
    getPresets,
    createPreset,
    updatePreset,
    deletePreset,
    getPresetServices,
    createPresetService,
    updatePresetService,
    deletePresetService,
    checkPresetExistance,
  };
}

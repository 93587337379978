import { inOffice, inTeams, isIE11, isEdge } from './platform';

export default class Dialog {
  size = '';
  url = '';
  constructor(url, width, height) {
    this.url = url;
    this.size = this._optimizeSize(width, height);
  }
  _windowFeatures = ',menubar=no,toolbar=no,location=no,resizable=yes,scrollbars=yes,status=no';
  key = 'VGVtcG9yYXJ5S2V5Rm9yT0pIQXV0aA==';

  open(callback, errorCallback) {
    if (inOffice() && !inTeams()) {
      window.Office.context.ui.displayDialogAsync(this.url, { width: this.size.width$, height: this.size.height$ }, (result) => {
        let dialog = result.value;
        if (result.status === window.Office.AsyncResultStatus.Failed) {
          errorCallback(new Error(result.error.message, result.error));
          dialog.close();
        } else {
          dialog.addEventHandler(window.Office.EventType.DialogMessageReceived, (args) => {
            let result = this._safeParse(args.message);
            callback(result);
            dialog.close();
          });

          dialog.addEventHandler(window.Office.EventType.DialogEventReceived, (args) => {
            errorCallback(new Error(args.message, args.error));
            dialog.close();
          });
        }
      });
    } else if (inTeams()) {
      window.microsoftTeams.initialize();
      window.microsoftTeams.authentication.authenticate({
        url: this.url,
        width: this.size.width,
        height: this.size.height,
        failureCallback: (exception) => (errorCallback ? errorCallback(new Error('Error while launching dialog', exception)) : ''),
        successCallback: (message) => callback(this._safeParse(message)),
      });
    } else {
      try {
        const options = 'width=' + this.size.width + ',height=' + this.size.height + this._windowFeatures;
        window.open(this.url, this.url, options);
        if (isIE11 || isEdge) {
          this._pollLocalStorageForToken(callback, errorCallback);
        } else {
          const handler = (event) => {
            if (event.origin === window.location.origin && event.data.source !== 'react-devtools-bridge') {
              callback(this._safeParse(event.data));
            }
          };
          window.addEventListener('message', handler);
        }
        // win.onbeforeunload = function() {
        //   errorCallback();
        //   window.removeEventListener('message', handler);
        // };
      } catch (exception) {
        if (errorCallback) {
          return errorCallback(new Error('Unexpected error occurred while creating popup', exception));
        }
      }
    }
  }

  _pollLocalStorageForToken(resolve, reject) {
    localStorage.removeItem(this.key);
    const POLL_INTERVAL = 400;
    let interval = setInterval(() => {
      try {
        const data = localStorage.getItem(this.key);
        if (!(data == null)) {
          clearInterval(interval);
          localStorage.removeItem(this.key);
          return resolve(this._safeParse(data));
        }
      } catch (exception) {
        clearInterval(interval);
        localStorage.removeItem(this.key);
        return reject(new Error('Unexpected error occurred in the dialog', exception));
      }
    }, POLL_INTERVAL);
  }

  _safeParse(data) {
    try {
      let result = JSON.parse(data);

      if (result.parse) {
        return this._safeParse(result.value);
      }
      return result.value;
    } catch (_e) {
      return data;
    }
  }

  _optimizeSize(desiredWidth, desiredHeight) {
    const { width: screenWidth, height: screenHeight } = window.screen;

    const width = this._maxSize(desiredWidth, screenWidth);
    const height = this._maxSize(desiredHeight, screenHeight);
    const width$ = this._percentage(width, screenWidth);
    const height$ = this._percentage(height, screenHeight);

    return { width$, height$, width, height };
  }
  _maxSize(value, max) {
    return value < max - 30 ? value : max - 30;
  }
  _percentage(value, max) {
    return (value * 100) / max;
  }
}

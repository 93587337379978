import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  Tabs,
  Tab,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { PortalDataContext } from 'Contexts';
import { CustomerManagement as CustomerManagementContext } from 'Contexts/CustomerManagement';
import { CustomerInfo, CustomerConfiguration, CustomerDashboard, CustomerStatistic, Snackbar, CustomerUsers } from 'Components';
import CustomerInsight from 'Components/Customer/CustomerInsight';
import { ErrorScreen, LoadingScreen } from 'Components';

import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';

const gridSpacing = 4;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
  },
  cacheMessage: {
    textAlign: 'right',
  },
  toolbar: {
    backgroundColor: '#B271AF',
    color: '#ffffff',
  },
  paperInfo: {
    height: '100%',
    minHeight: 1100,
  },
  paperLoader: {
    minHeight: '35vh',
    paddingTop: '25vh',
    marginBottom: 50,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tab: {
    width: '20.00%',
    maxWidth: 'none',
    height: 70,
    fontSize: '1rem',
    textTransform: 'none',
  },
  tabHalf: {
    width: '50%',
    maxWidth: 'none',
    height: 70,
    fontSize: '1rem',
    textTransform: 'none',
  },
  tabs: {
    height: 70,
    '& > div > div > span': {
      height: 4,
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  saveBox: {
    marginTop: theme.spacing(4),
  },
  undo: {
    marginTop: theme.spacing(1),
  },
});
function Customer(props) {
  const context = useContext(PortalDataContext);
  const CustomerManagement = useContext(CustomerManagementContext);
  const {
    initCustomer,
    handleCustomerSave,
    isSaving,
    handleSuspendCustomer,
    handleDeleteCustomer,
    customer,
    hasChanges,
    resetCustomer,
    resetContext,
  } = CustomerManagement;
  const { selectedCustomer } = context;

  const { classes } = props;
  const [tabIndex, setTabIndex] = useState(0);

  const [loader, setLoader] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Getting customer...');

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState(undefined);

  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [dialogSuspendOpen, setDialogSuspendOpen] = useState(false);
  const [seenNewSave, setSeenNewSave] = useState(localStorage.getItem('seenNewSave'));

  useEffect(() => {
    props.pass.setCounter(undefined);
    setActiveCustomer().catch(console.error);
  }, []);

  useEffect(() => {
    if (hasChanges) {
      setTimeout(() => {
        setSeenNewSave('seen');
        localStorage.setItem('seenNewSave', 'seen');
      }, 10000);
    }
  }, [hasChanges]);

  useEffect(() => {
    if (selectedCustomer && props.route.match.params.id === selectedCustomer?.customer?.tenant_id) {
      initManagementContext();
    }
  }, [selectedCustomer?.customer?.tenant_id, selectedCustomer?.customer?.status]);
  const initManagementContext = () => {
    resetContext();
    initCustomer(selectedCustomer);
  };

  const setActiveCustomer = async () => {
    const { setSelectedCustomer } = context;
    try {
      await setSelectedCustomer(props.route.match.params.id);
      setLoader(false);
    } catch (e) {
      console.error(e);
      setError(error);
      setErrorMessage(e);
    }
  };

  const handleLoader = (loader, message) => {
    setLoader(loader);
    setLoadingMessage(message);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  // These were moved to the top level component because designs keep changing the location of the suspend and delete options
  // between both CustomerConfigure and CustomerDashboard so keeping them here and passing them down will make itearation faster
  const handleOpenSuspend = () => {
    setDialogSuspendOpen(true);
  };

  const handleOpenDelete = () => {
    setDialogDeleteOpen(true);
  };
  const handleDialogClose = () => {
    if (dialogDeleteOpen) {
      setDialogDeleteOpen(false);
    }
    if (dialogSuspendOpen) {
      setDialogSuspendOpen(false);
    }
  };

  if (error) {
    return <ErrorScreen errorMessage={errorMessage} />;
  }

  if (!customer || loader) {
    return <LoadingScreen loadingMessage={loadingMessage} />;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing} justifyContent={'center'}>
        <Grid item md={11} lg={4}>
          <CustomerInfo {...{ ...props.pass, handleSnackbar, handleLoader }} />

          <Box className={classes.saveBox}>
            {!props.pass.isReadOnly() && (
              <Tooltip
                title={<Typography variant="subtitle1">All changes in any section are now saved here!</Typography>}
                placement="top"
                open={!!hasChanges && !seenNewSave}
                arrow
                aria-label="save prompt">
                <Button
                  disabled={isSaving || !hasChanges}
                  variant={'contained'}
                  size="large"
                  color={'primary'}
                  type={'button'}
                  className={hasChanges && !seenNewSave ? 'pulse' : ''}
                  fullWidth
                  onClick={async () => {
                    try {
                      const customer = await handleCustomerSave({ repairCustomer: props.pass.isStaff() });
                      context.updateCustomer(customer);
                      handleSnackbar('Customer Saved', 'success');
                      localStorage.setItem('seenNewSave', 'seen');
                    } catch (e) {
                      console.error(e.message);
                      handleSnackbar('Something went wrong! ' + e.message, 'error');
                    }
                  }}>
                  {isSaving ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <SaveIcon className={classes.extendedIcon} /> Save
                    </>
                  )}
                </Button>
              </Tooltip>
            )}
            <Button
              variant={'contained'}
              disabled={isSaving || !hasChanges}
              size="large"
              color="secondary"
              type={'button'}
              fullWidth
              onClick={() => {
                resetCustomer();
              }}
              className={classes.undo}>
              <UndoIcon className={classes.extendedIcon} /> Reset
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} lg={8}>
          {!props.pass.isReadOnly() ? (
            <Paper elevation={2} square>
              <Tabs indicatorColor={'primary'} onChange={(event, value) => setTabIndex(value)} value={tabIndex} className={classes.tabs} variant="scrollable">
                <Tab label={'Information'} className={classes.tab} />
                {customer.customer.status.toLowerCase() !== 'deleted' && <Tab label={'Configuration'} className={classes.tab} />}
                {props.pass.isStaff() && <Tab label={'Users'} className={classes.tab}/>}
                <Tab label={'Statistics'} className={classes.tab} />
                <Tab label={'Insights'} className={classes.tab} />
              </Tabs>
            </Paper>
          ) : (
            <Paper elevation={2} square>
              <Tabs indicatorColor={'primary'} onChange={(event, value) => setTabIndex(value)} value={tabIndex} className={classes.tabs} variant="scrollable">
                <Tab label={'Information'} className={classes.tabHalf} />
                {props.pass.isStaff() && <Tab label={'Users'} className={classes.tabHalf} />}
                <Tab label={'Statistics'} className={classes.tabHalf} />
                <Tab label={'Insights'} className={classes.tab} />
              </Tabs>
            </Paper>
          )}

          {tabIndex === 0 && customer.customer.status.toLowerCase() !== 'deleted' && (
            <CustomerDashboard handleLoader={handleLoader} route={props.route} pass={props.pass} {...{ handleSnackbar }} />
          )}
          {tabIndex === 1 && !props.pass.isReadOnly() && customer.customer.status.toLowerCase() !== 'deleted' && (
            <CustomerConfiguration
              pass={props.pass}
              getUserCommunitySlug={props.pass.getUserCommunitySlug}
              {...{ handleOpenDelete, handleOpenSuspend, handleSnackbar, handleLoader }}
            />
          )}
          {(tabIndex === 2 || (tabIndex === 1 && props.pass.isReadOnly())) && props.pass.isStaff() && (
            <CustomerUsers
              handleLoader={handleLoader}
              route={props.route}
              customer={customer}
              isStaff={props.pass.isStaff}
              {...{ handleSnackbar }}
            />
          )}
          {((tabIndex === 3 && props.pass.isStaff()) || (tabIndex === 1 && props.pass.isReadOnly()) || (tabIndex === 2 && !props.pass.isStaff())) && <CustomerStatistic handleLoader={handleLoader} route={props.route} />}
          {(tabIndex === 4 || (tabIndex === 1 && props.pass.isReadOnly()) || (tabIndex === 3 && !props.pass.isStaff())) && <CustomerInsight handleLoader={handleLoader} route={props.route} community={selectedCustomer}/>}
        </Grid>
      </Grid>
      <Snackbar
        severity={snackbarSeverity}
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <br />
      <br />
      <Dialog
        open={dialogSuspendOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.squarePaperOverride}>
        <DialogTitle id="alert-dialog-title">{customer.customer.status.toLowerCase() !== 'suspended' ? 'Suspend' : 'Activate'}</DialogTitle>
        <DialogContent>
          {customer.customer.status.toLowerCase() !== 'suspended' ? (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to suspend this customer, by doing so all users associated to image bank will lose access.
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to activate this customer, by doing so all users associated to image bank will gain access.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} onClick={handleDialogClose} color="primary" autoFocus>
            Cancel
          </Button>

          <Button
            variant={'text'}
            onClick={(event) => {
              const newStatus = customer.customer.status.toLowerCase() !== 'suspended' ? 'suspended' : 'trialexpired';
              handleSuspendCustomer(newStatus)
                .then((response) => {
                  context.updateCustomer({ ...response });
                  setTabIndex(0);
                  handleSnackbar(newStatus === 'suspended' ? 'Customer Suspended' : 'Customer Activated', 'success');
                  handleDialogClose();
                })
                .catch((e) => {
                  console.error(e);
                  handleSnackbar('Something went wrong. ' + e.statusText?.toString(), 'error');
                  handleDialogClose();
                });
            }}
            color="primary">
            {customer.customer.status.toLowerCase() !== 'suspended' ? 'Suspend' : 'Activate'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogDeleteOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.squarePaperOverride}>
        <DialogTitle id="alert-dialog-title">{'Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this customer, this action is not reversable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} onClick={handleDialogClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            variant={'text'}
            onClick={(event) => {
              handleDeleteCustomer()
                .then(() => {
                  handleSnackbar('Customer deleted', 'success');
                  props.route.history.push('/customers' + (props.pass.testMode() ? '?test=true' : ''));
                  context.removeCusotmer(selectedCustomer.customer.tenant_id);
                  handleDialogClose();
                })
                .catch((e) => {
                  console.error(e);

                  handleSnackbar('Something went wrong. ' + e.statusText?.toString(), 'error');
                  handleDialogClose();
                });
            }}
            color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Customer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Customer);

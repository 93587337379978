import { IconButton, Snackbar as MuiSnackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
//import CloseIcon from '@material-ui/icons/Close';
import CloseIcon from '@mui/icons-material/Close';  


export default function Snackbar(props) {
  const { severity, message, open, onClose } = props;
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      severity="success"
      message={severity ? undefined : message}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>,
      ]}>
      {severity && <Alert severity={severity}>{message}</Alert>}
    </MuiSnackbar>
  );
}

import PickitAPI from './index';

let api_host = 'https://' + window.location.hostname.replace(/portal\./, 'proxy.');
const baseUrl = (api_host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : api_host) + '/api';

class SalesUIPartner extends PickitAPI {
  static get users() {
    let _self = this;

    return {
      getAll: async function (tenant_id, parent, slug, page_nr = 1, prevResult) {
        /*
         * Get users list for exact partner community
         *
         *
         *
         * */

        let url = baseUrl + '/portal/partners/users?partner=' + tenant_id;
        if (parent !== undefined) {
          url += '&parent=' + parent;
        }
        if (page_nr !== undefined) {
          url += '&page_nr=' + page_nr;
        }
        url += '&page_limit=100';
        let headers = await _self.getHeaders(slug);
        let users = await _self.request(url, {
          headers: headers,
        });

        let response = undefined;
        try {
          response = await users.json();
        } catch (e) {
          console.error(e);

          return [];
        }
        const result = prevResult ? prevResult.concat(response.data) : response.data;
        if (response.total_count > result.length && response.data.length > 0) {
          return this.getAll(tenant_id, parent, slug, page_nr + 1, result);
        } else {
          return { total: response.total_count, data: result };
        }
      },
      put: async function (put_data, slug) {
        /*
         * Update user
         *
         * */
        let url = baseUrl + '/portal/partners/users';
        let headers = await _self.getHeaders(slug);
        let response_data = await _self.request(url, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(put_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      post: async function (post_data, slug) {
        /*
         * Create new users
         *
         * */
        let url = baseUrl + '/portal/partners/users';
        let headers = await _self.getHeaders(slug);
        let response_data = await _self.request(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(post_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
    };
  }

  static get partners() {
    let _self = this;

    return {
      getAll: async function (testMode, page = 1, prevResult) {
        /*
         * Get partners list
         *
         * */
        let url = baseUrl + `/portal/partners?page_limit=100&page_nr=${page}`;

        if (testMode) {
          url += '&show_test=1';
        }
        let partners = undefined;
        let headers = await _self.getHeaders();
        try {
          partners = await _self.request(url, {
            headers: headers,
          });
        } catch (e) {
          console.error(e);
        }

        let response = undefined;
        try {
          response = await partners.json();
        } catch (e) {
          console.error(e);

          return [];
        }
        const result = prevResult ? prevResult.concat(response.data) : response.data;
        if (response.total_count > result.length) {
          return this.getAll(testMode, page + 1, result);
        } else {
          return { total: response.total_count, data: result };
        }
      },
      getFiltered: async function (params, testMode, overrideSlug) {
        /*
         * Get partners list
         *
         * */
        let url = baseUrl + '/portal/partners?' + _self.stringifyParams(params);

        if (testMode) {
          url += '&show_test=1';
        }
        let headers = await _self.getHeaders(overrideSlug);
        let partners = await _self.request(url, {
          headers: headers,
        });

        try {
          return partners.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      /**
       *
       * @param {string uuid} tenant_id
       * @param {string uuid} parent
       * @param {string}
       * @param {bool} testMode
       */
      getOne: async function (tenant_id, parent, testMode) {
        /*
         * Get partner
         *
         * */
        let url = baseUrl + '/portal/partners?partner=' + tenant_id;
        if (parent !== undefined && tenant_id !== parent) {
          url += '&parent=' + parent;
        }

        if (testMode) {
          url += '&show_test=1';
        }
        let headers = await _self.getHeaders();
        let partner = await _self.request(url, {
          headers: headers,
        });

        try {
          return partner.json();
        } catch (e) {
          console.error(e);

          return [];
        }
      },
      putCommunityContentFilterSettings: async function (put_data) {
        /*
         * Update Community Content Filter Settings
         *
         * */
        let headers = await _self.getHeaders();
        let response_data = await _self.request(baseUrl + '/ibc/market/settings/content-filter/', {
          headers: headers,
          method: 'PUT',
          body: JSON.stringify(put_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      putCommunityMarketVisibilitySettings: async function (put_data) {
        /*
         * Update Community Content Filter Settings
         *
         * */
        let headers = await _self.getHeaders();
        let response_data = await _self.request(baseUrl + '/ibc/market/settings/permission/', {
          headers: headers,
          method: 'PUT',
          body: JSON.stringify(put_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      putCuratorAccessSettings: async function (put_data) {
        /*
         * Update Curator access
         *
         * */
        let headers = await _self.getHeaders();
        let response_data = await _self.request(baseUrl + '/ibc/market/settings/curator/', {
          headers: headers,
          method: 'PUT',
          body: JSON.stringify(put_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      putNewPasswordNotification: async function (put_data, slug) {
        /*
         * Send new password to community owner
         *
         * */
        let headers = await _self.getHeaders(slug);
        let response_data = await _self.request(baseUrl + '/portal/partners/users/', {
          headers: headers,
          method: 'PUT',
          body: JSON.stringify(put_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      getCommunityMarketVisibilitySettings: async function (get_data) {
        /*
         * Get Community Market Visibility Settings
         *
         * */
        let headers = await _self.getHeaders();
        let response_data = await _self.request(
          baseUrl + '/ibc/market/settings/permission/?partner=' + get_data.partner + '&customer=' + get_data.customer,
          {
            headers: headers,
            method: 'GET',
          }
        );

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      getUsers: async function (get_data) {
        /*
         * Get Community Users
         *
         * */
        let headers = await _self.getHeaders();
        let response_data = await _self.request(baseUrl + '/ibc/users/?partner=' + get_data.partner + '&customer=' + get_data.customer, {
          headers: headers,
          method: 'GET',
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      post: async function (post_data) {
        /*
         * Create new partner
         *
         * */
        let url = baseUrl + '/portal/partners';
        let headers = await _self.getHeaders();
        let response_data = await _self.request(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(post_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
      put: async function (put_data, partner) {
        /*
         * Update partner
         *
         * */
        let url = baseUrl + '/portal/partners';

        if (partner !== undefined && partner !== '') {
          url += '?partner=' + partner;
        }
        let headers = await _self.getHeaders();
        let response_data = await _self.request(url, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(put_data),
        });

        try {
          return response_data.json();
        } catch (e) {
          console.error(e);
        }
      },
    };
  }
}

export default SalesUIPartner;

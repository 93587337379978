import { Box, Grid, List, Typography, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from 'Components';
import React, { useEffect, useReducer, useState } from 'react';
import { ServiceEditField, ServiceField } from '.';

const styles = (theme) => ({
  root: {},
});

const Services = (props) => {
  const { preset, services, onChange, edit, disabled, accordion, style } = props;
  const [change, setChange] = useState(undefined);

  const usedServices = preset?.services || services;

  const hiddenKeys = ['getty_api_key', 'getty_api_secret'];
  const [localServices, setLocalServices] = useReducer((state, action) => {
    const { key, service, value, initialOrder } = action;
    if (key === 'replace') return value;

    const firstMoveMutation = service.order === initialOrder;

    let { group } = service;

    if (!group) group = service.path.split('.')[1];

    const serviceKey = service.key || service.slug;
    const changedServices = [];
    let previousEntry;

    if (key === 'group') {
      let highestOrder = state[value].reduce((highest, item) => (item.order >= highest ? item.order + 1 : highest), 0);
      state[group] = state[group].filter((item) => item.key === serviceKey);
      service.order = highestOrder;
      service.group = value;
      if (!state[value].find((item) => item.service_id === service.service_id)) {
        state[value].push(service);
      }
      changedServices.push({ service, action: 'change' });
    } else {
      state[group] = state[group]
        .sort((a, b) => a.order - b.order)
        .filter((feature) => !hiddenKeys.find((key) => key === (feature.key || feature.slug)))
        .map((service) => {
          let actionTaken = 'change';
          const identifier = service.slug || service.key;
          switch (key) {
            case 'moveUp':
              if (identifier === serviceKey && previousEntry && firstMoveMutation) {
                const order = service.order;
                service.order = previousEntry.order;
                previousEntry.order = order;
                changedServices.push({ service: previousEntry, action: actionTaken });
                changedServices.push({ service, action: actionTaken });
              }
              previousEntry = service;

              break;
            case 'moveDown':
              if (previousEntry && firstMoveMutation && changedServices.length <= 0) {
                const order = service.order;
                service.order = previousEntry.order;
                previousEntry.order = order;
                changedServices.push({ service: previousEntry, action: actionTaken });
                changedServices.push({ service, action: actionTaken });
              }
              if (identifier === serviceKey) {
                previousEntry = service;
              }
              break;
            case 'delete':
              actionTaken = 'delete';
              if (identifier === serviceKey) {
                service.hidden = true;
                changedServices.push({ service, action: actionTaken });
              }
              break;
            default:
              if (identifier === serviceKey) {
                service[key] = value;
                changedServices.push({ service, action: actionTaken });
              }
              break;
          }

          return service;
        });
    }
    if (!!onChange && changedServices.length > 0 && !change) {
      setChange(changedServices);
    }
    return { ...state };
  }, usedServices);
  useEffect(() => {
    if (!!onChange && change) {
      onChange(localServices, change);
      setChange(undefined);
    }
  }, [change]);

  useEffect(() => {
    if (usedServices) {
      setLocalServices({ key: 'replace', value: usedServices });
    }
  }, [usedServices]);

  if (!localServices) {
    return <></>;
  }

  return (
    <Box flexBasis="100%" style={style}>
      <Wrapper title={'Services'} accordion={accordion} disabled={localServices?.services?.length === 0}>
        {edit ? (
          <List>
            {localServices.services &&
              localServices.services
                .sort((a, b) => a.order - b.order)
                .filter((service) => !hiddenKeys.find((key) => key === (service.key || service.slug)))
                .map((service, index, array) => (
                  <ServiceEditField
                    service={service}
                    key={service.path}
                    onChange={setLocalServices}
                    firstOption={index === 0}
                    lastOption={array.length === 1 || index === array.length - 1}
                    disabled={disabled}
                    variant={index % 2 === 0 ? undefined : 'alternate'}
                  />
                ))}
          </List>
        ) : (
          <Grid container spacing={1}>
            {localServices.services &&
              localServices.services
                .sort((a, b) => a.order - b.order)
                .filter((service) => !hiddenKeys.find((key) => key === (service.key || service.slug)))
                .map((service, index, array) => (
                  <ServiceField
                    service={service}
                    onChange={(value) => setLocalServices({ service, value, key: 'value' })}
                    key={service.path}
                    disabled={disabled}
                  />
                ))}
          </Grid>
        )}
      </Wrapper>
      <Wrapper title={'Features'} accordion={accordion} disabled={localServices?.features?.length === 0}>
        {edit ? (
          <List>
            {localServices.features &&
              localServices.features
                .sort((a, b) => a.order - b.order)
                .filter((feature) => !hiddenKeys.find((key) => key === (feature.key || feature.slug)))
                .map((feature, index, array) => (
                  <ServiceEditField
                    service={feature}
                    key={feature.path}
                    onChange={setLocalServices}
                    firstOption={index === 0}
                    lastOption={array.length === 1 || index === array.length - 1}
                    disabled={disabled}
                    variant={index % 2 === 0 ? undefined : 'alternate'}
                  />
                ))}
          </List>
        ) : (
          <Grid container spacing={1}>
            {localServices.features &&
              localServices.features
                .sort((a, b) => a.order - b.order)
                .filter((feature) => !hiddenKeys.find((key) => key === (feature.key || feature.slug)))
                .map((feature, index, array) => (
                  <ServiceField
                    service={feature}
                    onChange={(value) => setLocalServices({ service: feature, value, key: 'value' })}
                    key={feature.path}
                    disabled={disabled}
                  />
                ))}
          </Grid>
        )}
      </Wrapper>
      <Wrapper title={'Integrations'} accordion={accordion} disabled={localServices?.integrations?.length === 0}>
        {edit ? (
          <List>
            {localServices.integrations &&
              localServices.integrations
                .sort((a, b) => a.order - b.order)
                .filter((integration) => !hiddenKeys.find((key) => key === (integration.key || integration.slug)))
                .map((integration, index, array) => {
                  return (
                    <ServiceEditField
                      service={integration}
                      key={integration.path}
                      onChange={setLocalServices}
                      firstOption={index === 0}
                      lastOption={array.length === 1 || index === array.length - 1}
                      disabled={disabled}
                      variant={index % 2 === 0 ? undefined : 'alternate'}
                    />
                  );
                })}
          </List>
        ) : (
          <Grid container spacing={1}>
            {localServices.integrations &&
              localServices.integrations
                .sort((a, b) => a.order - b.order)
                .filter((integration) => !hiddenKeys.find((key) => key === (integration.key || integration.slug)))
                .map((integration, index, array) => (
                  <ServiceField
                    service={integration}
                    onChange={(value) => setLocalServices({ service: integration, value, key: 'value' })}
                    key={integration.path}
                    disabled={disabled}
                  />
                ))}
          </Grid>
        )}
      </Wrapper>
    </Box>
  );
};
const Wrapper = (props) => {
  const { children, accordion, title, disabled } = props;
  if (accordion) {
    return (
      <Accordion square style={{ width: '100%', flexGrow: 1 }} disabled={disabled}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>{children}</AccordionDetails>
      </Accordion>
    );
  }
  return (
    <>
      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      {children}
    </>
  );
};
export default withStyles(styles, { withTheme: true })(Services);

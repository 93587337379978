import React, { useState, useEffect } from 'react';
import { startCase, clone } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Button, Grid, Input, Typography } from '@material-ui/core';
import PickitAPI from 'PickitAPI';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';

const gridSpacing = 2;

const styles = (theme) => ({
  customerLogo: {
    textAlign: 'center',
    padding: '4px 0',
    height: 100,
  },
  customerLogoImg: {
    maxHeight: 75,
    maxWidth: '100%',
    margin: '0 auto',
  },
  grid: {
    padding: 15,
  },
  gridContent: {
    padding: 30,
  },
  toolbarGridItem: {
    display: 'flex',
    alignItems: 'center',
    // borderRight: '1px lightgray solid',

    '& > strong': {
      fontWeight: 'bold',
      marginTop: 10,
      marginBottom: 20,
      marginLeft: 10,
      lineHeight: '100%',
      textAlign: 'left',

      '& > span': {
        fontSize: '0.6rem',
        fontWeight: 'normal',
      },
    },
  },
  toolbarGridFirstItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fb1790',

    '& > strong': {
      color: '#fff',
      fontWeight: 'bold',
      marginTop: 10,
      marginBottom: 20,
      marginLeft: 20,
      lineHeight: '100%',
      textAlign: 'left',

      '& > span': {
        fontSize: '0.6rem',
        fontWeight: 'normal',
      },
    },
  },
  formControl: {
    width: '100%',
    paddingBottom: 20,
  },
  subheading: {
    paddingTop: 20,
  },
  saveButton: {
    display: 'inline-block',
    padding: '5px 25px',
    minHeight: 24,
    minWidth: 64,
    marginRight: 8,
    marginBottom: 5,
  },
  cancelButton: {
    display: 'inline-block',
    padding: '5px 10px',
    minHeight: 24,
    minWidth: 64,
    marginBottom: 5,
    boxShadow: 'none',
    color: '#fb1790',
  },
  editButton: {
    display: 'inline-block',
    padding: '5px 25px',
    minHeight: 24,
    minWidth: 64,
  },
  editButtons: {
    textAlign: 'right',
    width: '90%',
    whiteSpace: 'nowrap',
    '@media (max-width: 1350px)': {
      whiteSpace: 'normal',
    },
    '& > button': {
      '@media (max-width: 1350px)': {
        marginRight: 10,
        marginTop: 5,
        minWidth: 90,
      },
    },
  },
  toolbar: {
    backgroundColor: '#f7f7f5',
  },
  card: {
    textAlign: 'center',
    width: '100%',
  },
  purchaseInput: {
    marginBottom: -1,
    '& > input': {
      padding: 0,
    },
  },
  dividerRight: {
    borderRight: '1px solid rgba(151,151,151,0.21)',
  },
});
function CustomerOverview(props) {
  const { classes, customer } = props;
  const [community, setCommunity] = useState(props.community);
  const [editMode, setEditMode] = useState(false);
  const [maxNumberOfUsers, setMaxNumberOfUsers] = useState(null);
  const [used, setUsed] = useState(undefined);

  useEffect(() => {
    setUsed(props.community.maxNumberOfUsers - props.community.empty_slots);
    setMaxNumberOfUsers(props.community.maxNumberOfUsers);
  }, []);
  const handleFormSeatsToggle = () => {
    if (!editMode) {
      setEditMode(true);
      setMaxNumberOfUsers(community.maxNumberOfUsers);
    } else {
      let community_ = clone(community);
      community_.maxNumberOfUsers = maxNumberOfUsers;
      setEditMode(false);
      setMaxNumberOfUsers(maxNumberOfUsers);
      setCommunity(community_);
    }
  };

  const handleFieldUpdate = (event) => {
    let community_ = clone(community);
    community_.maxNumberOfUsers = event.target.value;
    setCommunity(community_);
  };

  const handleSaveSeats = () => {
    setEditMode(false);
    setMaxNumberOfUsers(community.maxNumberOfUsers);
    let form_data = new FormData();
    form_data.append('maxNumberOfUsers', community.maxNumberOfUsers);

    PickitAPI.community.putMaxNumberOfUsers(community.id, form_data).then((response) => {
      setCommunity(response);

      PickitAPI.cache.set('sales_ui_customers', undefined);
    });
  };

  if (community.product === undefined) {
    return <div />;
  }
  let status = '';

  switch (true) {
    case customer.tenant_id === customer.partner && customer.status === 'active':
      status = 'NFR';
      break;
    case customer.status === 'suspended':
      status = 'Suspended';
      break;
    case customer.status === 'deleted':
      status = 'Deleted';
      break;
    //Null check is placeholder until we decide how to do this logic
    case parseInt(community.product.trial_days_left) <= 0 || community.product.trial_days_left === null:
      status = 'Paying';
      break;
    case parseInt(community.product.trial_days_left) > 0:
      status = 'Trial Active';
      break;
    case parseInt(community.product.trial_days_left) <= 0:
      status = 'Trial Expired';
      break;

    default:
      status = 'Unknown';
      break;
  }

  return (
    <Card elevation={5} className={classes.card}>
      <CardContent className={classes.cardContent} style={{ padding: 0 }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={3} className={classes.toolbarGridFirstItem}>
            <Typography variant={'subtitle1'} component={'strong'}>
              <span>{'Subscription'}</span>
              <br />
              {community.product ? community.product.name : 'None'}
            </Typography>
          </Grid>
          <Grid item xs={2} className={`${classes.toolbarGridItem} ${classes.dividerRight}`}>
            {editMode ? (
              <Typography variant={'subtitle1'} component={'strong'}>
                <span>{'Licensed '}</span>
                <Input value={community.maxNumberOfUsers} onChange={handleFieldUpdate} className={classes.purchaseInput} />
              </Typography>
            ) : (
              <Typography variant={'subtitle1'} component={'strong'}>
                <span>{'Licensed '}</span>
                <br />
                {maxNumberOfUsers || 0}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} className={`${classes.toolbarGridItem} ${classes.dividerRight}`} style={{ opacity: editMode ? 0.3 : 1 }}>
            <Typography variant={'subtitle1'} component={'strong'}>
              <span>{'Activated '}</span>
              <br />
              {used}
            </Typography>
          </Grid>
          <Grid item xs={2} className={`${classes.toolbarGridItem} ${classes.dividerRight}`} style={{ opacity: editMode ? 0.3 : 1 }}>
            <Typography variant={'subtitle1'} component={'strong'}>
              <span>{'Status'}</span>
              <br />
              {startCase(status)}
              {parseInt(community.product.trial_days_left) > 0 && customer.status === 'active' ? (
                <React.Fragment>
                  <br />
                  <span style={{ position: 'absolute', marginLeft: 2 }}>{community.product.trial_days_left + 1 + ' days left'}</span>
                </React.Fragment>
              ) : (
                ''
              )}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.toolbarGridItem}>
            {editMode ? (
              <div className={classes.editButtons}>
                <Button variant={'contained'} color={'primary'} type={'button'} className={classes.saveButton} onClick={handleSaveSeats}>
                  Save
                </Button>
                <Button variant={'text'} color={'secondary'} type={'button'} className={classes.cancelButton} onClick={handleFormSeatsToggle}>
                  Cancel
                </Button>
              </div>
            ) : (
              <div style={{ textAlign: 'right', width: '90%' }}>
                {!props.isReadOnly() && (
                  <Button variant={'contained'} color={'primary'} type={'button'} className={classes.editButton} onClick={handleFormSeatsToggle}>
                    Edit
                  </Button>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CustomerOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  community: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerOverview);

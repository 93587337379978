import useTokenManagement from './useTokenManagement';

export default function useRequest() {
  const { rotateToken } = useTokenManagement();
  /**
   *
   * @param {String} url Request URL.
   * @param {object} options Same options object as for the fetch API. method: GET and auth token are attached internally
   * @param {boolean} rotatedToken Do not provide, for internal logic only.
   * @returns {object} The parsed json response.
   */
  const request = async (url, options, rotatedToken) => {
    const token = JSON.parse(localStorage.getItem('user') || '{}')?.jwt;
    const req = await fetch(url, {
      method: 'GET',
      ...options,
      headers: {
        Authorization: `JWT ${token}`,
        ...options?.headers,
      },
    });
    const res = await req.json();
    if (!req.ok) {
      if (!rotatedToken && (req.status === 401 || req.status === 403)) {
        const newToken = await rotateToken();
        return request(url, { ...options, headers: { ...options.headers, Authorization: `JWT ${newToken.jwt}` } }, true);
      }
      throw res;
    }
    return res;
  };
  return request;
}

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Paper,
  Toolbar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  LinearProgress,
  Select,
  TextField,
  InputLabel,
  MenuItem,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { PortalDataContext } from 'Contexts/PortalDataContext';

import { Redirect } from 'react-router-dom';
import { clone, startCase } from 'lodash';
import useRouterQuery from 'hooks/useRouterQuery';
import SalesUIPartner from 'PickitAPI/Partner';
import { Pagination } from '@material-ui/lab';

const gridSpacing = 2;
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
    paddingBottom: 50,
  },
  cacheMessage: {
    textAlign: 'right',
  },

  paper: {
    minHeight: '60vh',
    minWidth: 1200,
    width: 'fit-content',
  },
  toolbar: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
  },
  tableRow: {
    cursor: 'pointer',
  },
  button: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  input: {
    underline: 'false',
  },
  Icon: {
    color: '#727272',
  },
  tableCell: {
    paddingTop: 23,
    paddingBottom: 23,
  },
  tableCellFocus: {
    fontWeight: 500,
  },
  '@media (max-width: 1230px)': {
    paper: {
      overflowX: 'scroll',
    },
  },
  showSort: {
    '&:hover > button > span > span': {
      opacity: 1,
    },
  },
});

let searchTimeout = undefined;

function Partners(props) {
  const context = useContext(PortalDataContext);
  const { classes, pass } = props;

  const [partners, setPartners] = useState([]);
  const [totalPartners, setTotalPartners] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  const defaultParams = [
    { key: 'page_nr', value: 1 },
    { key: 'page_limit', value: 25 },
    { key: 'sort_field', value: 'date_desc' },
    { key: 'status', value: undefined },
    { key: 'type', value: undefined },
    { key: 'search', value: undefined },
  ];

  const query = useRouterQuery(defaultParams);
  const { params, applyDefaultParams, saveParams, loadParams } = query;

  const statusFilter = {
    any: {
      title: 'Any',
      slug: 'undefined',
    },
    active: {
      title: 'Active',
      slug: 'active',
    },
    deleted: {
      title: 'Deleted',
      slug: 'deleted',
    },
  };
  const typeFilter = {
    any: {
      title: 'Any',
      slug: 'undefined',
    },
    distributor: {
      title: 'Distributor',
      slug: 'distributor',
    },
    reseller: {
      title: 'Reseller',
      slug: 'reseller',
    },
  };

  useEffect(() => {
    loadParams('partners');
    getPartners();
    setIsMounted(true);
  }, []);
  useEffect(() => {
    if (isMounted) {
      getPartners();
      saveParams('partners');
    }
  }, [params.page_nr, params.page_limit, params.status, params.sort_field, params.type, props?.pass?.testMode()]);

  useEffect(() => {
    if (isMounted) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        if (params.page_nr === 1 || params.page_nr === '1') {
          getPartners();
        } else {
          query.set('page_nr', 1);
        }
        saveParams('partners');
      }, 1000);
    }
  }, [params.search]);

  const getParams = () => {
    const newParams = clone(params);
    newParams.parent = props.pass.getPartnerTenantId() || '';
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] === 'undefined') {
        newParams[key] = undefined;
      }
    });
    if (newParams.search) {
      newParams.search = newParams.search.toLowerCase();
    }
    return newParams;
  };

  const getPartners = () => {
    if (!params.page_nr) {
      loadParams('partners');
      return;
    }
    if (!props.pass) return;
    let testMode = props.pass.testMode();
    const newParams = getParams();
    setPartners([]);

    SalesUIPartner.partners
      .getFiltered(newParams, props.pass.testMode())
      .then((partners) => {
        const currentParams = getParams();

        //Make sure rapid test mode change doesn't overwrite slow response
        if (testMode !== props.pass.testMode() || JSON.stringify(newParams) !== JSON.stringify(currentParams)) {
          return;
        }
        setPartners(partners.data);
        setTotalPartners(partners.total_count);
      })
      .catch((e) => {
        if (e.status === 403) {
          props.pass.logout();
        }
        console.error(e);
        context.setError(e.statusText);
      });
  };

  const onFilterChanged = (event) => {
    let key = event.target.name,
      value = event.target.value;
    if (value === 'undefined') {
      query.delete(key, value);
      return;
    }
    query.set('page_nr', 1);
    query.set(key, value);
  };

  const setOrder = (event) => {
    event.persist();
    let newOrder = '';
    let currentOrder = params.sort_field;

    if (currentOrder.indexOf(event.target.id) === -1 || currentOrder.indexOf('_desc') !== -1) {
      newOrder = event.target.id + '_asc';
    } else {
      newOrder = event.target.id + '_desc';
    }
    query.set('sort_field', newOrder);
    query.set('page_nr', 1);
  };

  const handlePartnerClick = (partner_uuid) => (event) => {
    if (props.route) {
      props.route.history.push('/partner/' + partner_uuid + (props.pass.testMode() ? '?test=true' : ''));
    }
  };

  const handlePartnerNewClick = (event) => {
    if (props.route) {
      props.route.history.push('/partners/new' + (props.pass.testMode() ? '?test=true' : ''));
    }
  };

  if (props.pass !== undefined && !props.pass.isDistributor()) {
    // Pass only distributors on that page
    return <Redirect to={`/customers${props.pass.testMode() ? '?test=true' : ''}`} />;
  }

  let partnerRows = !!partners ? (
    partners
      .filter((partner) => !!partner && partner.tenant_id !== pass.getPartnerTenantId())
      .map((partner, index) => {
        return (
          <TableRow hover key={index} className={classes.tableRow} width="400px">
            <TableCell
              onClick={handlePartnerClick(partner.tenant_id)}
              className={`${classes.noWrap} ${classes.tableCell} ${classes.tableCellFocus}`}
              size="small">
              {partner.name || partner.tenant_id}
            </TableCell>
            <TableCell onClick={handlePartnerClick(partner.tenant_id)} className={`${classes.noWrap} ${classes.tableCell}`} size="small">
              {new Date(partner.created_on).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </TableCell>
            <TableCell onClick={handlePartnerClick(partner.tenant_id)} className={`${classes.noWrap} ${classes.tableCell}`} size="small">
              {startCase(partner.type)}
            </TableCell>
            <TableCell onClick={handlePartnerClick(partner.tenant_id)} className={`${classes.noWrap} ${classes.tableCell}`} size="small">
              {partner.parent ? partner.parent.name : 'N/A'}
            </TableCell>
            {/*<TableCell onClick={handlePartnerClick(partner.tenant_id)} className={`${classes.noWrap} ${classes.tableCell}`} size="small">{ partner.email }</TableCell>*/}
            <TableCell onClick={handlePartnerClick(partner.tenant_id)} className={`${classes.noWrap} ${classes.tableCell}`} size="small">
              {startCase(partner.status)}
            </TableCell>
          </TableRow>
        );
      })
  ) : (
    <TableRow style={{ height: '50vh' }}>
      <TableCell colSpan={8}>
        <LinearProgress />
      </TableCell>
    </TableRow>
  );
  if (props.pass) {
    props.pass.setCounter(context.partnerTotal);
  }

  let statusFilterRows = Object.keys(statusFilter).map((key) => {
    let filter = statusFilter[key];

    return (
      <MenuItem key={key} value={filter.slug}>
        {filter.title}
      </MenuItem>
    );
  });

  let typeFilterRows = Object.keys(typeFilter).map((key) => {
    let filter = typeFilter[key];

    return (
      <MenuItem key={key} value={filter.slug}>
        {filter.title}
      </MenuItem>
    );
  });

  const PartnerPagination = (props) => (
    <>
      <Typography>Result: {props.totalPartners}</Typography>
      <Typography style={{ marginLeft: 'auto', marginRight: 10 }}>Customers per page:</Typography>

      <Select
        value={Number(props.page_limit) || 25}
        onChange={(event) => {
          props.query.set('page_limit', parseInt(event.target.value, 10));
          window.scrollTo(0, 0);
        }}>
        {[25, 50, 100].map((rowsPerPage) => (
          <MenuItem value={rowsPerPage}>{rowsPerPage}</MenuItem>
        ))}
      </Select>
      <Pagination
        count={Math.ceil(props.totalPartners / props.page_limit) || 0}
        page={Number(props.page_nr ?? 1)}
        onChange={(event, page) => {
          props.query.set('page_nr', Number(page));
          window.scrollTo(0, 0);
        }}
      />
    </>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing} justifyContent={'center'}>
        <Grid item xs={12}>
          <Paper elevation={2} square className={classes.paper}>
            <Toolbar>
              {props.pass && !props.pass.isReadOnly() && (
                <Button
                  className={classes.button}
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<Icon>add</Icon>}
                  onClick={() => {
                    handlePartnerNewClick();
                  }}
                  style={{ marginTop: 10 }}>
                  Add Partner
                </Button>
              )}
              <IconButton onClick={() => getPartners()} style={{ marginLeft: 10, marginTop: 11 }}>
                <Icon className={'material-icons ' + classes.Icon}>refresh</Icon>
              </IconButton>
            </Toolbar>
            <Toolbar className={classes.toolbar}>
              <Grid container wrap="nowrap" alignItems="center">
                <FormControl size="small" variant="filled" fullWidth>
                  <InputLabel>{'Partner Status'}</InputLabel>
                  <Select disableUnderline name={'status'} value={params.status || 'undefined'} onChange={onFilterChanged}>
                    {statusFilterRows}
                  </Select>
                </FormControl>

                {props.pass && props.pass.isStaff() && (
                  <FormControl size="small" variant="filled" fullWidth>
                    <InputLabel>{'Partner Type'}</InputLabel>
                    <Select disableUnderline name={'type'} value={params.type || 'undefined'} onChange={onFilterChanged} style={{ marginLeft: 10 }}>
                      {typeFilterRows}
                    </Select>
                  </FormControl>
                )}

                <FormControl variant="filled" fullWidth>
                  <TextField
                    name={'search'}
                    size="small"
                    variant="filled"
                    label="Search"
                    value={params.search}
                    onChange={(e) => query.set('search', e.target.value)}
                    placeholder={'Type to filter...'}
                    style={{ marginLeft: 10 }}
                    startadornment={
                      <InputAdornment position="start">
                        <Icon className="material-icons">search</Icon>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <IconButton color={'primary'} onClick={() => applyDefaultParams(true)} style={{ marginLoeft: 10, marginTop: 4 }}>
                  <Icon className="material-icons" style={{ color: '#727272' }}>
                    rotate_left
                  </Icon>
                </IconButton>
              </Grid>
            </Toolbar>
            <Toolbar>
              <PartnerPagination {...{ query, totalPartners, page_nr: params.page_nr, page_limit: params.page_limit }} />
            </Toolbar>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell xs={2} variant={'head'} size="small" id={'name'} onClick={setOrder} width="400px">
                        <TableSortLabel
                          active={params.sort_field === 'name_asc' || params.sort_field === 'name_desc'}
                          direction={params.sort_field === 'name_desc' ? 'desc' : 'asc'}
                          id="name"
                          onClick={setOrder}>
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant={'head'} size="small" id={'date'} onClick={setOrder}>
                        <TableSortLabel
                          active={params.sort_field === 'date_asc' || params.sort_field === 'date_desc'}
                          direction={params.sort_field === 'date_desc' ? 'desc' : 'asc'}
                          id="date"
                          onClick={setOrder}>
                          Created On
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant={'head'} size="small" id={'type'} onClick={setOrder}>
                        <TableSortLabel
                          active={params.sort_field === 'type_asc' || params.sort_field === 'type_desc'}
                          direction={params.sort_field === 'type_desc' ? 'desc' : 'asc'}
                          id="type"
                          onClick={setOrder}>
                          Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant={'head'} size="small" id={'dist'} onClick={setOrder}>
                        <TableSortLabel
                          active={params.sort_field === 'dist_asc' || params.sort_field === 'dist_desc'}
                          direction={params.sort_field === 'dist_desc' ? 'desc' : 'asc'}
                          id="dist"
                          onClick={setOrder}>
                          Distributor
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant={'head'} size="small" id={'status'} onClick={setOrder}>
                        <TableSortLabel
                          active={params.sort_field === 'status_asc' || params.sort_field === 'status_desc'}
                          direction={params.sort_field === 'status_desc' ? 'desc' : 'asc'}
                          id="status"
                          onClick={setOrder}>
                          Status
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>{partnerRows}</TableBody>
                </Table>
              </Grid>
            </Grid>
            <Toolbar>
              <PartnerPagination {...{ query, totalPartners, page_nr: params.page_nr, page_limit: params.page_limit }} />
            </Toolbar>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

Partners.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Partners);

import { withStyles } from '@material-ui/core/styles';
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails } from '@material-ui/core';
export const Accordion = withStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  expanded: {},
  disabled: {
    backgroundColor: 'rgba(0,0,0,0) !important',
  },
}))(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    padding: 0,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);

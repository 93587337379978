import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  FormControl,
  Grid,
  Toolbar,
  Typography,
  FormGroup,
  Chip,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  InputLabel,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
} from '@material-ui/core';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PickitAPI from 'PickitAPI/index';
import NativeSelect from '@material-ui/core/NativeSelect/NativeSelect';
import Utility from 'Services/utils';
import { CustomerManagement as CustomerManagementContext } from 'Contexts/CustomerManagement';

const gridSpacing = 5;

const styles = (theme) => ({
  grid: {
    marginTop: 0,
    padding: 15,
    '& > div': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  formControl: {
    paddingBottom: 10,
  },
  title: {
    fontSize: 24,
    paddingTop: 0,
  },
  subtitle: {
    fontSize: 16,
    paddingTop: 0,
  },
  saveButton: {
    display: 'flex',
    padding: '5px 40px',
    minHeight: 24,
    minWidth: 64,
    margin: '32px auto 0px auto',
    boxShadow: 'none',
    alignItems: 'center',
  },
  customerLogo: {
    textAlign: 'center',
    padding: ' 0',
    width: 125,
    height: 70,
  },
  customerLogoImg: {
    maxHeight: '85%',
    maxWidth: '85%',
  },
  customerLogoNoBranding: {
    height: 70,
    textAlign: 'center',
    position: 'relative',
    padding: 0,
    margin: '0 auto',

    '& > img': {
      opacity: 0.3,
      width: '100%',
      height: 140,
      position: 'absolute',
      zIndex: 5,
      background:
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAEGWlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPrtzZyMkzlNsNIV0qD8NJQ2TVjShtLp/3d02bpZJNtoi6GT27s6Yyc44M7v9oU9FUHwx6psUxL+3gCAo9Q/bPrQvlQol2tQgKD60+INQ6Ium65k7M5lpurHeZe58853vnnvuuWfvBei5qliWkRQBFpquLRcy4nOHj4g9K5CEh6AXBqFXUR0rXalMAjZPC3e1W99Dwntf2dXd/p+tt0YdFSBxH2Kz5qgLiI8B8KdVy3YBevqRHz/qWh72Yui3MUDEL3q44WPXw3M+fo1pZuQs4tOIBVVTaoiXEI/MxfhGDPsxsNZfoE1q66ro5aJim3XdoLFw72H+n23BaIXzbcOnz5mfPoTvYVz7KzUl5+FRxEuqkp9G/Ajia219thzg25abkRE/BpDc3pqvphHvRFys2weqvp+krbWKIX7nhDbzLOItiM8358pTwdirqpPFnMF2xLc1WvLyOwTAibpbmvHHcvttU57y5+XqNZrLe3lE/Pq8eUj2fXKfOe3pfOjzhJYtB/yll5SDFcSDiH+hRkH25+L+sdxKEAMZahrlSX8ukqMOWy/jXW2m6M9LDBc31B9LFuv6gVKg/0Szi3KAr1kGq1GMjU/aLbnq6/lRxc4XfJ98hTargX++DbMJBSiYMIe9Ck1YAxFkKEAG3xbYaKmDDgYyFK0UGYpfoWYXG+fAPPI6tJnNwb7ClP7IyF+D+bjOtCpkhz6CFrIa/I6sFtNl8auFXGMTP34sNwI/JhkgEtmDz14ySfaRcTIBInmKPE32kxyyE2Tv+thKbEVePDfW/byMM1Kmm0XdObS7oGD/MypMXFPXrCwOtoYjyyn7BV29/MZfsVzpLDdRtuIZnbpXzvlf+ev8MvYr/Gqk4H/kV/G3csdazLuyTMPsbFhzd1UabQbjFvDRmcWJxR3zcfHkVw9GfpbJmeev9F08WW8uDkaslwX6avlWGU6NRKz0g/SHtCy9J30o/ca9zX3Kfc19zn3BXQKRO8ud477hLnAfc1/G9mrzGlrfexZ5GLdn6ZZrrEohI2wVHhZywjbhUWEy8icMCGNCUdiBlq3r+xafL549HQ5jH+an+1y+LlYBifuxAvRN/lVVVOlwlCkdVm9NOL5BE4wkQ2SMlDZU97hX86EilU/lUmkQUztTE6mx1EEPh7OmdqBtAvv8HdWpbrJS6tJj3n0CWdM6busNzRV3S9KTYhqvNiqWmuroiKgYhshMjmhTh9ptWhsF7970j/SbMrsPE1suR5z7DMC+P/Hs+y7ijrQAlhyAgccjbhjPygfeBTjzhNqy28EdkUh8C+DU9+z2v/oyeH791OncxHOs5y2AtTc7nb/f73TWPkD/qwBnjX8BoJ98VQNcC+8AAAFFSURBVGgF7djbDYMwDAXQBjEMYqyqY1WMxTppLiJIQIA8ariWkp8IPuweO60am67r7MstY8wwjuPH7dMz3uUua63p+/7r9rePIRW/9QmQzCV9ub0YgSIgzhxvQkjFb1EZBAdEKolkfJTqtnb7bv/zOJm58moRE0AzYgFoRawAGhE7gDZEEKAJcQjQgjgFaEBcAtgRUQBmRDSAFZEEYEQkA9gQWQAmRDaABVEEYEAUAx5H4EaGOyw+SMl66mbXzNOD4k6gCPNUY/CFwF1YOn5zRxJJRINqaUbUuZA/79sd3wm3xIdbdS60rXzo2XVCbO60/HxKJgFKKv4CkEziuyKBWAE0InYAbYggQBPiEKAFcQrQgLgEsCOiAMyIaAArIgnAiEgGsCGyAEyIbAALogjAgCgGPI7AX9w6F3JtqHMhnMWLFSrSD9jOnakVHpZYAAAAAElFTkSuQmCC') 8% 50%",
      backgroundSize: '5%',
      left: 0,
      top: 0,
    },
  },
  dashedUnderline: {
    '&::before': {
      borderBottomStyle: 'dashed !important',
    },
  },
  noUnderline: {
    '&::before': {
      borderBottomStyle: 'none !important',
    },
    '&::after': {
      borderBottomStyle: 'none !important',
    },
  },
  paperInfo: {},
  chipText: {
    fontSize: 14,
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const Accordion = withStyles((theme) => ({
  root: {
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    padding: 0,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);

function CustomerInfo(props) {
  const { classes } = props;
  const CustomerManagement = useContext(CustomerManagementContext);
  const { customer: cust, handleCustomerChange } = CustomerManagement;
  const { customer, community } = cust;

  const [countries, setCountries] = useState(Utility.getCountries());
  useEffect(() => {
    // For non staff user we must have current partner tenant id on any request
    if (!props.isStaff()) {
      handleCustomerChange({ path: 'customer.partner', value: props.getPartnerTenantId() });
    }

    if (PickitAPI.cache.get('sales_ui_countries')) {
      setCountries(PickitAPI.cache.get('sales_ui_countries'));
    } else {
      PickitAPI.helpers.getCountries().then((countries) => {
        setCountries(countries);
        PickitAPI.cache.set('sales_ui_countries', countries);
      });
    }
  }, []);
  const onFieldUpdate = (event, value) => {
    // Do not allow update field if user has no admin rights
    event.persist();
    if (props.isReadOnly()) return;

    handleCustomerChange({ path: event.target.name, value: event.target.value || value });
  };

  let keys = Object.keys(countries);
  keys.sort((a, b) => countries[a].localeCompare(countries[b]));

  const countriesRows = keys.map((country) => {
    return (
      <option value={country} key={country}>
        {countries[country]}
      </option>
    );
  });

  return (
    <>
      <Paper elevation={2} square style={{ background: '#f5f2f4', position: 'relative', textAlign: 'center' }}>
        {community.profile_image_url ? (
          <Toolbar className={classes.customerLogo} style={{ backgroundColor: community.colour, width: '100%', textAlign: 'center' }}>
            <img src={community.profile_image_url} alt=" " className={classes.customerLogoImg} style={{ margin: '0 auto' }} />
          </Toolbar>
        ) : (
          <>
            <Toolbar className={`${classes.customerLogoNoBranding} squares`}></Toolbar>
            <span
              style={{
                width: '100%',
                height: '100%',
                fontWeight: 500,
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              No logo or color added
            </span>
          </>
        )}
      </Paper>
      <br />
      <Paper elevation={2} square className={classes.paperInfo}>
        <form>
          <Grid container spacing={gridSpacing} className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant={'h6'} className={classes.title}>
                {'Image bank details'}
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  name={'customer.details.customer.name'}
                  value={customer?.details?.customer?.name}
                  label={'Company Name'}
                  onChange={onFieldUpdate}
                  readOnly={props.isReadOnly()}
                />
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  //className={classes.noUnderline}
                  name={'community.slug'}
                  value={'https://' + community.slug + '.pickit.com' || ''}
                  label={'Community URL Slug'}
                  readOnly
                  disabled
                />
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <div style={{ display: 'flex' }}>
                  <TextField
                    name={'community.host'}
                    value={community.host}
                    label={<p style={{ marginTop: 0, marginBottom: 0, whiteSpace: 'nowrap' }}>Community display name</p>}
                    onChange={onFieldUpdate}
                    readOnly={props.isReadOnly()}
                    style={{ width: '70%' }}
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                  <p style={{ marginTop: 0, marginBottom: 0, alignSelf: 'flex-end', paddingBottom: 7 }}>.pickit.com</p>
                </div>
              </FormControl>
              {props.isStaff() && (
                <>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>New user license</InputLabel>
                    <NativeSelect
                      disabled={customer.status === 'starter'}
                      name={'customer.new_user_license'}
                      value={customer.new_user_license}
                      placeholder={'New user license'}
                      label="New user license"
                      onChange={onFieldUpdate}>
                      <option value="paying" key="paying">
                        Paying
                      </option>
                      <option value="starter" key="starter">
                        Starter
                      </option>
                    </NativeSelect>
                  </FormControl>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={customer?.status === 'starter'}
                            name={'customer.is_license_management_allowed'}
                            color="primary"
                            checked={customer.is_license_management_allowed}
                            onChange={onFieldUpdate}
                          />
                        }
                        label="Allow license management"
                      />
                    </FormGroup>
                    <FormHelperText>Allow the customer to change the license type of their own users</FormHelperText>
                  </FormControl>
                </>
              )}
              <Accordion square>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant={'h6'} className={classes.subtitle}>
                    {'Address'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField
                        name={'customer.details.customer.address'}
                        value={customer?.details?.customer?.address || ''}
                        label={'Address'}
                        onChange={onFieldUpdate}
                        readOnly={props.isReadOnly()}
                      />
                    </FormControl>
                    <FormGroup row>
                      <FormControl className={classes.formControl} style={{ width: '65%' }}>
                        <TextField
                          name={'customer.details.customer.city'}
                          value={customer.details.customer.city || ''}
                          label={'City'}
                          onChange={onFieldUpdate}
                          readOnly={props.isReadOnly()}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl} style={{ width: '33%', marginLeft: 'auto' }}>
                        <TextField
                          name={'customer.details.customer.postcode'}
                          value={customer.details.customer.postcode || ''}
                          label={'Zip'}
                          onChange={onFieldUpdate}
                          readOnly={props.isReadOnly()}
                        />
                      </FormControl>
                    </FormGroup>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel>Country</InputLabel>
                      <NativeSelect name={'customer.country'} value={customer.country} placeholder={'Country'} onChange={onFieldUpdate}>
                        {countriesRows}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion square>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant={'h6'} className={classes.subtitle}>
                    {'Contact person'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <FormGroup row>
                      <FormControl className={classes.formControl} style={{ width: '49%' }}>
                        <TextField
                          name={'customer.details.customer.first_name'}
                          value={customer.details.customer.first_name || ''}
                          label={'First name'}
                          onChange={onFieldUpdate}
                          readOnly={props.isReadOnly()}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl} style={{ width: '49%', marginLeft: 'auto' }}>
                        <TextField
                          name={'customer.details.customer.last_name'}
                          value={customer.details.customer.last_name || ''}
                          label={'Last name'}
                          onChange={onFieldUpdate}
                          readOnly={props.isReadOnly()}
                        />
                      </FormControl>
                    </FormGroup>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField
                        name={'customer.details.customer.email'}
                        value={customer.details.customer.email || ''}
                        label={'Email address'}
                        onChange={onFieldUpdate}
                        readOnly={props.isReadOnly()}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                      <TextField
                        name={'customer.details.customer.phone'}
                        value={customer?.details?.customer?.phone || ''}
                        label={'Phone (optional)'}
                        onChange={onFieldUpdate}
                        readOnly={props.isReadOnly()}
                      />
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Typography variant={'h6'} className={classes.subtitle} style={{ marginTop: 12 }}>
                {'Authentication'}
              </Typography>
              <Typography variant={'body1'} component="div" className={classes.chipText}>
                {'Microsoft SSO'}{' '}
                <Chip
                  size="small"
                  style={community.if_sso_enabled ? { minWidth: 90, background: '#000', color: '#fff' } : { minWidth: 90 }}
                  label={community.if_sso_enabled ? 'ACTIVE' : 'NOT ACTIVE'}
                />
              </Typography>
              <Typography variant={'body1'} component="div" className={classes.chipText}>
                {'Regular login'}{' '}
                <Chip
                  size="small"
                  style={!community.disable_email_login ? { minWidth: 90, background: '#000', color: '#fff' } : { minWidth: 90 }}
                  label={!community.disable_email_login ? 'ACTIVE' : 'NOT ACTIVE'}
                />
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}

CustomerInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerInfo);

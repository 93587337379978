import React, { useEffect, useState, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Box,
  Button,
  Checkbox,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar as MuiToolbar,
  Typography,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import usePresets from 'hooks/usePresets';
import { Preset, Services } from 'Components';
import { clone } from 'lodash';
import Snackbar from 'Components/Snackbar';

const gridSpacing = 2;

const Toolbar = withStyles((theme) => ({
  root: {
    '&>*:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))(MuiToolbar);

const DialogContent = withStyles((theme) => ({
  root: {
    '&>form>*:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}))(MuiDialogContent);

const Dialog = withStyles((theme) => ({
  paper: {
    maxWidth: 600,
  },
}))(MuiDialog);
const EditDialog = withStyles((theme) => ({
  paper: {
    maxWidth: 700,
  },
}))(MuiDialog);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 10,
    paddingTop: 40,
    paddingBottom: 50,
  },
  paper: { background: '#f5f2f4' },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    background: '#fff',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)}',
    minHeight: 64,
  },
  form: {
    '&>*:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  button: {
    marginTop: 10,
  },
});

const DEFAULT_SERVICE = {
  name: '',
  group: 'integrations',
  slug: '',
  path: '',
  service_type: 'bool',
  default: false,
};
/**
 * Service
 *   {
        key: 'test_feature',
        path: 'access.features.test_feature',
        name: 'Test service',
        value: false,
        order: 1,
      },
 */
const DEFAULT_PRESET = {
  name: '',
  services: {
    services: [],
    features: [],
    integrations: [],
    metadata: [],
  },
};
function Presets(props) {
  const { classes } = props;
  const {
    data,
    getPresets,
    getPresetServices,
    createPreset,
    createPresetService,
    updatePreset,
    updatePresetService,
    deletePresetService,
    deletePreset,
  } = usePresets();

  const [openNewService, setOpenNewService] = useState(false);
  const [openNewPreset, setOpenNewPreset] = useState(false);
  const [openEditServices, setOpenEditServices] = useState(false);
  const [openDeletePreset, setOpenDeletePreset] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState(false);

  const [changedServices, setChangedServices] = useState({ change: {}, delete: {} });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState({ severity: 'success', message: '' });
  const [presets, setPresets] = useState(undefined);

  const [loaders, setLoader] = useReducer((state, action) => {
    state[action.key] = action.value;
    return { ...state };
  }, {});
  const [newService, setNewService] = useReducer((state, action) => {
    if (action.key === 'replace') return { ...action.value };
    state[action.key] = action.value;

    if (action.key === 'name' || action.key === 'slug') {
      state.slug = state[action.key]
        .replaceAll(' ', '_')
        .replace(/[^0-9a-zA-Z_]+/, '')
        .toLowerCase();
    }

    state.path = `access.${state.group}.${state.slug}`;
    if (action.key === 'service_type') {
      state.default = state.service_type === 'bool' ? false : '';
    }

    return { ...state };
  }, DEFAULT_SERVICE);

  const [newPreset, setNewPreset] = useReducer((state, action) => {
    if (action.key === 'replace') return { ...action.value };
    state[action.key] = action.value;

    return { ...state };
  }, DEFAULT_PRESET);
  useEffect(() => {
    ValidatorForm.addValidationRule('StringLength<100', (value) => {
      return value !== undefined && typeof value === 'string' && value.length <= 100;
    });
    ValidatorForm.addValidationRule('StringLength>0', (value) => {
      return value !== undefined && typeof value === 'string' && value.length > 0;
    });
  }, []);

  useEffect(() => {
    initNewPreset();
  }, [data.services]);
  const initNewPreset = () => {
    if (data.services) {
      const parsedServices = {};
      Object.keys(data.services).forEach(
        (key) =>
          (parsedServices[key] = data.services[key].map((service) => ({
            value: service.default,
            key: service.slug,
            name: service.name,
            order: service.order,
            path: service.path,
          })))
      );
      setNewPreset({ key: 'services', value: parsedServices });
    }
  };
  const openSnackbar = (message, severity = 'success') => {
    setSnackbarOptions({ message, severity });
    setSnackbarOpen(true);
  };

  const onServiceCreate = async () => {
    try {
      setLoader({ key: 'createService', value: true });
      const newService_ = clone(newService);
      newService_.order = 0;
      data?.services[newService.group]?.forEach((service) => {
        if (service.order >= newService_.order) newService_.order = service.order + 1;
      });
      await createPresetService(newService_);
      setOpenNewService(false);
      getPresets();
      setNewService({ key: 'replace', value: DEFAULT_SERVICE });
      openSnackbar('Service added');
    } catch (e) {
      console.error(e);
      openSnackbar('Failed to add service | ' + e.error, 'error');
    }
    setLoader({ key: 'createService', value: false });
  };
  const onPresetCreate = async () => {
    try {
      setLoader({ key: 'createPreset', value: true });

      const newPreset_ = clone(newPreset);
      newPreset_.order = 0;
      data?.presets?.forEach((preset) => {
        if (preset.order >= newPreset_.order) newPreset_.order = preset.order + 1;
      });
      const preset = await createPreset({ name: newPreset_.name, order: newPreset_.order });

      await updatePreset({ ...newPreset_, preset_id: preset.preset_id });
      setOpenNewPreset(false);
      getPresets();
      openSnackbar('Preset added');
      setNewPreset({ key: 'replace', value: DEFAULT_PRESET });
      initNewPreset();
    } catch (e) {
      openSnackbar('Failed to add preset | ' + e.error, 'error');
    }
    setLoader({ key: 'createPreset', value: false });
  };
  const onServiceSave = async () => {
    try {
      setLoader({ key: 'saveService', value: true });

      const services = clone(changedServices);
      if (services?.delete && Object.keys(services.delete).length > 0) {
        for (const key of Object.keys(services.delete)) {
          await deletePresetService({ service_id: services.delete[key].service?.service_id });
        }
      }
      if (services?.change && Object.keys(services.change).length > 0) {
        for (const key of Object.keys(services.change)) {
          await updatePresetService(services.change[key].service);
        }
      }

      getPresets();
      getPresetServices();
      setOpenEditServices(false);
      openSnackbar('Services saved');
      setChangedServices({ change: {}, delete: {} });
    } catch (e) {
      console.error(e);
      openSnackbar('Failed to save services | ' + (e.error || e), 'error');
    }
    setLoader({ key: 'saveService', value: false });
  };

  const onPresetSave = async (action_) => {
    try {
      setLoader({ key: 'savePreset', value: true });

      const { action, preset, initialOrder } = action_,
        changedPresets = [];
      let previousEntry;
      const firstMoveMutation = preset.order === initialOrder;

      switch (action) {
        case 'moveUp':
          clone(data.presets).forEach((item) => {
            if (item.preset_id === preset.preset_id && previousEntry && firstMoveMutation) {
              const order = item.order;
              item.order = previousEntry.order;
              previousEntry.order = order;
              changedPresets.push(item);
              changedPresets.push(previousEntry);
            }
            previousEntry = item;
          });

          break;
        case 'moveDown':
          clone(data.presets).forEach((item) => {
            if (previousEntry && firstMoveMutation && changedPresets.length <= 0) {
              const order = item.order;
              item.order = previousEntry.order;
              previousEntry.order = order;
              changedPresets.push(item);
              changedPresets.push(previousEntry);
            }
            if (item.preset_id === preset.preset_id) {
              previousEntry = item;
            }
          });
          break;
        default:
          await updatePreset(preset);
          break;
      }
      if (changedPresets.length > 0) {
        for (const preset_ of changedPresets) {
          await updatePreset(preset_);
        }

        openSnackbar('Preset saved');
        setLoader({ key: 'savePreset', value: false });

        return;
      }
      await getPresets();
      openSnackbar('Preset saved');
    } catch (e) {
      openSnackbar('Failed to save preset | ' + e.error, 'error');
    }
    setLoader({ key: 'savePreset', value: false });
  };

  //This method improves performance A LOT since component re-renders would cuase this entire tree to render again
  //due to mapping the data
  useEffect(() => {
    if (data.presets) {
      setPresets(
        data?.presets
          ?.sort((a, b) => a.order - b.order)
          ?.map((preset, index, array) => (
            <Preset
              preset={preset}
              key={preset.preset_id}
              onSave={onPresetSave}
              onDelete={() => {
                setPresetToDelete(preset);
                setOpenDeletePreset(true);
              }}
              firstOption={index === 2}
              lastOption={array.length === 1 || index === array.length - 1}
              disabled={loaders.savePreset}
            />
          ))
      );
    }
  }, [data.presets_replaced_at, loaders.savePreset]);
  const onPresetDelete = async (preset_id) => {
    setLoader({ key: 'deletePreset', value: true });

    try {
      await deletePreset({ preset_id });
      openSnackbar('Preset deleted');
    } catch (e) {
      openSnackbar('Failed to delete preset | ' + e.error, 'error');
    }
    setOpenDeletePreset(false);
    setLoader({ key: 'deletePreset', value: false });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={gridSpacing} justifyContent={'center'}>
        <Grid item xs={12}>
          <Paper elevation={0} square className={classes.paper}>
            <Toolbar className={classes.toolbar} elevation={2}>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                startIcon={<Icon>add</Icon>}
                color={'primary'}
                onClick={() => setOpenNewPreset(true)}>
                Add Preset
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                startIcon={<Icon>add</Icon>}
                color={'primary'}
                onClick={() => setOpenNewService(true)}>
                Add Service
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                startIcon={<Icon>create</Icon>}
                color={'primary'}
                onClick={() => setOpenEditServices(true)}>
                Edit Services
              </Button>
            </Toolbar>
            {props.pass.testMode() && (
              <Box style={{ width: '100%', backgroundColor: '#f44336', color: '#ffffffEE', display: 'flex' }} flex justifyContent="center">
                <Typography style={{ padding: 10 }}>Warning! changes in policy are global and do not currently respect test data.</Typography>
              </Box>
            )}
            {!data.presets && (
              <Box width="100%" height="10px">
                <CircularProgress />
              </Box>
            )}
            <Box className={classes.content}>
              {/**
               *
               * PRESET ROWS
               *
               */}
              {presets}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/**
       *
       * NEW PRESET DIALOG
       *
       */}
      <Dialog open={openNewPreset}>
        <DialogTitle>Add Preset</DialogTitle>
        <ValidatorForm onSubmit={onPresetCreate}>
          <DialogContent>
            <form className={classes.form}>
              <TextValidator
                name={'name'}
                label={'Name'}
                onChange={(event) => setNewPreset({ key: 'name', value: event.target.value })}
                value={newPreset.name}
                validators={['StringLength<100', 'StringLength>0']}
                errorMessages={["Name can't be longer than 100 characters", 'This field is required']}
                required
                fullWidth
              />

              {!!newPreset.services && (
                <Services preset={{ ...newPreset }} onChange={(services) => setNewPreset({ key: 'services', value: services })} />
              )}
            </form>
          </DialogContent>
          <DialogActions>
            <Button color={'primary'} onClick={() => setOpenNewPreset(false)} disabled={loaders.createPreset}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color={'primary'}
              type="subit"
              disabled={loaders.createPreset || newPreset.name.length > 100 || newPreset.name.length <= 0}>
              Add
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      {/**
       *
       * NEW SERVICE DIALOG
       *
       */}
      <Dialog open={openNewService}>
        <DialogTitle>Add Service</DialogTitle>
        <ValidatorForm onSubmit={onServiceCreate}>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextValidator
                  name={'name'}
                  label={'Name'}
                  onChange={(event) => setNewService({ key: 'name', value: event.target.value })}
                  value={newService.name}
                  validators={['StringLength<100', 'StringLength>0']}
                  errorMessages={["Name can't be longer than 100 characters", 'This field is required']}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Group</InputLabel>
                  <Select value={newService.group} onChange={(event) => setNewService({ key: 'group', value: event.target.value })}>
                    <MenuItem value="services">Services</MenuItem>
                    <MenuItem value="features">Features</MenuItem>
                    <MenuItem value="integrations">Integrations</MenuItem>
                    <MenuItem value="metadata">Metadata</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextValidator
                  name={'name'}
                  label={'Slug'}
                  onChange={(event) => setNewService({ key: 'slug', value: event.target.value })}
                  value={newService.slug}
                  validators={['StringLength<100', 'StringLength>0']}
                  errorMessages={["Slug can't be longer than 100 characters", 'This field is required']}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select value={newService.service_type} onChange={(event) => setNewService({ key: 'service_type', value: event.target.value })}>
                    <MenuItem value="bool">Checkbox</MenuItem>
                    <MenuItem value="string">Text</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {newService.service_type === 'bool' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={newService.default}
                    onChange={(event) => setNewService({ key: 'default', value: !newService.default })}
                  />
                }
                label="Default value:"
                labelPlacement="start"
                style={{ marginLeft: 0 }}
              />
            ) : (
              <TextField
                label="Default value"
                value={newService.default}
                onChange={(event) => setNewService({ key: 'default', value: event.target.value })}
                fullWidth
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button color={'primary'} onClick={() => setOpenNewService(false)} disabled={loaders.createService}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color={'primary'}
              type="submit"
              disabled={loaders.createService || newService.name.length > 100 || newService.name.length <= 0}>
              Add
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      {/**
       *
       * EDIT SERVICES DIALOG
       *
       */}
      <EditDialog open={openEditServices}>
        <DialogTitle>Edit Services</DialogTitle>
        <DialogContent>
          <Services
            services={{ ...data.services }}
            onChange={(services, changes) => {
              const changedServices_ = clone(changedServices);
              changes.forEach((change) => (changedServices_[change.action][change.service.path] = change));

              setChangedServices(changedServices_);
            }}
            edit
            disabled={loaders.saveService}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenEditServices(false);
              setChangedServices({ change: [], delete: [] });
              //If we don't reset the hook services, the above services compoent won't reset for some reason.
              //Believe me, this is so dumb I don't know. Please help...
              getPresetServices();
            }}
            disabled={loaders.saveService}>
            Cancel
          </Button>
          <Button variant="contained" color={'primary'} onClick={onServiceSave} disabled={loaders.saveService}>
            Save
          </Button>
        </DialogActions>
      </EditDialog>

      {/**
       *
       * Delete Preset Dialog
       *
       */}
      <Dialog open={openDeletePreset}>
        <DialogTitle>Delete {presetToDelete?.name}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the preset? </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color={'primary'}
            onClick={() => {
              setOpenDeletePreset(false);
              setPresetToDelete(undefined);
            }}
            disabled={loaders.deletePreset}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color={'primary'}
            type="subit"
            disabled={loaders.deletePreset}
            onClick={() => onPresetDelete(presetToDelete.preset_id)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        {...snackbarOptions}
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Presets);

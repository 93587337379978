import { Checkbox, FormControlLabel, Icon, IconButton, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import React from 'react';

export const ServiceEditField = (props) => {
  const { service, onChange, lastOption, firstOption, disabled, variant } = props;
  if (service.hidden) {
    return <></>;
  }
  const component =
    typeof service.default === 'string' ? (
      <TextField
        value={service.default}
        onChange={(event) => onChange({ service, value: event.target.value, key: 'default' })}
        label="Default value"
        size="small"
        disabled={disabled}
        style={{ marginLeft: 16 }}
      />
    ) : (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={service.default}
            onChange={() => onChange({ service, value: !service.default, key: 'default' })}
            disabled={disabled}
          />
        }
        label={'Default value:'}
        labelPlacement="start"
      />
    );
  return (
    <ListItem style={variant === 'alternate' ? { background: 'rgba(0,0,0,0.05)' } : undefined}>
      <ListItemText>
        <TextField
          value={service.name}
          onChange={(event) => onChange({ service, value: event.target.value, key: 'name' })}
          label="name"
          size="small"
          disabled={disabled}
        />

        {/*uncomment to enable changing of groups   
      <FormControl style={{ marginLeft: 10 }} size="small">
          <InputLabel>Group</InputLabel>
          <Select value={service.group} onChange={(event) => onChange({ service, value: event.target.value, key: 'group' })} disabled={disabled}>
            <MenuItem value="services">Services</MenuItem>
            <MenuItem value="features">Features</MenuItem>
            <MenuItem value="integrations">Integrations</MenuItem>
          </Select>
        </FormControl> */}
        {component}
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton onClick={() => onChange({ service, key: 'moveUp', initialOrder: service.order })} disabled={firstOption || disabled}>
          <Icon>arrow_upward</Icon>
        </IconButton>
        <IconButton onClick={() => onChange({ service, key: 'moveDown', initialOrder: service.order })} disabled={lastOption || disabled}>
          <Icon>arrow_downward</Icon>
        </IconButton>
        <IconButton onClick={() => onChange({ service, key: 'delete' })} disabled={disabled}>
          <Icon>delete</Icon>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
export default ServiceEditField;

import React, { Component } from 'react';

import SalesUICustomer from 'PickitAPI/Customer';
import clone from 'clone';
import { get, set } from 'lodash';
import { differenceInCalendarDays, format, isDate, parseISO } from 'date-fns';
import deepmerge from 'deepmerge';

export const CustomerManagement = React.createContext({
  customer: undefined,
  originalCustomer: undefined,
  hasCustomerChanges: false,
});
CustomerManagement.name = 'CustomerManagementContext';

const baseDetails = {
  address: ' ',
  city: ' ',
  country: ' ',
  domain: ' ',
  email: ' ',
  first_name: ' ',
  last_name: ' ',
  name: ' ',
  o365_admin_email: ' ',
  phone: ' ',
  postcode: ' ',
  seats: 0,
};

export class CustomerManagementProvider extends Component {
  resetContext = () => {
    this.setState({
      customer: undefined,
      originalCustomer: undefined,
      hasCustomerChanges: false,
    });
  };

  initCustomer = (customer) => {
    this.setState({ customer: clone(customer), originalCustomer: clone(customer) });
  };
  resetCustomer = () => {
    this.setState({ customer: clone(this.state.originalCustomer), hasCustomerChanges: false });
  };

  setCustomer = (customer) => {
    this.setState({ customer });
  };

  handleCustomerChange = ({ path, value }) => {
    const state = this?.state?.customer;

    const isTheSame = get(state, path) === value;

    const newState = { ...set(state, path, value) };

    this.setState({
      customer: newState,
      hasCustomerChanges: this.state.hasCustomerChanges || !isTheSame,
    });
  };

  handleCustomerSave = async (params) => {
    const { community } = this.state.originalCustomer;
    const { mode, onSave, repairCustomer } = params || {};
    if (mode !== 'silent') {
      this.setState({ isSaving: true });
    }
    let updatedCustomer = deepmerge(this.state.originalCustomer, this.state.customer);

    const { customer: customer_, community: community_, policy: policy_, policy_preset_id } = updatedCustomer;
    if (customer_.details.product.seats < 0) {
      customer_.details.product.seats = 0;
    }
    if (repairCustomer) {
      //Fix corrupted customers that lack required fields due to other parties using our endpoints
      customer_.details.customer = { ...baseDetails, ...customer_.details.customer };
      if (!customer_.details.customer.tenant_id) {
        customer_.details.customer.tenant_id = customer_.tenant_id;
      }
    }

    // This block fixes some broken customer data
    if (
      customer_.status === 'trialactive' &&
      community.product.trial_end_date === null &&
      community.product.trial_start_date === null &&
      mode === 'silent'
    ) {
      customer_.status = 'trialexpired';
    }
    if (customer_.status === 'starter') {
      customer_.new_user_license = 'starter';
      customer_.is_license_management_allowed = false;
    }
    const trialDays = differenceInCalendarDays(
      new Date(updatedCustomer.community.product.trial_end_date),
      new Date(community.product.trial_start_date)
    );
    if (policy_preset_id === 15) {
      customer_.license_display_name = 'enterprise';
    }
    if (customer_.status === 'trialactive' && trialDays !== community_.product.trial_days_left) {
      if (isDate(community_.product.trial_end_date)) {
        community_.product.trial_end_date = format(community_.product.trial_end_date, 'yyyy-MM-dd');
      } else {
        community_.product.trial_end_date = format(parseISO(community_.product.trial_end_date), 'yyyy-MM-dd');
      }
    } else if (customer_.status !== 'trialactive') {
      community_.product.trial_end_date = format(new Date(), 'yyyy-MM-dd');
    }
    try {
      const response = await SalesUICustomer.customers.put({ customer: customer_, community: community_, policy: policy_, policy_preset_id });
      if (onSave) {
        onSave();
      }
      this.setState({ isSaving: false, hasCustomerChanges: false, originalCustomer: response.data[0] });
      return response.data[0];
    } catch (e) {
      this.setState({ isSaving: false });
      throw e;
    }
  };
  handleDeleteCustomer = async () => {
    if (this.state.customer.customer.status.toLowerCase() === 'suspended') {
      //Must unsuspend the customer before deletion. Why? ¯\_(ツ)_/¯
      await this.handleSuspendCustomer('trialexpired');
    }
    await this.deleteCustomer();
  };
  deleteCustomer = async () => {
    try {
      await SalesUICustomer.customers.delete(
        this.state.customer.customer.tenant_id,
        this.state.customer.customer.partner,
        this.state.customer.customer.details.community_url
      );
    } catch (e) {
      await this.handleSuspendCustomer('suspended');
      throw e;
    }
  };
  handleSuspendCustomer = async (newStatus) => {
    const { customer: customer_, community: community_ } = clone(this.state.customer);
    customer_.status = newStatus;
    const response = await SalesUICustomer.customers.put({
      customer: customer_,
      community: community_,
    });
    return response.data[0];
  };

  render() {
    const values = {
      ...this.state,
      initCustomer: this.initCustomer,
      setCustomer: this.setCustomer,
      handleCustomerChange: this.handleCustomerChange,
      handleCustomerSave: this.handleCustomerSave,
      handleDeleteCustomer: this.handleDeleteCustomer,
      handleSuspendCustomer: this.handleSuspendCustomer,
      hasChanges: this?.state?.hasCustomerChanges,
      resetCustomer: this.resetCustomer,
      resetContext: this.resetContext,
    };
    return <CustomerManagement.Provider value={values}>{this.props.children}</CustomerManagement.Provider>;
  }
}

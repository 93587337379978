import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FilterNoneIcon from '@mui/icons-material/FilterNone';

import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  Typography,
  Box,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Toolbar,
  Button,
  IconButton,
} from '@material-ui/core';

import PickitAPI from 'PickitAPI/index';

const gridSpacing = 5;

const styles = (theme) => ({
  grid: {
    marginTop: 0,
    padding: 15,
    '& > div': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  formControl: {
    paddingBottom: 10,
  },
  dashedUnderline: {
    '&::before': {
      borderBottomStyle: 'dashed !important',
    },
  },
  noUnderline: {
    '&::before': {
      borderBottomStyle: 'none !important',
    },
    '&::after': {
      borderBottomStyle: 'none !important',
    },
  },
  paperInfo: {},
});

const columns = [
  {
    id: 'info',
    label: 'Info'
  },
  {
    id: 'role',
    label: 'Role',
    align: 'center'
  },
  {
    id: 'action',
    label: '',
    align: 'center'
  },
]

const roles = [
  {
    id: 'all',
    label: 'All'
  },
  {
    id: 'user',
    label: 'User'
  },
  {
    id: 'admin',
    label: 'Administrator'
  },
  {
    id: 'it',
    label: 'IT-admin'
  },
  {
    id: 'owner',
    label: 'Owner'
  },
  {
    id: 'external',
    label: 'External'
  },
  {
    id: 'apiuser',
    label: 'API-user'
  },
]

const ordering = [
  {
    id: 'name_asc',
    label: 'Name',
    direction: 'asc',
    field: 'name'
  },
  {
    id: 'name_desc',
    label: 'Name',
    direction: 'desc',
    field: 'name'
  },
  {
    id: 'created_on_asc',
    label: 'Oldest',
    direction: 'asc',
    field: 'created_on'
  },
  {
    id: 'created_on_desc',
    label: 'Newest',
    direction: 'desc',
    field: 'created_on'
  },
]

const actions = [
  {
    label: 'Send out invite email',
    id: 'send_out_invite_email',
    isDisabled: (user, isStaff) => {
      return user?.groups?.includes("apiuser")
    }
  },
  {
    label: 'Override/Set password',
    id: 'override_set_password',
    isDisabled: (user, isStaff) => {
      return user?.is_staff
    }
  },
  {
    label: 'Authenticate as user',
    id: 'login_as',
    isDisabled: (user, isStaff) => {
      return (
        !isStaff()
        || user?.is_staff
        || user?.groups?.includes("apiuser")
        || !user?.simple_token
      )
    }
  }
]

const defaultUserPassword = {
  newPassword1: '',
  newPassword2: ''
};

function CustomerUsers(props) {
  const { classes, customer, handleSnackbar, isStaff } = props;

  const controllerRef = useRef();
  const [customerUsers, setCustomerUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [role, setRole] = React.useState('all');
  const [sortField, setSortField] = React.useState('name');
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [search, setSearch] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [passwordModalVisible, setPasswordModalVisible] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(undefined);
  const [userPassword, setUserPassword] = React.useState(defaultUserPassword);

  const [modalButtonLabel, setModalButtonLabel] = React.useState('Disable password');
  const [modalButtonDisabled, setModalButtonDisabled] = React.useState(false);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const getLoginAsURL = (community, user) => {
    /**
     * Getting the URL
     */
    let host = 'https://app.' + window.location.hostname.replace(/portal\./, '');
    let hostname = host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER ? `${process?.env?.REACT_APP_API_URL}` : host;
    let url =
      `${hostname}/#/` +
      `?auth=${user.simple_token}` +
      `&user_id=${user.id}` +
      `&community=${community.host || community.slug}` +
      `&clear_cache=1`;
    return url.replace('proxy.', 'app.');
  };

  const handleLoginAsUser = (community, user) => {
    /**
     * Login as a user
     */
    window.open(getLoginAsURL(community, user), '_blank');
  };

  const handleCopyURL = (event, community, user) => {
    /**
     * Copy link to the clipboard for login as a user
     */
    event.stopPropagation();
    navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(getLoginAsURL(community, user));
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };
  const getCustomerUsers = async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    if (!isLoading || search || selectedUser) {
      setIsLoading(true);
      const page_nr = page >= 0 ? page : 0;
      const response = await PickitAPI.user.getAll(customer.community.slug, page_nr, rowsPerPage, role, search, sortOrder, sortField, controller?.signal);
      setTotalUsers(response?.total_count);
      setCustomerUsers(response?.data ?? []);
      setIsLoading(false);
    }

    controllerRef.current = null;
  }

  const getUserRole = (user) => {
    /**
     * Getting user role by following rules
     */

    if (user?.groups?.includes("owner") && user?.is_curator) {
      return 'Curator'
    } else if (user?.groups?.includes("owner") && !user.is_curator) {
      return 'Owner'
    } else if (user?.groups?.includes("it")) {
      return 'IT-admin'
    } else if (user?.groups?.includes("creator")) {
      return 'Admin'
    } else if (user?.groups?.includes("apiuser") || user?.is_api_user) {
      return 'API-user'
    } else if (user?.is_external) {
      return 'External'
    } else {
      return 'User'
    }
  }

  useEffect(() => {
    setPage(-1);
  }, [rowsPerPage, role, search]);

  useEffect(() => {
    const page_nr = page >= 0 ? page : 0;
    if (page === page_nr) {
      getCustomerUsers().catch(console.error);
    }
    setPage(page_nr);
  }, [page]);

  const TableConfiguration = (<Table stickyHeader size={"small"}>
      <TableBody>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50, 100, 200]}
            colSpan={columns.length - 1}
            count={totalUsers}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ borderBottom: 0 }}
          />
        </TableRow>
      </TableBody>
    </Table>
  );

  useEffect(() => {
    if (0 < userPassword?.newPassword1?.length && userPassword?.newPassword1?.length <= 3) {
      setModalButtonLabel('Password is too short');
      setModalButtonDisabled(true);
    } else if (userPassword?.newPassword1?.length > 3 && userPassword?.newPassword1 !== userPassword?.newPassword2) {
      setModalButtonLabel('Password missmatch');
      setModalButtonDisabled(true);
    } else if (userPassword?.newPassword1?.length > 3 && userPassword?.newPassword1 === userPassword?.newPassword2) {
      setModalButtonLabel('Set/override password');
      setModalButtonDisabled(false);
    } else {
      setModalButtonLabel('Remove password');
      if (!selectedUser?.has_usable_password || !selectedUser?.is_sso_only || selectedUser?.is_staff) {
        setModalButtonDisabled(true);
      } else {
        setModalButtonDisabled(false);
      }
    }
  }, [userPassword, selectedUser]);

  return (
    <div className={classes.root}>
      <Modal
        open={passwordModalVisible}
        onClose={() => {
          setSelectedUser(undefined);
          setPasswordModalVisible(false);
          setUserPassword(defaultUserPassword);
          setModalButtonDisabled(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', minWidth: 400 }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Paper elevation={2} square className={classes.paper} style={{padding: 40}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Override/Set password
                </Typography>
                {selectedUser && (<span>for {selectedUser?.first_name} {selectedUser?.last_name}</span>)}
                {isLoading ? (
                  <Grid container spacing={gridSpacing} className={classes.grid}>
                    <Grid item xs={12} align={"center"}>
                      <CircularProgress></CircularProgress>
                    </Grid>
                  </Grid>
                ):(
                  <div>
                    <FormControl variant="filled" fullWidth style={{ marginBottom: 10 }}>
                      <TextField
                        name={'new_password1'}
                        size="small"
                        variant="filled"
                        value={userPassword?.newPassword1}
                        onChange={(event) => {
                          const newUserPassword = {
                            ...userPassword,
                            newPassword1: event.target.value
                          };
                          setUserPassword(newUserPassword);
                        }}
                        label="New password"
                        type={"password"}
                      />
                    </FormControl>
                    <FormControl variant="filled" fullWidth style={{ marginBottom: 10 }}>
                      <TextField
                        name={'new_password2'}
                        size="small"
                        variant="filled"
                        value={userPassword?.newPassword2}
                        onChange={(event) => {
                          const newUserPassword = {
                            ...userPassword,
                            newPassword2: event.target.value
                          };
                          setUserPassword(newUserPassword);
                        }}
                        label="Repeat new password"
                        type={"password"}
                      />
                    </FormControl>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={4}>
                        <Button
                          variant={'text'}
                          onClick={() => {
                            setSelectedUser(undefined);
                            setPasswordModalVisible(false);
                            setUserPassword(defaultUserPassword);
                            setModalButtonDisabled(false);
                          }}
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={8} align={"right"}>
                        <Button
                          variant={'contained'}
                          onClick={async () => {
                            setIsLoading(true);
                            await PickitAPI.user.setPassword(customer.community.slug, selectedUser?.id, userPassword.newPassword2);
                            setIsLoading(false);
                            handleSnackbar(userPassword?.newPassword1?.length ? 'Password set' : 'Password removed', 'success');

                            setSelectedUser(undefined);
                            setPasswordModalVisible(false);
                            setUserPassword(defaultUserPassword);
                            setModalButtonDisabled(false);

                            await getCustomerUsers();
                          }}
                          color="primary"
                          disabled={modalButtonDisabled}
                        >
                          {modalButtonLabel}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Paper elevation={2} square className={classes.paper}>
            <Toolbar>
              <Grid container wrap="nowrap" alignItems="center">
                <FormControl variant="filled" fullWidth style={{ maxWidth: 300 }}>
                  <TextField
                    name={'search'}
                    size="small"
                    variant="filled"
                    value={search}
                    onChange={(event) => setSearch(event.target.value?.trim())}
                    label="Search"
                  />
                </FormControl>
                <FormControl size="small" variant="filled" fullWidth style={{ marginLeft: 10, maxWidth: 150 }}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    disableUnderline
                    name={'role'}
                    value={role}
                    onChange={(event) => setRole(event.target.value)}
                    MenuProps={{
                      variant: 'menu',
                      style: { maxHeight: 800 },
                    }}>
                      {roles.map(role => (
                        <MenuItem value={role.id} key={role.id}>{role.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl size="small" variant="filled" fullWidth style={{ marginLeft: 10, maxWidth: 150 }}>
                  <InputLabel>Ordering</InputLabel>
                  <Select
                    disableUnderline
                    name={'order_by'}
                    value={`${sortField}_${sortOrder}`}
                    onChange={(event) => {
                      const order = ordering.filter(order => order.id === event.target.value)[0];
                      setSortField(order.field);
                      setSortOrder(order.direction);
                      setPage(-1);
                    }}
                    MenuProps={{
                      variant: 'menu',
                      style: { maxHeight: 800 },
                    }}>
                      {ordering.map(order => (
                        <MenuItem value={order.id} key={order.id}>
                          {order.label} {order.field === 'name' ? order.direction === 'asc' ? 'A-Z' : 'Z-A' : ''}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Toolbar>

            {isLoading ? (
              <Grid container spacing={gridSpacing} className={classes.grid}>
                <Grid item xs={12} align={"center"}>
                  <CircularProgress></CircularProgress>
                </Grid>
              </Grid>
            ):(
              <Grid container spacing={gridSpacing} className={classes.grid}>
                <Grid item xs={12}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    {TableConfiguration}
                    <Table stickyHeader size={"small"}>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell key={column.id} align={column?.align || 'left'}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customerUsers.map((user) => (
                          <TableRow key={user.id}>
                            <TableCell>{user.first_name} {user.last_name}
                              <br/>
                              <strong>{user.email}</strong>
                            </TableCell>
                            <TableCell align={"center"}>{getUserRole(user)}</TableCell>
                            <TableCell style={{ width: 170 }} align={"right"}>
                              <FormControl size="small" variant="filled" fullWidth style={{ width: 150 }}>
                                <InputLabel>Action</InputLabel>
                                <Select
                                  disableUnderline
                                  name={'action'}
                                  value={'blank'}
                                  onChange={async (event) => {
                                    if (event.target.value === 'login_as') {
                                      handleLoginAsUser(customer.community, user);
                                    } else if (event.target.value === 'override_set_password') {
                                      await setSelectedUser(user);
                                      setPasswordModalVisible(true);
                                    } else if (event.target.value === 'send_out_invite_email') {
                                      setIsLoading(true);
                                      await PickitAPI.user.sendOutInviteEmail(customer.community.slug, user?.id);
                                      handleSnackbar('Email sent', 'success');
                                      setIsLoading(false)
                                    }
                                  }}
                                  MenuProps={{
                                    variant: 'menu',
                                    style: { maxHeight: 800 },
                                  }}>
                                    <MenuItem value={'blank'} key={0}>Select an action...</MenuItem>
                                    {actions.map(action => (
                                      <MenuItem value={action.id} key={action.id} disabled={action.isDisabled(user, isStaff)}>
                                        {action.label}
                                        {action.id === 'login_as' && isStaff() && (
                                          <IconButton onClick={(event) => {handleCopyURL(event, customer.community, user)}} style={{ marginLeft: 'auto' }} title="Copy link.">
                                            <FilterNoneIcon />
                                          </IconButton>
                                        )}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {TableConfiguration}
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

CustomerUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CustomerUsers);

import React, { Component } from 'react';

import SalesUICustomer from 'PickitAPI/Customer';
import SalesUIPartner from 'PickitAPI/Partner';
import PickitAPI from 'PickitAPI';
import { lastDayOfMonth } from 'date-fns';
import clone from 'clone';

export const PortalDataContext = React.createContext({
  testMode: false,
  customers: undefined,
  partners: undefined,
});
PortalDataContext.name = 'PortalDataContext';

export class PortalDataProvider extends Component {
  constructor(props) {
    super(props);

    const today = new Date(),
      lastMonth = today.getMonth() - 1 >= 0 ? today.getMonth() - 1 : 11,
      year = lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear(),
      start = new Date(year, lastMonth, 1, 0, 0, 1),
      end = new Date(year, lastMonth, lastDayOfMonth(start).getDate(), 23, 59, 59),
      timeFrame = {
        start,
        end,
      };
    const urlParams = new URLSearchParams(window.location.search);
    const testMode = urlParams.get('test');
    this.state = {
      testMode,
      customers: undefined,
      partners: undefined,
      lastMonthDates: timeFrame,
      lastMonth: this.getMonthName(lastMonth),
    };
  }
  componentDidMount = () => {
    if (this.props.pass !== undefined) {
      this.setState(
        {
          testMode: this.props.pass.testMode(),
        },
        () => {
          if (!this.props.pass.isAuthenticated()) {
            setTimeout(() => {
              this.getPartnerList();
            }, 1000);
          } else {
            this.getPartnerList();
          }
        }
      );
    }
  };

  getMonthName = (monthNumber) => {
    monthNumber = monthNumber < 0 ? 11 : monthNumber;
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthNumber];
  };

  getCustomersForExport = async (page_nr = 1, queryParams) =>
    new Promise(async (resolve, reject) => {
      const params = clone(queryParams);
      params.page_nr = page_nr;
      params.page_limit = 200;

      if (!this.props.pass.isStaff()) {
        params.partner = this.props.pass.isReseller() ? this.props.pass.getPartnerTenantId() : undefined;
        params.parent = this.props.pass.isDistributor() ? this.props.pass.getPartnerTenantId() : undefined;
        params.has_self_service = this.props.pass.isReseller() ? undefined : params.has_self_service;
      }
      params.show_test = this.props.pass.testMode();
      if (!params.show_test) {
        delete params.show_test;
      }

      const customers = await SalesUICustomer.customers.getForPartnerProxy(params, this.props.pass.testMode());
      return resolve(customers);
    });

  getPartnerList = () => {
    if ((this.props.pass.isReseller() || this.props.pass.isDistributor()) && !this.props.pass.isStaff() && !this.state.gettingPartnerList) return;
    this.setState({ partnerList: [], gettingPartnerList: true });
    SalesUIPartner.partners.getAll(this.props.pass.testMode()).then((result) => {
      this.setState({ partnerList: result.data, gettingPartnerList: false });
    });
  };
  updateCustomer = ({ customer, community, policy, policy_preset_id }) => {
    let customers = JSON.parse(JSON.stringify(this.state.customers || []));
    let updatedCustomers = customers.map((item) => {
      if (item.customer.tenant_id === customer.tenant_id) {
        return { customer: { ...item.customer, ...customer }, community: { ...item.community, ...community }, policy, policy_preset_id };
      }
      return item;
    });
    this.setState({ customers: updatedCustomers }, () => {
      PickitAPI.cache.set('sales_ui_customers', {
        customerParams: this.state.customerParams,
        customers: updatedCustomers,
      });
      if (this.state.selectedCustomer) {
        this.setSelectedCustomer(this.state.selectedCustomer.customer.tenant_id);
      }
    });
  };
  removeCusotmer = (tenant_id) => {
    let customers = JSON.parse(JSON.stringify(this.state.customers));
    let updatedCustomers = customers
      .map((item) => {
        if (item.customer.tenant_id === tenant_id) {
          return undefined;
        }
        return item;
      })
      .filter((item) => !!item);
    this.setState({ customers: updatedCustomers }, () => {
      PickitAPI.cache.set('sales_ui_customers', {
        customerParams: this.state.customerParams,
        customers: updatedCustomers,
      });
      if (this.state.selectedCustomer) {
        this.setSelectedCustomer(this.state.selectedCustomer.customer.tenant_id);
      }
    });
  };
  addCustomer = (customer) => {
    let customers = this.state.customers ? JSON.parse(JSON.stringify(this.state.customers)) : [];
    customers.push({ ...customer });
    this.setState({ customers: customers }, () => {
      PickitAPI.cache.set('sales_ui_customers', {
        customerParams: this.state.customerParams,
        customers: customers,
      });
    });
  };
  getCustomer = async (tenant_id) => {
    try {
      let parent = this.props.pass.isDistributor() ? this.props.pass.getPartnerTenantId() : undefined;
      let partner = this.props.pass.isReseller() || this.props.pass.isStaff() ? this.props.pass.getPartnerTenantId() : undefined;
      if (tenant_id === this.props.pass.getPartnerTenantId() && this.props.pass.isDistributor()) {
        parent = undefined;
        partner = this.props.pass.getPartnerTenantId();
      }
      const params = {
        customer: tenant_id,
        partner: partner,
        parent: parent,
      };
      const result = await SalesUICustomer.customers.get(params, this.props.pass.testMode());
      if (this.state.customers && !this.state.customers.find((customer) => customer.customer.tenant_id === tenant_id) && !!result.data[0]) {
        this.addCustomer({ ...result.data[0] });
      }
      return result.data;
    } catch (e) {
      console.error('failed getting customer \n', e);
    }
  };
  setSelectedCustomer = async (tenant_id) => {
    const { customers } = this.state;

    let selectedCustomer = undefined;
    if (customers) {
      selectedCustomer = customers.find((customer) => {
        return !!customer && customer.customer.tenant_id === tenant_id;
      });
    }
    if (!selectedCustomer) {
      let data = await this.getCustomer(tenant_id);
      if (!data || !data[0]) {
        // eslint-disable-next-line no-throw-literal
        throw 'Customer could not be found';
      }
      selectedCustomer = data[0];
    }

    this.setState({ selectedCustomer: selectedCustomer });
  };
  getPartner = async (tenant_id) => {
    try {
      let result = await SalesUIPartner.partners.getOne(tenant_id, this.props.pass.getPartnerTenantId(), this.props.pass.testMode());

      this.addPartner(result.data[0]);
      this.getCustomer(tenant_id);
      return result.data;
    } catch (e) {
      console.error('failed getting partner \n', e);
    }
  };
  setSelectedPartner = async (tenant_id) => {
    const { partners } = this.state;
    let selectedPartner = undefined,
      existingPartner = !!partners
        ? partners.find((partner) => {
            return !!partner && partner.tenant_id === tenant_id;
          })
        : undefined;

    if (!existingPartner) {
      let data = await this.getPartner(tenant_id);
      if (!data || !data[0]) {
        // eslint-disable-next-line no-throw-literal
        throw 'Partner could not be found';
      }
      existingPartner = data[0];
    }
    selectedPartner = {
      partner: existingPartner,
      partnerCustomer: undefined,
      partnerCustomers: [],
      partnerPartners: [],
    };
    let result = await this.getCustomer(tenant_id);
    selectedPartner.partnerCustomer = result[0];
    if (!selectedPartner.partnerCustomer) throw new Error('No partner customer found');
    let params = {};
    if (existingPartner.type === 'reseller') {
      params = {
        partner: tenant_id,
        parent: this.props.pass.isDistributor() ? this.props.pass.getPartnerTenantId() : undefined,
        community_slug: selectedPartner.partnerCustomer.community.slug,
        page_nr: undefined,
        page_limit: undefined,
      };
      result = await SalesUICustomer.customers.getAllForPartner(params, this.props.pass.testMode);
      selectedPartner.partnerCustomers = result.data;
    }
    if (existingPartner.type === 'distributor') {
      params = {
        parent: tenant_id,
        page_nr: 1,
        page_limit: 200,
      };
      result = await SalesUIPartner.partners.getFiltered(params, this.props.pass.testMode, selectedPartner.partnerCustomer.community.slug);
      selectedPartner.partnerPartners = result.data;
    }
    this.setState({ gettingPartner: false, selectedPartner: selectedPartner });
  };
  updatePartner = (partner) => {
    let partners = JSON.parse(JSON.stringify(this.state.partners));
    let updatedPartners = partners.map((item) => {
      if (item.tenant_id === partner.tenant_id) {
        return { ...item, ...partner };
      }
      return item;
    });
    this.setState({ partners: updatedPartners }, () => {
      PickitAPI.cache.set('sales_ui_partners', undefined);

      PickitAPI.cache.set('sales_ui_partners', updatedPartners);
      if (this.state.selectedPartner) {
        let selectedPartner = this.state.selectedPartner;
        selectedPartner.partner = partner;
        this.setState({ selectedPartner: selectedPartner });
      }
    });
  };
  addPartner = (partner, callback) => {
    let partners = clone(this.state.partners) || [];
    let partnerList = clone(this.state.partnerList) || [];

    partners.push(partner);
    partnerList.push(partner);
    this.setState({ partners, partnerList });
  };
  changeCustomersPage = (event, page) => {
    this.setState({ customerParams: { ...this.state.customerParams, page_nr: page } }, () => {
      this.refreshCustomers();
      window.scrollTo(0, 0);
    });
  };
  changeCustomersPerPage = (event) => {
    this.setState({ customerParams: { ...this.state.customerParams, page_limit: parseInt(event.target.value, 10) } }, () => {
      this.refreshCustomers();
    });
  };
  updateCustomerFilters = (filters) => {
    let newFilters = {};

    if (filters.name !== '') {
      newFilters.search = filters.name;
    }
    switch (filters.relation) {
      case 'managed':
        newFilters.has_self_service = false;
        break;
      case 'self_service':
        newFilters.has_self_service = true;
        break;
      default:
        break;
    }
    newFilters.partner = filters.partner === 'all' ? undefined : filters.partner;
    newFilters.status = filters.status === 'all' ? undefined : filters.status;
    if (filters.relation !== 'all') {
      newFilters.has_self_service = filters.relation === 'managed' ? false : true;
    }

    const newParams = clone(this.state.customerParams);

    newParams.filters = newFilters;
    newParams.page_nr = 0;
    let refresh = JSON.stringify(newParams) !== JSON.stringify(this.state.customerParams);
    this.setState(
      {
        customerParams: newParams,
      },
      () => {
        if (refresh) {
          this.refreshCustomers();
        }
      }
    );
  };
  updateCustomerSorting = (sorting) => {
    const newParams = clone(this.state.customerParams);

    newParams.sort_field = sorting;
    newParams.page_nr = 0;
    let refresh = JSON.stringify(newParams) !== JSON.stringify(this.state.customerParams);

    this.setState({ customerParams: newParams }, () => {
      if (refresh) {
        this.refreshCustomers();
      }
    });
  };
  changePartnersPage = (event, page) => {
    this.setState({ partnerParams: { ...this.state.partnerParams, page_nr: page } }, () => {
      this.refreshPartners();
      window.scrollTo(0, 0);
    });
  };
  changePartnersPerPage = (event) => {
    this.setState({ partnerParams: { ...this.state.partnerParams, page_limit: parseInt(event.target.value, 10) } }, () => {
      this.refreshPartners();
    });
  };
  updatePartnerFilters = (filters) => {
    let newFilters = {};

    if (filters.name !== '') {
      newFilters.search = filters.name;
    }
    switch (filters.relation) {
      case 'managed':
        newFilters.has_self_service = false;
        break;
      case 'self_service':
        newFilters.has_self_service = true;
        break;
      default:
        break;
    }
    newFilters.status = filters.status === 'any' ? undefined : filters.status;
    newFilters.type = filters.type === 'any' ? undefined : filters.type;

    const newParams = clone(this.state.partnerParams);

    newParams.filters = newFilters;
    let refresh = JSON.stringify(newParams) !== JSON.stringify(this.state.partnerParams);
    this.setState(
      {
        partnerParams: newParams,
      },
      () => {
        if (refresh) {
          this.refreshPartners();
        }
      }
    );
  };
  updatePartnerSorting = (sorting) => {
    const newParams = clone(this.state.partnerParams);

    newParams.sort_field = sorting;
    let refresh = JSON.stringify(newParams) !== JSON.stringify(this.state.partnerParams);

    this.setState({ partnerParams: newParams }, () => {
      if (refresh) {
        this.refreshPartners();
      }
    });
  };
  onTestModeChanged = (newState) => {
    this.setState({ testMode: newState }, () => {
      if (!this.state.gettingPartnerList) {
        this.getPartnerList();
      }
    });
  };

  setError = (errorMessage) => {
    this.setState({
      error: true,
      errorMessage: errorMessage,
    });
  };
  render() {
    if (this.state.testMode !== this.props.pass.testMode()) {
      console.warn('reload for testmode change');
      this.onTestModeChanged(this.props.pass.testMode());
    }

    const values = {
      ...this.state,
      getCustomersForExport: this.getCustomersForExport,
      getAIStatistic: SalesUICustomer.customers.getAIStatistic,
      getAIActivity: SalesUICustomer.customers.getAIActivity,
      updateCustomer: this.updateCustomer,
      removeCusotmer: this.removeCusotmer,
      updatePartner: this.updatePartner,
      setSelectedCustomer: this.setSelectedCustomer,
      setSelectedPartner: this.setSelectedPartner,
      addCustomer: this.addCustomer,
      addPartner: this.addPartner,
      changeCustomersPage: this.changeCustomersPage,
      changeCustomersPerPage: this.changeCustomersPerPage,
      updateCustomerFilters: this.updateCustomerFilters,
      updateCustomerSorting: this.updateCustomerSorting,
      changePartnersPage: this.changePartnersPage,
      changePartnersPerPage: this.changePartnersPerPage,
      updatePartnerFilters: this.updatePartnerFilters,
      updatePartnerSorting: this.updatePartnerSorting,
      setError: this.setError,
    };
    return <PortalDataContext.Provider value={values}>{this.props.children}</PortalDataContext.Provider>;
  }
}

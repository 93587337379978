import React from 'react';
import subDays from 'date-fns/subDays';
import PickitAPI from 'PickitAPI';
// import "./style.scss";

function dateToISO(date) {
  return date.toISOString();
}

export default function useUsage(community, start, end) {
  let api_host = 'https://' + window.location.hostname.replace(/portal\./, 'files.');
  let url = '';
  if (process?.env?.REACT_APP_IS_DOCKER && process.env?.REACT_APP_FILES_API_URL) {
    url = `${process?.env?.REACT_APP_FILES_API_URL}`;
  } else {
    url =
      api_host.indexOf('localhost') !== -1 || process?.env?.REACT_APP_IS_DOCKER
        ? `${process?.env?.REACT_APP_API_URL.replace('proxy', 'files')}`
        : api_host;
  }

  const [isLoading, setIsLoading] = React.useState(true);
  const [startPeriod, setStartPeriod] = React.useReducer((state, action) => dateToISO(action), start ? dateToISO(start) : null);
  const [endPeriod, setEndPeriod] = React.useReducer((state, action) => dateToISO(action), end ? dateToISO(end) : null);
  const [data, setData] = React.useReducer((state, action) => {
    if (action.replace) {
      state[action.key] = action.data;
    }
    if (action.delete) {
      delete state[action.key];
    }
    return { ...state };
  }, []);
  const [activeUsersCommunities, setActiveUsersCommunities] = React.useReducer((state, action) => {
    return { ...state, ...action };
  }, {});

  React.useEffect(() => {
    if (!start) {
      setEndPeriod(new Date());
      setStartPeriod(subDays(new Date(), 7));
    }
  }, []);

  React.useEffect(() => {
    if (startPeriod && endPeriod && community) {
      setData({
        replace: true,
        key: 'activity',
        data: {},
      });
      getData();
    }
  }, [startPeriod, endPeriod]);

  async function getToplist() {
    setIsLoading(true);
    await PickitAPI.checkToken();
    const token = JSON.parse(localStorage.getItem('user') || '{}')?.jwt;
    const req = await fetch(`${url}/media/statistics/top?start=${startPeriod}&end=${endPeriod}`, {
      headers: {
        'pickit-community': community,
        Authorization: `JWT ${token}`,
      },
    });
    const res = await req.json();
    setData({
      replace: true,
      key: 'top',
      data: res,
    });
    setIsLoading(false);
  }

  async function getData() {
    setIsLoading(true);
    await PickitAPI.checkToken();
    const token = JSON.parse(localStorage.getItem('user') || '{}')?.jwt;
    try {
      const req = await fetch(`${url}/media/statistics/usage?start=${startPeriod}&end=${endPeriod}`, {
        headers: {
          'pickit-community': community,
          Authorization: `JWT ${token}`,
        },
      });
      const res = await req.json();
      setData({
        replace: true,
        data: res,
        key: 'activity',
      });
    } catch (e) {
      console.debug(e);
    }
    setIsLoading(false);
  }

  async function getActiveUsers() {
    setIsLoading(true);
    await PickitAPI.checkToken();
    const token = JSON.parse(localStorage.getItem('user') || '{}')?.jwt;
    try {
      const req = await fetch(`${url}/media/statistics/active-users?start=${startPeriod}&end=${endPeriod}`, {
        headers: {
          'pickit-community': community,
          Authorization: `JWT ${token}`,
        },
      });
      const res = await req.json();
      setData({
        replace: true,
        key: 'activeUsers',
        data: res,
      });
    } catch (e) {
      console.debug(e);
    }
    setIsLoading(false);
  }

  async function getActiveUsersByCommunities(communities) {
    setIsLoading(true);
    await PickitAPI.checkToken();
    const token = JSON.parse(localStorage.getItem('user') || '{}')?.jwt;
    const req = await fetch(`${url}/media/statistics/active-users-by-communities?start=${startPeriod}&end=${endPeriod}`, {
      method: 'POST',
      body: JSON.stringify({
        communities: communities,
      }),
      headers: {
        'Content-Type': 'application/json',
        'pickit-community': community,
        Authorization: `JWT ${token}`,
      },
    });
    const res = await req.json();
    setActiveUsersCommunities(res);
    setIsLoading(false);
    return res;
  }
  return {
    startPeriod,
    setStartPeriod,
    endPeriod,
    setEndPeriod,
    data,
    getData,
    getToplist,
    isLoading,
    getActiveUsers,
    getActiveUsersByCommunities,
    activeUsersCommunities,
  };
}

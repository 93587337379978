import React, { useState, useContext, useEffect, Suspense } from 'react';
import SalesUIPartner from 'PickitAPI/Partner';
import PickitAPI from 'PickitAPI';

import { PortalDataContext } from 'Contexts/PortalDataContext';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  IconButton,
  FormControl,
  Icon,
  Typography,
  Input,
  FormHelperText,
  CircularProgress,
  Button,
  Checkbox,
  TableRow,
  NativeSelect,
  TablePagination,
  TableFooter,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clone from 'clone';
import { filter } from 'lodash';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const User = React.lazy(() => import('./User.jsx'));

const styles = (theme) => ({
  paper: {
    minHeight: '89%',
  },
  tab: {
    width: '50%',
  },
  noWrap: {
    cursor: 'pointer',
    fontSize: 16,
    paddingTop: 30,
    paddingBottom: 30,
  },
  paperTab: {
    width: '50%',
    maxWidth: 'none',
    height: 70,
    fontSize: '1rem',
    textTransform: 'none',
  },
  focusCell: {
    fontWeight: 500,
  },
  saveButton: {
    display: 'flex',
    padding: '5px 40px',
    minHeight: 24,
    minWidth: 64,
    boxShadow: 'none',
    alignItems: 'center',
    marginBottom: 10,
  },
  button: {
    paddingLeft: 25,
    paddingRight: 25,
    whiteSpace: 'nowrap',
  },
});

function Users(props) {
  const { classes, handleLoader, handleSnackbar, setError, setErrorMessage } = props;

  const context = useContext(PortalDataContext);

  const [isInviting, setIsInviting] = useState(false);
  const [allowedRoles, setAllowedRoles] = useState(['write', 'read', 'inactive']);

  const [selectAll, setSelectAll] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState('all');
  const [users, setUsers] = useState(undefined);
  const [newEmails, setNewEmails] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notifyingUsers, setNotifyingUsers] = useState(false);
  const [changingPasswordUsers, setChangingPassword] = useState(false);

  const [creatingNewUsers, setCreatingNewUsers] = useState(false);
  const [changingRole, setChangingRole] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [changingUser, setchangingUser] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getUsers();
    return () => {};
  }, []);

  const getUsers = async (is_refresh = false) => {
    const { partnerCustomer } = context.selectedPartner;
    if (!is_refresh && PickitAPI.cache.get('sales_ui_users' + props.route.match.params.id)) {
      let users = PickitAPI.cache.get('sales_ui_users' + props.route.match.params.id);
      let community_slug = '';

      // Setup user's status
      users = users.map((user) => {
        if (community_slug === '') {
          community_slug = user.groups[0].split('_')[0];
        }

        user.role = 'inactive';
        if (user.groups.indexOf('portalwrite') !== -1) {
          user.role = 'write';
        } else if (user.groups.indexOf('portalread') !== -1) {
          user.role = 'read';
        }

        return user;
      });
      setUsers(users);
      setAllowedRoles(['read', 'write', 'inactive']);
      setIsInviting(false);
      setNewEmails('');
      setNotifyingUsers(false);
      setCreatingNewUsers(false);
      setChangingRole(false);
      setRefreshing(false);
      setChangingPassword(false);

      PickitAPI.cache.set('sales_ui_users' + props.route.match.params.id, users);
      if (is_refresh) {
        handleLoader(false, '');
        handleSnackbar('Users list updated', 'success');
      }
    } else {
      setLoadingUsers(true);
      SalesUIPartner.users
        .getAll(props.route.match.params.id, props.pass.getPartnerTenantId(), partnerCustomer.community.slug)
        .then((response) => {
          let users = response.data;
          let community_slug = '';

          // Setup user's status
          users = users.map((user) => {
            if (community_slug === '') {
              community_slug = user.groups[0].split('_')[0];
            }

            user.role = 'inactive';
            if (user.groups.indexOf('portalwrite') !== -1) {
              user.role = 'write';
            } else if (user.groups.indexOf('portalread') !== -1) {
              user.role = 'read';
            }

            return user;
          });
          setUsers(users);
          setAllowedRoles(['read', 'write', 'inactive']);
          setIsInviting(false);
          setNewEmails('');
          setNotifyingUsers(false);
          setCreatingNewUsers(false);
          setChangingPassword(false);
          setChangingRole(false);
          setRefreshing(false);
          setLoadingUsers(false);

          PickitAPI.cache.set('sales_ui_users' + props.route.match.params.id, users);
          if (is_refresh) {
            handleLoader(false, '');
            handleSnackbar('Users list updated', 'success');
          }
        })
        .catch((error) => {
          error.json().then((response) => {
            console.error('failed getting user');
            setError(true);
            setErrorMessage('Corrupted data. ' + response.error);
            setIsInviting(false);
            setNotifyingUsers(false);
            setCreatingNewUsers(false);
            setChangingRole(false);
            setChangingPassword(false);
            setRefreshing(false);
            setLoadingUsers(false);
          });
        });
    }
  };

  const handleChangeNewEmails = (event) => {
    setNewEmails(event.target.value);
  };
  const handleAction = (event, s) => {
    if (props.pass.isReadOnly()) {
      return;
    }
    const name = event.target.name || event.target.parentNode.name;
    const action = name.indexOf('user_') === -1 ? name : 'role';
    const { partnerCustomer } = context.selectedPartner;
    const data = {
      partner: props.route.match.params.id,
      parent: props.pass.getPartnerTenantId(),
      action: action,
    };
    let request = SalesUIPartner.users.put;

    switch (action) {
      case 'invite':
        setCreatingNewUsers(true);
        delete data.action;
        request = SalesUIPartner.users.post;
        data.emails = newEmails;
        break;
      case 'notify':
        setNotifyingUsers(true);
        data.users_id = selectedUsers;

        break;
      case 'pwd':
        setChangingPassword(true);

        data.users_id = selectedUsers;
        break;
      case 'role':
        setChangingRole(true);
        setchangingUser(event.target.name);

        data.user_id = name.split('_')[1];
        data.role = event.target.value;
        break;

      default:
        return;
    }
    request(data, partnerCustomer.community.slug)
      .then((data) => {
        getUsers(true);
      })
      .catch((e) => {
        console.error(e);
        e.json().then((errorJson) => {
          if (errorJson.errors) {
            let msg = errorJson.error.toString() + '. ';
            Object.keys(errorJson.errors).forEach((k) => {
              msg += errorJson.errors[k] + '. ';
            });
            setChangingRole(false);
            handleSnackbar('Something went wrong. ' + msg, 'error');
          } else {
            setChangingRole(false);
            handleSnackbar('Something went wrong. ' + errorJson.error.toString(), 'error');
          }
        });
      });
  };

  const handleSelectUser = (event, user_id) => {
    let selectedUsers_ = selectedUsers ? clone(selectedUsers) : [];
    if (event.target.checked) {
      selectedUsers_.push(user_id);
    } else if (selectedUsers_.indexOf(user_id) !== -1) {
      selectedUsers_ = filter(selectedUsers_, (selected_user_id) => {
        return selected_user_id !== user_id;
      });
    }
    setSelectedUsers(selectedUsers_);
  };

  const handleChangeUsersFilter = (event) => {
    let newAllowedRoles = ['read', 'write', 'inactive'];

    if (event.target.value === 'portal') {
      newAllowedRoles = ['read', 'write'];
    } else if (event.target.value === 'community') {
      newAllowedRoles = ['inactive'];
    }
    setAllowedRoles(newAllowedRoles);
    setSelectedUsers([]);
    setSelectAll(false);
    setFilteredUsers(event.target.value);
  };

  const handleSelectAllUsers = (event) => {
    let users_id = filter(users, (user) => {
      return allowedRoles.indexOf(user.role) !== -1;
    }).map((user) => {
      return user.id;
    });

    if ((selectedUsers.length && selectedUsers.length !== users_id.length) || !selectedUsers.length) {
      setSelectedUsers(users_id);
      setSelectAll(true);
    } else {
      setSelectedUsers([]);
      setSelectAll(false);
    }
  };

  const roleRows = [
    { name: 'No access', slug: 'inactive' },
    { name: 'Viewer', slug: 'read' },
    { name: 'Admin', slug: 'write' },
  ].map((role) => {
    return (
      <option key={role.slug} value={role.slug}>
        {role.name}
      </option>
    );
  });
  if (!users && !loadingUsers) return <CircularProgress></CircularProgress>;
  const filteredUserRows = filter(users, (partnerUser) => {
    return allowedRoles.indexOf(partnerUser.role) !== -1;
  });

  const partnerUserRows = (rowsPerPage > 0 ? filteredUserRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredUserRows).map(
    (partnerUser) => {
      return (
        <Suspense
          fallback={
            <TableRow>
              <TableCell colSpan={4}>
                <Skeleton variant="rect" width="100%"></Skeleton>
              </TableCell>
            </TableRow>
          }>
          <User {...{ partnerUser, classes, selectedUsers, handleSelectUser, changingRole, handleAction, roleRows, changingUser }} />
        </Suspense>
      );
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {isInviting ? (
        <Paper elevation={2} square className={classes.paper} style={{ padding: '0 20px', minHeight: 0 }}>
          <Grid container spacing={0} xs={12} className={classes.grid}>
            <Grid item xs={6}>
              <Typography variant={'h6'} className={classes.title} style={{ paddingTop: 16, marginLeft: 16 }}>
                {'Invite New User'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} xs={12} className={classes.grid}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input name={'newEmails'} value={newEmails} onChange={handleChangeNewEmails} placeholder={'E-mail'} />
                <FormHelperText>{'Separate emails with a comma. All users will be added as viewers. You can change this later.'}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container xs={6}>
            <Grid item xs={6}>
              <Button
                name="invite"
                onClick={handleAction}
                disabled={creatingNewUsers}
                color="primary"
                variant={'contained'}
                className={classes.saveButton}
                style={{ marginLeft: 16 }}
                type={'button'}>
                {creatingNewUsers ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  setIsInviting(false);
                  setNewEmails('');
                }}
                color="secondary"
                disableRipple
                variant={'text'}
                style={{ marginRight: 16, color: '#fb1790' }}>
                {'Cancel'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper elevation={2} square className={classes.paper} style={{ marginTop: 8, padding: '0 20px' }}>
          {!props.pass.isReadOnly() && (
            <Grid container spacing={2}>
              <Grid item xs={3} style={{ paddingLeft: 0, paddingTop: 16, display: 'flex' }}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setIsInviting(true);
                  }}>
                  <Icon className="material-icons">add</Icon>
                  <p style={{ margin: 0, height: 22 }}>{'New User'}</p>
                </Button>
                {refreshing ? (
                  <div style={{ width: 'fit-content', marginLeft: 22, transform: 'translateY(6px)' }}>
                    <CircularProgress size={24} />
                  </div>
                ) : (
                  <IconButton
                    color={'primary'}
                    onClick={() => {
                      setRefreshing(true);
                      getUsers(true);
                    }}
                    style={{ marginLeft: 10, padding: 8 }}>
                    <Icon className="material-icons" fontSize={'small'} style={{ color: '#727272' }}>
                      refresh
                    </Icon>
                  </IconButton>
                )}
                <br />
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right', paddingTop: 16 }}>
                <FormControl>
                  <NativeSelect value={filteredUsers} onChange={handleChangeUsersFilter}>
                    <option key={'all'} value={'all'}>
                      All
                    </option>
                    <option key={'portal'} value={'portal'}>
                      Portal users
                    </option>
                    <option key={'community'} value={'community'}>
                      Community users
                    </option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={1} style={{ spaddingTop: 16, textAlign: 'right' }}></Grid>
              <Grid item xs={3} style={{ paddingRight: 0, paddingTop: 16, textAlign: 'right' }}>
                <Button
                  name="notify"
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  onClick={handleAction}
                  disabled={!selectedUsers.length || notifyingUsers}
                  fullWidth>
                  {notifyingUsers ? <CircularProgress size={24} /> : selectedUsers.length ? 'Notify (' + selectedUsers.length + ')' : 'Notify users'}
                </Button>
              </Grid>
              <Grid item xs={3} style={{ paddingTop: 16 }}>
                <Button
                  name="pwd"
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  onClick={handleAction}
                  disabled={!selectedUsers.length || changingPasswordUsers}
                  fullWidth>
                  {changingPasswordUsers ? (
                    <CircularProgress size={24} />
                  ) : selectedUsers.length ? (
                    'Reset password (' + selectedUsers.length + ')'
                  ) : (
                    'Reset password'
                  )}
                </Button>
              </Grid>

              <br />
              {partnerUserRows && (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                          colSpan={4}
                          count={filteredUserRows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                      <TableRow>
                        <TableCell variant={'head'} style={{ padding: 0 }}>
                          <Checkbox color={'primary'} onChange={handleSelectAllUsers} checked={selectAll} />
                        </TableCell>
                        <TableCell variant={'head'}>{'Name'}</TableCell>
                        <TableCell variant={'head'}>{'Email'}</TableCell>
                        <TableCell variant={'head'}>{'Role'}</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>{partnerUserRows}</TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                          colSpan={4}
                          count={filteredUserRows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </>
              )}
            </Grid>
          )}
        </Paper>
      )}
    </>
  );
}
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Users);
